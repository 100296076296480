import React, { useState } from 'react';

import './Adminhome.css'
import { FaSackDollar } from "react-icons/fa6";
import { FaRupeeSign } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { BsBank2 } from "react-icons/bs";
import Chart from './Expenseschart/chart'
import Expenseslist from './Expenseslist/Expenseslist'
import Addexpenses from './Expenses/Addexpenses'
import { Navigate } from 'react-router-dom';

const Expenses = ({ isLoggedIn }) => {
    const [currentView, setCurrentView] = useState('jobDescription');

    const renderContent = () => {
        switch (currentView) {
            case 'Chart':
                return <Chart />;
            case 'expense':
                return <Addexpenses />;
            default:
                return <Chart />;
        }
    };
    if (!isLoggedIn) {
        return <Navigate to="/login" />;
    }

    const handleTabClick = (view) => {
        setCurrentView(view);
    };
    // if (!isLoggedIn) {
    //     return <Navigate to="/login" />;
    // }
    return (
        <div>
            <div class="inner-wrapper" style={{ marginTop: "30px" }}>


                <header class="header">

                    <div class="top-header-section">

                    </div>

                </header>

                <div class="page-wrapper" style={{ marginTop: "-70px" }}>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-3 col-lg-4 col-md-12 theiaStickySidebar">
                                <aside class="sidebar sidebar-user">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card ctm-border-radius shadow-sm grow" style={{ width: "290px" }}>
                                                <div class="card-body py-4">
                                                    <div class="row">
                                                        <div class="col-md-12 mr-auto text-left">
                                                            <div class="custom-search input-group">
                                                                <div class="custom-breadcrumb">
                                                                    <ol class="breadcrumb no-bg-color d-inline-block p-0 m-0 mb-2">
                                                                        <li class="breadcrumb-item d-inline-block"><a href="/" class="text-dark">Home</a></li>
                                                                        <li class="breadcrumb-item d-inline-block active">Carrers</li>
                                                                    </ol>
                                                                    <h4 class="text-dark">Expenses</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="user-card card shadow-sm bg-white text-center ctm-border-radius grow" style={{ width: "290px" }}>
                                        <div class="user-info card-body">
                                            <div class="user-avatar mb-4">
                                                <img src="images/408535-200.png" alt="User Avatar" class="img-fluid rounded-circle" width="100" />
                                            </div>
                                            <div class="user-details">
                                                <h2>Welcome Admin!</h2>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="sidebar-wrapper">
                                        <div className="card ctm-border-radius shadow-sm border-none grow navbars" style={{ width: "290px" }}>
                                            <div className="card-body">
                                                <div className="row no-gutters">
                                                    <div className="col-12 col-md-6 align-items-center shadow-none text-center">
                                                        <Link to="/admincarrers" className="text-dark p-4 second-slider-btn ctm-border-right ctm-border-top">
                                                            <span className="lnr font-23"><FaUserGraduate /></span>
                                                            <span className="nav-text">Careers</span>
                                                        </Link>
                                                    </div>
                                                    <div className="col-12 col-md-6 align-items-center shadow-none text-center">
                                                        <Link to="/expenses" className="text-dark p-4 second-slider-btn ctm-border-right ctm-border-top">
                                                            <span className="lnr font-23"><BsBank2 /></span>
                                                            <span className="nav-text">Expenses</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </aside>
                            </div>
                            <div class="col-xl-9 col-lg-8 col-md-12">
                                <div class="quicklink-sidebar-menu ctm-border-radius shadow-sm bg-white card grow" style={{ width: "900px" }}>

                                    <div class="card-body">
                                        <ul class="list-group list-group-horizontal-lg">
                                            <li className={`list-group-item text-center button-5 ${currentView === 'Chart' ? 'active' : ''}`}>
                                                <a
                                                    href="#"
                                                    className={currentView === 'Chart' ? 'text-white' : 'text-dark'}
                                                    onClick={() => handleTabClick('Chart')}
                                                >
                                                    <FaSackDollar /> Expenses
                                                </a>
                                            </li>
                                            <li className={`list-group-item text-center button-6 ${currentView === 'expense' ? 'active' : ''}`}>
                                                <a
                                                    href="#"
                                                    className={currentView === 'expense' ? 'text-white' : 'text-dark'}
                                                    onClick={() => handleTabClick('expense')}
                                                >
                                                    <FaRupeeSign /> Add Expenses
                                                </a>
                                            </li>
                                            {/* Add other navigation links here */}
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-9 col-md-9 responsive_expensive" >
                                    <div className="content-area">
                                        {currentView !== 'expense' && <Expenseslist />}
                                    </div>
                                    <div className="content-area1">
                                        {renderContent()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

                <div class="sidebar-overlay" id="sidebar_overlay"></div>
            </div >
        </div >
    )
}

export default Expenses
