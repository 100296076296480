import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaMapLocationDot } from "react-icons/fa6";
import "./Home.css";
import $ from "jquery";
import "slick-carousel";
import Carsouel from "../../Component/Carsouel/Carsouel";
import { MdOutlineDesignServices } from "react-icons/md";
import { MdManageHistory } from "react-icons/md";
import { SiConstruct3 } from "react-icons/si";
import { FaBuildingColumns } from "react-icons/fa6";
import { SiAntdesign } from "react-icons/si";
import { MdEmojiTransportation } from "react-icons/md";
import { IoMdArrowDroprightCircle } from "react-icons/io";
import { FaLocationArrow } from "react-icons/fa";
import ServiceSlider from "../../Component/ServiceSlider/ServiceSlider";
import AOS from "aos";
import "aos/dist/aos.css";
import Example from "../../Component/TestimonalSlider/TestmonalSlider";
import HomeGallery from "../../Component/HomeGallery/HomeGallery";
import Carosole from "../../Component/Carosole";
import Marque from "../../Component/Marque/Marque";
import Cadslider from '../../Component/Cadslider/Cadslider'
import { FaWhatsapp } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";

const slides = [
  {
    city: 'Foundation',
    country: 'Building Dreams, One Foundation at a Time.',
    img: '/images/6.jpg',
  },
  {
    city: 'Where Steel Meets Sky',
    country: 'Engineering Marvels in Motion.',
    img: '/images/main-slider/image-4.jpg',
  },
  {
    city: 'Pioneering Progress',
    country: 'Constructing the Pathways to Tomorrow.',
    img: '/images/main-slider/image-6.jpg',
  },
  {
    city: 'Strength in Structure',
    country: 'Where Ingenuity Meets Concrete.',
    img: '/images/corosol.jpg',
  },
  {
    city: 'summer Internship Program',
    country: 'Join Us..! To Enhance your Skills.',
    img: '/images/main-slider/g38834.jpg',
  },
  // {
  //   city: 'Moscow',
  //   country: 'Russia',
  //   img: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/moscow.jpg',
  // },
];


const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  useEffect(() => {
    AOS.init();

  }, []);
  return (
    <div class="page-wrapper">
      <Carosole slides={slides} />
      {/* <!--Success Section--> */}
      <section class="success-section" style={{ marginBottom: "-100px" }}>
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Image Column--> */}
            <div
              class="image-column col-md-5 col-sm-12 col-xs-12"
              data-aos="fade-up"
            >
              <div class="inner-column">
                <div class="image imi">
                  <img src="./images/h1.jpg" alt="" style={{ height: "350px" }} />
                </div>
                <div class="small-img">
                  <img src="images/resource/success-2.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="header-top head">
              <div className="auto-container">
                <div className="clearfix">
                  {/* Top Left */}
                  <div className="top-left pull-left">
                    <ul className="clearfix">
                      <li data-aos="fade-right"><FaMapLocationDot style={{ color: "#F77522", marginRight: "5px", fontSize: "29px" }} />The Pride Apartment, Near Bus Stand, Lane No.1, Jayprakash Nagar, Dhanbad, Jharkhand - 826001</li>
                    </ul>
                  </div>
                  {/* Top Right */}

                </div>
              </div>
            </div>
            {/* <!--Content Column--> */}
            <div
              class="content-column col-md-7 col-sm-12 col-xs-12"
              data-aos="fade-down"
            >
              <div class="inner-column">
                <div class="since-year clearfix resposnive_world">
                  <span class="title">since</span>
                  <div class="year-img mask1">
                     <img src="./images/2016.png" alt="2016" className="resposnive_2016"/>
                  </div>
                  <div class="work">
                    8 Years OF<strong>Success</strong>
                    <span>Work</span>
                  </div>
                </div>
                <div
                  class="text"
                  style={{ textAlign: "justify" }}
                  data-aos="fade-right"
                >
                  Welcome to D TECH - Empowering Engineering Excellence!
                  We are Providing Professional Consultancy Services for IOCL/ BPCL/ HPCL/ GAIL GAS, is an Engineers Associated Company working since November 2016. We are engaged in Design & Detailed Engineering works for Architectural Design, Highway Projects, Building Projects, Geo-technical Investigation, Land Surveying, Estimating & Costing, Scheduling, etc. We also prepare NOC cases for obtaining Access Permissions from Mort&H, NHAI, PWD etc.
                  <br />
                  <span>{"  "}</span>

                  <button style={{ backgroundColor: "#F77522", width: "130px", color: "white",marginTop:"10px" }}><Link to="/about" style={{ color: "white" }}>Read More...</Link></button>
                </div>

                <div class="fact-counter">
                  <div class="row clearfix">
                    {/* <!--Column--> */}
                    <div class="column counter-column col-md-3 col-sm-6 col-xs-12">
                      <div class="inner"></div>
                    </div>

                    {/* <!--Column--> */}

                    {/* <!--Column--> */}

                    {/* <!--Column--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <!--End Success Section--> */}

      {/* <section>
        <div class="sec-title centered" data-aos="fade-left">
          <h2>
            <span class="theme_color">Histroy</span> Build
          </h2>
        </div>

        <div className="resposnive_home_aboutus">
          <div>
            <div style={{ backgroundColor: "#F77522", height: "60px", width: "80px", marginBottom: "-50px" }}></div>
            <img src="images/aboutus3.jpg" alt="" />
            <div style={{ backgroundColor: "#F77522", height: "60px", width: "80px", marginTop: "-50px" }}></div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="content_home">
              <p style={{ textAlign: "justify" }}>
                Welcome to D TECH - Empowering Engineering Excellence!
                D TECH, We are providing Consultancy of Professional Services for IOCL/ BPCL/ HPCL/ GAIL GAS, is an Engineers Associated Company working since November 2016. We are engaged in Design & Detailed Engineering works for Architectural Design, Highway Projects, Building Projects, Geo-technical Investigation, Land Surveying, Estimating & Costing, Scheduling, etc. We also prepare NOC cases for obtaining Access Permissions from Mort&H, NHAI, PWD etc.
              </p>
            </div>
            <button style={{ backgroundColor: "#F77522", width: "140px", marginLeft: "30px" }}>
              <Link to="/about">See More</Link>
            </button>
          </div>
        </div>

      </section> */}
      {/* <!--Services Section Two--> */}
      <section
        class="services-section-two"
        style={{ backgroundImage: "url(images/background/4.jpg)" }}
      >
        <div class="auto-container">
          <div class="sec-title centered" data-aos="fade-left">
            <div class="title">Services We Offer & Solutions</div>
            <h2>
              <span class="theme_color">Our</span> Main Services
            </h2>
          </div>
          <div class="row clearfix">
            {/* <!--Services Block Two--> */}
            <div
              class="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-right"
            >
              <div class="inner-box">
                <div class="upper-box">
                  <div class="icon-box">
                    <MdOutlineDesignServices />
                  </div>
                  <h3>
                    <a href="/design">Design and Build</a>
                  </h3>
                </div>
                <div class="text">
                  Our team of experienced designers and architects work hand in hand with our clients to bring their vision to life.

                </div>
                <a href="/design" class="read-more">
                  Read More{"/design "}
                  <IoMdArrowDroprightCircle class="fa fa-angle-right" />
                </a>
              </div>
            </div>

            {/* <!--Services Block Two--> */}
            <div
              class="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-down"
            >
              <div class="inner-box">
                <div class="upper-box">
                  <div class="icon-box">
                    <MdManageHistory />
                  </div>
                  <h3>
                    <a href="/management">Management Work</a>
                  </h3>
                </div>
                <div class="text">
                  Our team of experienced designers and architects work hand in hand with our clients to bring their vision to life.
                </div>
                <a href="/management" class="read-more">
                  Read More{"/management "}
                  <IoMdArrowDroprightCircle class="fa fa-angle-right" />
                </a>
              </div>
            </div>

            {/* <!--Services Block Two--> */}
            <div
              class="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-left"
            >
              <div class="inner-box">
                <div class="upper-box">
                  <div class="icon-box">
                    <SiConstruct3 />
                  </div>
                  <h3>
                    <a href="/construction">Construction Planning</a>
                  </h3>
                </div>
                <div class="text">
                  Our team of experts is well-versed in all aspects of construction planning, from project management to procurement and logistics.
                </div>
                <a href="/construction" class="read-more">
                  Read More{"/construction "}
                  <IoMdArrowDroprightCircle class="fa fa-angle-right" />
                </a>
              </div>
            </div>
            <div
              class="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-down"
            >
              <div class="inner-box">
                <div class="upper-box">
                  <div class="icon-box">
                    <SiAntdesign />
                  </div>
                  <h3>
                    <a href="/interior">Interior Design</a>
                  </h3>
                </div>
                <div class="text">
                  We use the latest design trends, materials, and finishes to create harmonious and comfortable environments that enhance daily living.
                </div>
                <a href="/interior" class="read-more">
                  Read More{"/interior"}
                  <IoMdArrowDroprightCircle class="fa fa-angle-right" />
                </a>
              </div>
            </div>
            {/* <!--Services Block Two--> */}
            <div
              class="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-right"
            >
              <div class="inner-box">
                <div class="upper-box">
                  <div class="icon-box">
                    <FaBuildingColumns />
                  </div>
                  <h3>
                    <a href="/land">Land Survey</a>
                  </h3>
                </div>
                <div class="text">
                  We use state-of-the-art technology and techniques to accurately and efficiently survey the land.
                </div>
                <a href="/land" class="read-more">
                  Read More{"/land"}
                  <IoMdArrowDroprightCircle class="fa fa-angle-right" />
                </a>
              </div>
            </div>

            {/* <!--Services Block Two--> */}


            {/* <!--Services Block Two--> */}
            <div
              class="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-left"
            >
              <div class="inner-box">
                <div class="upper-box">
                  <div class="icon-box">
                    <MdEmojiTransportation />
                  </div>
                  <h3>
                    <a href="/soil">Soil Testing</a>
                  </h3>
                </div>
                <div class="text">
                  We use advanced testing methods and equipment to accurately assess the soil on our clients' sites.
                </div>
                <a href="/soil" class="read-more">
                  Read More{"/soil "}
                  <IoMdArrowDroprightCircle class="fa fa-angle-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Services Section Two--> */}

      {/* <!--Team Section--> */}
      <section class="team-section">
        <div class="auto-container">
          <div class="sec-title centered">
            <div class="title">Expert Worker</div>
            <h2>
              <span class="theme_color">Our</span> Dedicated Team
            </h2>
          </div>
          <div class="row clearfix responsive_team" >
            {/* <!--Team Block--> */}



            {/* <!--Team Block--> */}
            <div
              class="team-block col-lg-4 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-down-right"
            >
              <div class="inner-box" style={{ width: "300px" }}>
                <div class="image">
                  <img src="/images/gallery/wg1.jpeg" alt="" />
                  <div class="overlay-box">
                    <h3> Mr. Deepak</h3>
                   
                    <div class="designation">
                      <p className="deepak_info">

                     
                      He has 7 years of experience in the field of Engineering, with full expertise in preparing drawings, layouts, conducting site visits, performing surveys, soil testing and analysis, and creating structural engineering drawings.
                      </p>
                    </div>

                  </div>
                </div>

              </div>
              <p className="deepak"> 
              
              <h1 style={{backgroundColor:"rgb(4, 32, 56)",padding:"5px",color:"white",textAlign:"center",fontWeight:"500"}}><b>MR. DEEPAK</b> </h1>
                 He has 7 years of experience in the field of Engineering, with full expertise in preparing drawings, layouts, conducting site visits, performing surveys, soil testing and analysis, and creating structural engineering drawings.
              </p>
            </div>

            {/* <!--Team Block--> */}



            {/* <!--Team Block--> */}

          </div>
        </div>
      </section>
      
      {/* <!--End Team Section--> */}
      <section>
        <div>
          <ServiceSlider />
        </div>
      </section>
    
      <section>
        <div class="sec-title centered">
          <h2>
            <span class="theme_color">Skill</span> Development Training
          </h2>
        </div>

        <div className="training_container">
          <div className="content_section">
            <div className="training_first_bg">
              <img
                src="images/cad1.jpeg"
                alt=""
                className="training_image"
              />
            </div>
            <div className="training_description">
              <p>
                CAD Desk offers a wide range of courses tailored for professionals and
                students in the fields of engineering, architecture, and design. The
                courses include training in popular CAD software such as AutoCAD,
                Revit, SolidWorks, and CATIA. Each course is designed to provide
                hands-on experience and practical knowledge,
              </p>
            </div>
            <div className="dropdown">
              <button
                className="search-box-btn"
                type="button"
                id="dropdownMenu3"
              >
                <Link to="/cadtraining" style={{ color: "white" }}>Training</Link>
              </button>
            </div>
          </div>

          <div className="info_section">
            <div>
              <h3 style={{ color: "white" }}>Training Location</h3>
              <div className="map_container">


                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58465.517783538984!2d86.05862379677075!3d23.672565378305944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f422fe608a6487%3A0x3ada376db2df947a!2sAgardih%2C%20Jharkhand%20827001!5e0!3m2!1sen!2sin!4v1716531282420!5m2!1sen!2sin" allowfullscreen="" className="map" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

              </div>
              <h3 style={{ color: "white" }}>Course Types</h3>
              {/* <div className="course_container">
                <img
                  src="images/cd1.jpeg"
                  alt=""
                  className="course_image"
                />
              
                <img
                  src="images/download.jpeg"
                  alt=""
                  className="course_image"
                />

              </div> */}
              <div className="course_container">
                <div className="course_item">
                  <h3 className="course_label">Mechanical</h3>
                  <img
                    src="images/cd1.jpeg"
                    alt="Mechanical"
                    className="course_image"
                  />
                </div>
                <div className="course_item">
                  <h3 className="course_label">Civil</h3>
                  <img
                    src="images/download.jpeg"
                    alt="Civil"
                    className="course_image"
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="sec-title centered">
          <h2>
            <span class="theme_color">Construction</span> Work
          </h2>
        </div>

        <div class="container_3d" style={{ marginTop: "-90px" }}>
          <div class="content_3d">
            <div class="sec-title" data-aos="fade-down">
              <div class="title"></div>
              
            </div>
            <p style={{ textAlign: "justify" }}>Construction is the process of building structures, from residential homes to large commercial buildings. A 3D image can illustrate the construction phases, from the foundation to the finished structure.
              Subsequently, walls, roofs, and internal systems like plumbing and electrical wiring are added. Finally, the exterior and interior finishes are completed, resulting in a fully constructed building. This visual representation helps in understanding the complex steps involved in construction.
            </p><br />
            <button style={{ backgroundColor: "#F77522", padding: "5px", borderRadius: "4px",width:"50%" }}>
              <Link to="/Gallery" style={{ color: "white" }}>Show More</Link>
            </button>
          </div>
          <div class="image_3d">
            <img src="images/gallery/dtech1.PNG" alt="Right Side Image" className="img_3d" style={{ height: "310px" }} />
           
          </div>
        </div>

      </section>
      {/* <!--Testmional Slider--> */}
      <section>
        <HomeGallery />
      </section>


      <section class="call-to-action-section-two" >
        <div class="auto-container">
          <div class="row clearfix">

            <div class="column col-md-7 col-sm-12 col-xs-12">
              <h2 className="join"><span style={{color:"white"}} class="theme_color">Enroll today</span>  to enhance your abilities with our internship and CAD training programs!</h2>
              <div class="text" style={{color:"black"}}>For more information, please contact us at available number.</div>
            </div>
            <div class="btn-column col-md-5 col-sm-12 col-xs-12" >
              <div class="number"><FaWhatsapp style={{color:"green",fontWeight:"600"}}/> +91 9263636301   </div>
              <div class="number"><FiPhone style={{fontWeight:"600",color:"white"}}/> +91 8797046868  </div>
            </div>

          </div>
        </div>
      </section>



      <section>
        <Marque />
      </section>


    </div>
  );
};

export default Home;
