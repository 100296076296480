// Write your code here
import React,{useEffect} from 'react'
import { Link } from 'react-router-dom';
import { FaPlus } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import './TranningItem.css'

const TranningItem = props => {

  const {Appitem} = props
  const {appName, category, imageUrl,brief,appId} = Appitem

  useEffect(() => {
    AOS.init();

  }, []);

 

  return (
    <>
    <div className="appItemcontainer" data-aos="fade-up"
     data-aos-duration="2000">
      <div>
      <img src={imageUrl} alt={appName} className="appimage" />
      </div>
       <div style={{margin:"10px"}}>
           <h3 style={{color:"#F77522",fontWeight:"bold"}}>{appName}</h3>
           <p>{brief}</p>
           <Link to={`/coursedetails/${appId}`}><button className='learn_more'>
               <FaPlus style={{color:"white"}}/>
            </button></Link>
       </div>
    </div>

    </>
  )
}
export default TranningItem;
