import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Chart } from 'chart.js';
import './Expenseslist.css'
const GraphComponent = () => {
  const [data, setData] = useState([]);
  const [graphType, setGraphType] = useState('weekly');
  const chartRef = useRef(null);

  useEffect(() => {
    fetchData(graphType);
    return () => {
      if (chartRef.current && chartRef.current.chartInstance) {
        chartRef.current.chartInstance.destroy();
      }
    };
  }, [graphType]);

  const fetchData = async (type) => {
    try {
      const response = await axios.get(`/api/accounts/${type}`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const formatGraphData = () => {
    const labels = data.map(item => {
      if (graphType === 'weekly') {
        return `Week ${item._id.week}, ${item._id.year}`;
      } else if (graphType === 'monthly') {
        return `Month ${item._id.month}, ${item._id.year}`;
      } else if (graphType === 'yearly') {
        return `Year ${item._id.year}`;
      }
      return '';
    });

    const inflowData = data.map(item => item.totalInflow);
    const outflowData = data.map(item => item.totalOutflow);

    return {
      labels,
      datasets: [
        {
          label: 'Total Inflow',
          backgroundColor: 'rgba(75,192,192,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(75,192,192,0.8)',
          hoverBorderColor: 'rgba(75,192,192,1)',
          data: inflowData,
        },
        {
          label: 'Total Outflow',
          backgroundColor: 'rgba(255,99,132,0.6)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.8)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: outflowData,
        }
      ]
    };
  };

  useEffect(() => {
    if (chartRef.current && chartRef.current.chartInstance) {
      chartRef.current.chartInstance.destroy();
    }
    const ctx = chartRef.current.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'bar',
      data: formatGraphData(),
      options: {
        responsive: true,
        scales: {
          x: {
            type: 'category',
            title: {
              display: true,
              text: graphType.charAt(0).toUpperCase() + graphType.slice(1) + ' Data'
            }
          },
          y: {
            title: {
              display: true,
              text: 'Amount'
            },
            beginAtZero: true
          }
        }
      }
    });

    chartRef.current.chartInstance = myChart;

    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, [graphType, data]);

  return (
    <div>
      <div>
        <button onClick={() => setGraphType('weekly')}>
          Weekly
        </button>
        <button onClick={() => setGraphType('monthly')}>Monthly</button>
        <button onClick={() => setGraphType('yearly')}>Yearly</button>
      </div>
      <canvas ref={chartRef} />
    </div>
  );
};

export default GraphComponent;
