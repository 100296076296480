import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaMapLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { MdMarkEmailRead } from "react-icons/md";
import { BsChevronDoubleRight } from "react-icons/bs";
import { MdAddIcCall } from "react-icons/md";
const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []);
    const [status, setStatus] = useState("Submit");
    const navigate = useNavigate();

    const [info, setInfo] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInfo({
            ...info,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let response = await fetch(`/api/contact`, {
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify(info),
            });

            let result = await response.json();
            if (result.status === "Message Sent") {
                setStatus("Submitted");
                setInfo({ name: "", email: "", phone: "", subject: "", message: "" });
                navigate("/thankyou");
            } else {
                setStatus("Error");
            }
            console.log(result);
        } catch (error) {
            console.error("Error submitting form:", error);
            setStatus("Error");
        }
    };
    return (
        <div>
            <section class="page-title" style={{ backgroundImage: `url('images/background/6.jpg')` }}>
                <div class="auto-container">
                    <div class="clearfix">
                        <div class="pull-left">
                            <h2 style={{ color: "white" }}>Contact Us</h2>
                        </div>
                        <div class="pull-right">
                            <ul class="page-breadcrumb">
                                <li><a href="/">Home</a> <BsChevronDoubleRight /></li>

                                <li style={{ marginLeft: "-23px" }}>Contact Us</li>
                            </ul>
                        </div>
                    </div>
                    <div class="contact-number text-center"><span class="icon "><MdAddIcCall /></span>Call Us: +919263636301 /+918797046868 </div>
                </div>
            </section>



            <section class="contact-section">
                <div class="auto-container">

                    <h2><span class="theme_color">Get</span> in Touch</h2>
                    <div class="text">You can talk to our online representative at any time. Please use our Live Chat System on our website or Fill up below instant messaging programs. <br /> Please be patient, Our 24/7 Support system will get back to you in short time. <br/> </div>
                    <p>For General Inquiries:+91 9263636301 /+91 8797046868</p>
                    <div class="row clearfix">
                        <div class="form-column col-lg-9 col-md-8 col-sm-12 col-xs-12">
                            <div class="inner-column">


                                <div class="contact-form style-two">
                                    <form method="post" id="contact-form" onSubmit={handleSubmit} noValidate>
                                        <div className="row clearfix">
                                            <div className="form-group col-md-6 col-sm-6 co-xs-12">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    value={info.name}
                                                    onChange={handleChange}
                                                    placeholder="Your Name"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-md-6 col-sm-6 co-xs-12">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    value={info.email}
                                                    onChange={handleChange}
                                                    placeholder="Email Address"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-md-6 col-sm-6 co-xs-12">
                                                <input
                                                    type="tel"
                                                    name="phone"
                                                    id="phone"
                                                    value={info.phone}
                                                    onChange={handleChange}
                                                    placeholder="91+"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-md-6 col-sm-6 co-xs-12">
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    id="subject"
                                                    value={info.subject}
                                                    onChange={handleChange}
                                                    placeholder="Your Subject"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-md-12 col-sm-12 co-xs-12">
                                                <textarea
                                                    name="message"
                                                    id="message"
                                                    placeholder="Your Comment"
                                                    required
                                                    value={info.message}
                                                    onChange={handleChange}
                                                ></textarea>
                                            </div>
                                            <div className="form-group col-md-12 col-sm-12 co-xs-12">
                                                <button style={{width:"100px"}} className="theme-btn btn-style-one" type="submit">
                                                    <span>{status}</span>
                                                </button>
                                            </div>
                                        </div>
                                        <p className="form-message"></p>
                                    </form>
                                </div>


                            </div>
                        </div>
                        <div class="info-column col-lg-3 col-md-4 col-sm-12 col-xs-12">

                            <ul class="list-style-two">
                                <li><FaMapLocationDot class="icon flaticon-home-1" style={{ padding: "10px", color: "#F77522" }} /><strong>Address,</strong>The Pride, Apartment Near, Bus Stand, Lane No.1, Jayprakash Nagar, Dhanbad, Jharkhand - 826001</li>
                                <li><MdMarkEmailRead class="icon flaticon-envelope-1" style={{ padding: "10px", color: "#F77522" }} /><strong>Send your mail at</strong><a href='mailto:dtechwale@gmail.com' target='_black' style={{ color: "#777777" }}>dtechwale@gmail.com</a></li>
                                <li><FaPhone class="icon flaticon-technology-2" style={{ padding: "10px", color: "#F77522" }} /><strong>Have Any Question</strong>Call Us: +919263636301 /+918797046868 </li>

                            </ul>

                        </div>
                    </div>
                </div>
            </section>

            <section
                class="services-section-two"
                style={{ marginTop: "-130px" }}
            >
                <div class="auto-container">
                    <div class="sec-title centered" data-aos="fade-left">
                        <div class="title" style={{ color: "white" }}>d</div>
                        <h2>
                            <span class="theme_color">Our</span> Branches
                        </h2>
                    </div>
                    <div class="row clearfix">
                        {/* <!--Services Block Two--> */}
                        <div
                            class="services-block-two col-lg-3 col-md-6 col-sm-6 col-xs-12"
                            data-aos="fade-right"
                        >
                            <div class="inner-box">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d233667.6783094076!2d85.94791979017634!3d23.780967631033835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f419c0c73a26df%3A0x3828fc26dc504e52!2sChandrapura%2C%20Jharkhand!5e0!3m2!1sen!2sin!4v1716531125979!5m2!1sen!2sin" width="200" height="180" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                <div class="text">
                                C/o Kaleshwar Mahto, Taranari, Chandrapura, Bokaro, Jharkhand.
                                </div>
                                <p><b>Call us:+91 9263636301</b></p>

                            </div>
                        </div>

                        {/* <!--Services Block Two--> */}
                        <div
                            class="services-block-two col-lg-3 col-md-6 col-sm-6 col-xs-12"
                            data-aos="fade-down"
                        >
                            <div class="inner-box">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14601.136505133118!2d86.40928455229451!3d23.808493584503807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f6bcae96182cf1%3A0xe3b0673c0dbab0f0!2sJay%20Prakash%20Nagar%2C%20Jharudih%2C%20Dhanbad%2C%20Jharkhand%20826001!5e0!3m2!1sen!2sin!4v1716531201830!5m2!1sen!2sin" width="200" height="180" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                <div class="text">
                                CAD DESK, Pride Apartment, Near Bus Stand Gali No.1, Denobili School road, behind LIC Office, Jay Prakash Nagar, Dhanbad, Jharkhand
                                </div>
                                <p><b>Call us:+91 9263636301</b></p>
                            </div>
                        </div>

                        {/* <!--Services Block Two--> */}
                        <div
                            class="services-block-two col-lg-3 col-md-6 col-sm-6 col-xs-12"
                            data-aos="fade-left"
                        >
                            <div class="inner-box">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58465.517783538984!2d86.05862379677075!3d23.672565378305944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f422fe608a6487%3A0x3ada376db2df947a!2sAgardih%2C%20Jharkhand%20827001!5e0!3m2!1sen!2sin!4v1716531282420!5m2!1sen!2sin" width="200" height="180" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                <div class="text">
                                gf - 7, D tech, city centre, near the Bokaro mall, Bokaro steel city, Jharkhand
                                </div>
                                <p><b>Call us:+91 9263636301</b></p>
                            </div>
                        </div>

                        {/* <!--Services Block Two--> */}
                        <div
                            class="services-block-two col-lg-3 col-md-6 col-sm-6 col-xs-12"
                            data-aos="fade-right"
                        >
                            <div class="inner-box">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3678.4257573131326!2d86.16133237218953!3d22.786682679451534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f5ee5263e2a86b%3A0xa13151b326646bc!2sTata%20Kandra%20Rd%2C%20Aditypur%201%2C%20Adityapur%2C%20Jamshedpur%2C%20Jharkhand!5e0!3m2!1sen!2sin!4v1716531323095!5m2!1sen!2sin" width="200" height="180" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                <div class="text">
                                CAD DESK, 2nd Floor, Suman Tower, Opposite to Jana Small Finance Bank, Tata Kandra Main Road, In front of Jiada, Adityapur-1. PO & PS – Adityapur, Dist. – Seraikela-Kharsawan, - Jharkhand
                                </div>
                                <p><b>Call us:+91 9263636301</b></p>
                            </div>
                        </div>

                        {/* <!--Services Block Two--> */}

                    </div>
                </div>
            </section>

            <section class="map-section">
                <div class="auto-container-fluid">
                    <div class="sec-title centered">
                        <h2><span class="theme_color">Location</span></h2>
                    </div>

                    <div class="map-outer">

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14601.136505133118!2d86.40928455229451!3d23.808493584503807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f6bcae96182cf1%3A0xe3b0673c0dbab0f0!2sJay%20Prakash%20Nagar%2C%20Jharudih%2C%20Dhanbad%2C%20Jharkhand%20826001!5e0!3m2!1sen!2sin!4v1715328643734!5m2!1sen!2sin" width="1800" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy"></iframe>

                    </div>
                </div>
            </section>

        </div>
    )
}

export default ContactUs
