// import React, { useState } from "react";
// import axios from "axios";
// import '../Expenseslist/Expenseslist.css'
// import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
// import "react-toastify/dist/ReactToastify.css";
// import {
//   PDFDownloadLink,
//   Document,
//   Page,
//   Text,
//   View,
//   StyleSheet,
// } from "@react-pdf/renderer";


// const formatDate = (dateString) => {
//   const date = new Date(dateString);
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const day = String(date.getDate()).padStart(2, "0");
//   const year = date.getFullYear();
//   return `${month}/${day}/${year}`;
// };

// const AccountTablePDF = ({ accounts }) => (
//   <View style={styles.table}>
//     <View style={styles.tableRow}>
//       <Text style={styles.headerCell}>Eid</Text>
//       <Text style={styles.headerCell}>Date</Text>
//       <Text style={styles.headerCell}>Reason</Text>
//       <Text style={styles.headerCell}>Amount</Text>
//       <Text style={styles.headerCell}>Cash Flow</Text>
//       <Text style={styles.headerCell}>Person Name</Text>
//       <Text style={styles.headerCell}>Description</Text>
//     </View>
//     {accounts.map((account, index) => (
//       <View style={styles.tableRow} key={index}>
//         <Text style={styles.cell}>{account.Eid}</Text>
//         <Text style={styles.cell}>{formatDate(account.date)}</Text>
//         <Text style={styles.cell}>{account.reason}</Text>
//         <Text style={styles.cell}>{account.amount}</Text>
//         <Text style={styles.cell}>{account.cashFlow}</Text>
//         <Text style={styles.cell}>{account.personName}</Text>
//         <Text style={styles.cell}>{account.description}</Text>
//       </View>
//     ))}
//   </View>
// );

// const PDFDocument = ({ accounts }) => (
//   <Document>
//     <Page style={styles.page}>
//       <Text style={styles.header}>Account Details</Text>
//       <AccountTablePDF accounts={accounts} />
//     </Page>
//   </Document>
// );

// const FetchAccountData = () => {
//   const [accounts, setAccounts] = useState([]);
//   const [totalInflow, setTotalInflow] = useState(0);
//   const [totalOutflow, setTotalOutflow] = useState(0);
//   const [error, setError] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");

//   const handleFetchAccount = async () => {
//     setLoading(true);
//     setError("");
//     try {
//       const response = await axios.get(
//         `http://localhost:5000/api/accounts/byDateRange`,
//         {
//           params: { startDate, endDate },
//         }
//       );
//       if (response.data.accounts.length > 0) {
//         setAccounts(response.data.accounts);
//         setTotalInflow(response.data.totalInflow);
//         setTotalOutflow(response.data.totalOutflow);
//       } else {
//         setError("No account data found for the specified date range.");
//       }
//     } catch (err) {
//       setError("Error fetching account data. Please try again later.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const currentDate = new Date().toLocaleDateString().replace(/\//g, "-");

//   const inputStyle = {
//     backgroundColor: "#fff",
//     padding: "1rem",
//     paddingRight: "3rem",
//     fontSize: "0.875rem",
//     lineHeight: "1.25rem",
//     borderRadius: "0.5rem",
//     boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
//     border: "1px solid #e5e7eb",
//     margin: "7px",
//   };

//   return (
//     <div>
//          <div className="sec-title centered">
//                 <h2>
//                     <span className="theme_color">Our </span> Expenses
//                 </h2>
//             </div>
//       <div style={{ display: "flex", marginRight: "20px", justifyContent: "space-around", flexWrap: "wrap" }}>
//         <div className="form-group" style={inputStyle}>
//           <label htmlFor="startDate">Start Date:</label>
//           <input type="date" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />
//         </div>

//         <div className="form-group" style={inputStyle}>
//           <label htmlFor="endDate">End Date:</label>
//           <input type="date" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} required />
//         </div>

//         <button onClick={handleFetchAccount} disabled={!startDate || !endDate || loading} style={inputStyle}>
//           {loading ? <span className="spinner"></span> : "Fetch Account"}
//         </button>
//       </div>

//       <div className="resposive_revenue_container">
//         <p className="form-group" style={{ ...inputStyle, width: "170px" }}>
//           Revenue: {totalInflow}
//         </p>
//         <p className="form-group" style={{ ...inputStyle, width: "170px" }}>
//           Expense: {totalOutflow}
//         </p>
//       </div>

//       {loading && <p>Loading...</p>}
//       {error && <p>{error}</p>}

//       {accounts.length > 0 && (
//         <div>
//           <table id="customers">
//             <thead>
//               <tr>
//                 <th>Eid</th>
//                 <th>Date</th>
//                 <th>Reason</th>
//                 <th>Amount</th>
//                 <th>Cash Flow</th>
//                 <th>Person Name</th>
//                 <th>Description</th>
//               </tr>
//             </thead>
//             <tbody>
//               {accounts.map((account, index) => (
//                 <tr key={index}>
//                   <td data-label="Eid">{account.Eid}</td>
//                   <td data-label="Date">{formatDate(account.date)}</td>
//                   <td data-label="Reason">{account.reason}</td>
//                   <td data-label="Amount">{account.amount}</td>
//                   <td data-label="Cash Flow">{account.cashFlow}</td>
//                   <td data-label="Person Name">{account.personName}</td>
//                   <td data-label="Description">{account.description}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           <ToastContainer />
//           <PDFDownloadLink
//   document={<PDFDocument accounts={accounts} />}
//   fileName={`account_details_${currentDate}.pdf`}
//   // Add onClick callback to show toast
//   onClick={() => toast.success("Invoice downloaded successfully")}
// >
//             {({ loading }) => (
//               <button
//                 style={{
//                   backgroundColor: "#4CAF50",
//                   color: "white",
//                   padding: "10px 20px",
//                   textAlign: "center",
//                   textDecoration: "none",
//                   display: "inline-block",
//                   fontSize: "16px",
//                   margin: "4px 2px",
//                   cursor: "pointer",
//                   borderRadius: "12px",
//                   border: "none",
//                   marginTop: "8px",
//                   marginRight: "8px",
//                 }}
//               >
//                 {loading ? "Generating PDF..." : "Download PDF"}
//               </button>
//             )}
//           </PDFDownloadLink>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FetchAccountData;

// const styles = StyleSheet.create({
//   page: {
//     padding: 30,
//   },
//   header: {
//     fontSize: 18,
//     marginBottom: 10,
//   },
//   table: {
//     display: "table",
//     width: "auto",
//     borderStyle: "solid",
//     borderWidth: 1,
//     borderColor: "#bfbfbf",
//     marginBottom: 10,
//   },
//   tableRow: {
//     flexDirection: "row",
//     flexGrow: 1,
//     flexShrink: 1,
//     flexWrap: "wrap",
//     minWidth: "100%",
//   },
//   headerCell: {
//     padding: 5,
//     fontWeight: "bold",
//     backgroundColor: "#e0e0e0",
//     borderStyle: "solid",
//     borderWidth: 1,
//     borderColor: "#bfbfbf",
//     borderLeftWidth: 0,
//     borderTopWidth: 0,
//     flex: 1,
//   },
//   cell: {
//     padding: 5,
//     borderStyle: "solid",
//     borderWidth: 1,
//     borderColor: "#bfbfbf",
//     borderLeftWidth: 0,
//     borderTopWidth: 0,
//     flex: 2,
//   },
// });




import React, { useState } from "react";
import axios from "axios";
import '../Expenseslist/Expenseslist.css'
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const AccountTablePDF = ({ accounts }) => (
  <View style={styles.table}>
    <View style={styles.tableRow}>
      <Text style={styles.headerCell}>Eid</Text>
      <Text style={styles.headerCell}>Date</Text>
      <Text style={styles.headerCell}>Reason</Text>
      <Text style={styles.headerCell}>Amount</Text>
      <Text style={styles.headerCell}>Cash Flow</Text>
      <Text style={styles.headerCell}>Person Name</Text>
      <Text style={styles.headerCell}>Description</Text>
    </View>
    {Array.isArray(accounts) && accounts.map((account, index) => (
      <View style={styles.tableRow} key={index}>
        <Text style={styles.cell}>{account.Eid}</Text>
        <Text style={styles.cell}>{formatDate(account.date)}</Text>
        <Text style={styles.cell}>{account.reason}</Text>
        <Text style={styles.cell}>{account.amount}</Text>
        <Text style={styles.cell}>{account.cashFlow}</Text>
        <Text style={styles.cell}>{account.personName}</Text>
        <Text style={styles.cell}>{account.description}</Text>
      </View>
    ))}
  </View>
);

const PDFDocument = ({ accounts }) => (
  <Document>
    <Page style={styles.page}>
      <Text style={styles.header}>Account Details</Text>
      <AccountTablePDF accounts={accounts} />
    </Page>
  </Document>
);

const FetchAccountData = () => {
  const [accounts, setAccounts] = useState([]);
  const [totalInflow, setTotalInflow] = useState(0);
  const [totalOutflow, setTotalOutflow] = useState(0);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleFetchAccount = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        `/api/accounts/byDateRange`,
        {
          params: { startDate, endDate },
        }
      );
      if (Array.isArray(response.data.accounts) && response.data.accounts.length > 0) {
        setAccounts(response.data.accounts);
        setTotalInflow(response.data.totalInflow);
        setTotalOutflow(response.data.totalOutflow);
      } else {
        setError("No account data found for the specified date range.");
      }
    } catch (err) {
      setError("Error fetching account data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const currentDate = new Date().toLocaleDateString().replace(/\//g, "-");


  return (
    <div>
      <div className="sec-title centered">
        <h2>
          <span className="theme_color">Our </span> Expenses
        </h2>
      </div>
      <div style={{ display: "flex", marginRight: "20px", justifyContent: "space-around", flexWrap: "wrap" }}>
        <div className="form-group1" >
          <label htmlFor="startDate">Start Date:</label>
          <input type="date" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />
        </div>

        <div className="form-group1">
          <label htmlFor="endDate">End Date:</label>
          <input type="date" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} required />
        </div>

        <button onClick={handleFetchAccount} disabled={!startDate || !endDate || loading} >
          {loading ? <span className="spinner"></span> : "Fetch Account"}
        </button>
      </div>

      <div className="resposive_revenue_container">
        <p className="form-group1" style={{ width: "170px" }}>
          Revenue: {totalInflow}
        </p>
        <p className="form-group1" style={{  width: "170px" }}>
          Expense: {totalOutflow}
        </p>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      {Array.isArray(accounts) && accounts.length > 0 && (
        <div>
          <table id="customers">
            <thead>
              <tr>
                <th>Eid</th>
                <th>Date</th>
                <th>Reason</th>
                <th>Amount</th>
                <th>Cash Flow</th>
                <th>Person Name</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {accounts.map((account, index) => (
                <tr key={index}>
                  <td data-label="Eid">{account.Eid}</td>
                  <td data-label="Date">{formatDate(account.date)}</td>
                  <td data-label="Reason">{account.reason}</td>
                  <td data-label="Amount">{account.amount}</td>
                  <td data-label="Cash Flow">{account.cashFlow}</td>
                  <td data-label="Person Name">{account.personName}</td>
                  <td data-label="Description">{account.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <ToastContainer />
          <PDFDownloadLink
            document={<PDFDocument accounts={accounts} />}
            fileName={`account_details_${currentDate}.pdf`}
            onClick={() => toast.success("Invoice downloaded successfully")}
          >
            {({ loading }) => (
              <button
                style={{
                  backgroundColor: "#4CAF50",
                  color: "white",
                  padding: "10px 20px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inline-block",
                  fontSize: "16px",
                  margin: "4px 2px",
                  cursor: "pointer",
                  borderRadius: "12px",
                  border: "none",
                  marginTop: "8px",
                  marginRight: "8px",
                }}
              >
                {loading ? "Generating PDF..." : "Download PDF"}
              </button>
            )}
          </PDFDownloadLink>
        </div>
      )}
    </div>
  );
};

export default FetchAccountData;

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
    flexGrow: 1,
    flexShrink: 1,
    flexWrap: "wrap",
    minWidth: "100%",
  },
  headerCell: {
    padding: 5,
    fontWeight: "bold",
    backgroundColor: "#e0e0e0",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flex: 1,
  },
  cell: {
    padding: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flex: 2,
  },
});
