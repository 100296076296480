import React from 'react'
import { Link } from 'react-router-dom';
import { IoHomeOutline } from "react-icons/io5";
import { GrGroup } from "react-icons/gr";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { SlCalender } from "react-icons/sl";
import { IoCalendarClearOutline } from "react-icons/io5";
import { MdOutlineReviews } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { MdOutlineManageAccounts } from "react-icons/md";
import { TbSettings2 } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { BsBank2 } from "react-icons/bs";
import { FaUserGraduate } from "react-icons/fa";
import './Adminhome.css'

const Adminhome = () => {
  return (
    <div>
      <div class="inner-wrapper" style={{ marginTop: "30px" }}>


        <header class="header">

          <div class="top-header-section">
            <div class="container-fluid">
              <div class="row align-items-center">

                <div class="col-lg-9 col-md-9 col-sm-9 col-6 text-right">
                  <div class="user-block d-none d-lg-block">
                    <div class="row align-items-center">

                    </div>
                  </div>
                  <div class="d-block d-lg-none">
                    <a href="javascript:void(0)">
                      <span class="lnr lnr-user d-block display-5 text-white" id="open_navSidebar"></span>
                    </a>

                    <div class="offcanvas-menu" id="offcanvas_menu">
                      <span class="lnr lnr-cross float-left display-6 position-absolute t-1 l-1 text-white" id="close_navSidebar"></span>
                      <div class="user-info align-center bg-theme text-center">
                        <a href="javascript:void(0)" class="d-block menu-style text-white">
                          <div class="user-avatar d-inline-block mr-3">
                            <img src="assets/img/profiles/img-6.jpg" alt="user avatar" class="rounded-circle img-fluid" width="55" />
                          </div>
                        </a>
                      </div>
                      <div class="user-notification-block align-center">
                        <div class="top-nav-search">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" />
                            <button class="btn" type="submit"><i class="fa fa-search"></i></button>
                          </form>
                        </div>
                      </div>
                      <hr />
                      <div class="user-menu-items px-3 m-0">
                        <a class="px-0 pb-2 pt-0" href="/">
                          <span class="media align-items-center">
                            <span class="lnr lnr-home mr-3"></span>
                            <span class="media-body text-truncate text-left">
                              <span class="text-truncate text-left">Dashboard</span>
                            </span>
                          </span>
                        </a>
                        <a class="p-2" href="employees.html">
                          <span class="media align-items-center">
                            <span class="lnr lnr-users mr-3"></span>
                            <span class="media-body text-truncate text-left">
                              <span class="text-truncate text-left">Employees</span>
                            </span>
                          </span>
                        </a>
                        <a class="p-2" href="company.html">
                          <span class="media align-items-center">
                            <span class="lnr lnr-apartment mr-3"></span>
                            <span class="media-body text-truncate text-left">
                              <span class="text-truncate text-left">Company</span>
                            </span>
                          </span>
                        </a>
                        <a class="p-2" href="calendar.html">
                          <span class="media align-items-center">
                            <span class="lnr lnr-calendar-full mr-3"></span>
                            <span class="media-body text-truncate text-left">
                              <span class="text-truncate text-left">Calendar</span>
                            </span>
                          </span>
                        </a>
                        <a class="p-2" href="leave.html">
                          <span class="media align-items-center">
                            <span class="lnr lnr-briefcase mr-3"></span>
                            <span class="media-body text-truncate text-left">
                              <span class="text-truncate text-left">Leave</span>
                            </span>
                          </span>
                        </a>
                        <a class="p-2" href="reviews.html">
                          <span class="media align-items-center">
                            <span class="lnr lnr-star mr-3"></span>
                            <span class="media-body text-truncate text-left">
                              <span class="text-truncate text-left">Reviews</span>
                            </span>
                          </span>
                        </a>
                        <a class="p-2" href="reports.html">
                          <span class="media align-items-center">
                            <span class="lnr lnr-rocket mr-3"></span>
                            <span class="media-body text-truncate text-left">
                              <span class="text-truncate text-left">Reports</span>
                            </span>
                          </span>
                        </a>
                        <a class="p-2" href="manage.html">
                          <span class="media align-items-center">
                            <span class="lnr lnr-sync mr-3"></span>
                            <span class="media-body text-truncate text-left">
                              <span class="text-truncate text-left">Manage</span>
                            </span>
                          </span>
                        </a>
                        <a class="p-2" href="settings.html">
                          <span class="media align-items-center">
                            <span class="lnr lnr-cog mr-3"></span>
                            <span class="media-body text-truncate text-left">
                              <span class="text-truncate text-left">Settings</span>
                            </span>
                          </span>
                        </a>
                        <a class="p-2" href="employment.html">
                          <span class="media align-items-center">
                            <span class="lnr lnr-user mr-3"></span>
                            <span class="media-body text-truncate text-left">
                              <span class="text-truncate text-left">Profile</span>
                            </span>
                          </span>
                        </a>
                        <a class="p-2" href="login.html">
                          <span class="media align-items-center">
                            <span class="lnr lnr-power-switch mr-3"></span>
                            <span class="media-body text-truncate text-left">
                              <span class="text-truncate text-left">Logout</span>
                            </span>
                          </span>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </header>


        <div class="page-wrapper" style={{ marginTop: "-70px" }}>
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-3 col-lg-4 col-md-12 theiaStickySidebar">
                <aside class="sidebar sidebar-user">
                  <div class="row">
                    <div class="col-12">
                      <div class="card ctm-border-radius shadow-sm grow" style={{ width: "290px" }}>
                        <div class="card-body py-4">
                          <div class="row">
                            <div class="col-md-12 mr-auto text-left">
                              <div class="custom-search input-group">
                                <div class="custom-breadcrumb">
                                  <ol class="breadcrumb no-bg-color d-inline-block p-0 m-0 mb-2">
                                    <li class="breadcrumb-item d-inline-block"><a href="/" class="text-dark">Home</a></li>
                                    <li class="breadcrumb-item d-inline-block active">Dashboard</li>
                                  </ol>
                                  <h4 class="text-dark">Admin Dashboard</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="user-card card shadow-sm bg-white text-center ctm-border-radius grow" style={{ width: "290px" }}>
                    <div class="user-info card-body">
                      <div class="user-avatar mb-4">
                        <img src="assets/img/profiles/img-13.jpg" alt="User Avatar" class="img-fluid rounded-circle" width="100" />
                      </div>
                      <div class="user-details">
                      <h2>Welcome Admin!</h2>
                     
                      </div>
                    </div>
                  </div>

                  <div class="sidebar-wrapper d-lg-block d-md-none d-none" >
                    <div class="card ctm-border-radius shadow-sm border-none grow" style={{ width: "290px" }}>
                      <div class="card-body">
                        <div class="row no-gutters">
                          <div className="col-6 align-items-center text-center">
                            <Link to="/adminhome" className="text-white active p-4 first-slider-btn ctm-border-right ctm-border-left ctm-border-top">
                              <span className="lnr font-23"><IoHomeOutline /></span>
                              <span className="nav-text">Dashboard</span>
                            </Link>
                          </div>

                          <div className="col-6 align-items-center shadow-none text-center">
                            <Link to="/admincarrers" className="text-dark p-4 second-slider-btn ctm-border-right ctm-border-top">
                              <span className="lnr font-23"><FaUserGraduate /></span>
                              <span className="nav-text">Careers</span>
                            </Link>
                          </div>
                          <div class="col-6 align-items-center shadow-none text-center">
                          <Link to="/expenses" className="text-dark p-4 second-slider-btn ctm-border-right ctm-border-top">
                              <span className="lnr font-23"><BsBank2 /></span>
                              <span className="nav-text">Expenses</span>
                            </Link>
                          </div>
                          <div class="col-6 align-items-center shadow-none text-center">
                            <a href="/Calender" class="text-dark p-4 ctm-border-right"><span class="lnr font-23"><SlCalender /></span><span class>Calendar</span></a>
                          </div>
                          <div class="col-6 align-items-center shadow-none text-center">
                            <a href="/Leave" class="text-dark p-4 ctm-border-right ctm-border-left"><span class="lnr font-23"><IoCalendarClearOutline /></span><span class>Leave</span></a>
                          </div>
                          <div class="col-6 align-items-center shadow-none text-center">
                            <a href="/Review" class="text-dark p-4 last-slider-btn ctm-border-right"><span class="lnr font-23"><MdOutlineReviews /></span><span class>Reviews</span></a>
                          </div>
                          <div class="col-6 align-items-center shadow-none text-center">
                            <a href="/Reports" class="text-dark p-4 ctm-border-right ctm-border-left"><span class="lnr font-23"><TbReportSearch /></span><span class>Reports</span></a>
                          </div>
                          <div class="col-6 align-items-center shadow-none text-center">
                            <a href="/Manage" class="text-dark p-4 ctm-border-right"><span class="lnr font-23"><MdOutlineManageAccounts /></span><span class>Manage</span></a>
                          </div>
                          <div class="col-6 align-items-center shadow-none text-center">
                            <a href="/Settings" class="text-dark p-4 last-slider-btn1 ctm-border-right ctm-border-left"><span class="lnr font-23"><TbSettings2 /></span><span class>Settings</span></a>
                          </div>
                          <div class="col-6 align-items-center shadow-none text-center">
                            <a href="/Employment" class="text-dark p-4 last-slider-btn ctm-border-right"><span class="lnr font-23"><CgProfile /></span><span class>Profile</span></a>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </aside>
              </div>
              <div class="col-xl-9 col-lg-8  col-md-12">
                <div class="quicklink-sidebar-menu ctm-border-radius shadow-sm bg-white card grow" style={{ width: "900px" }}>
                  <div class="card-body">
                    <ul class="list-group list-group-horizontal-lg">
                      <li class="list-group-item text-center active button-5"><a href="/" class="text-white">Admin Dashboard</a></li>
                      <li class="list-group-item text-center button-6"><a class="text-dark" href="">Employees Dashboard</a></li>
                    </ul>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="card dash-widget ctm-border-radius shadow-sm grow">
                      <div class="card-body">
                        <div class="card-icon bg-primary">
                          <i class="fa users" aria-hidden="true"><GrGroup /></i>
                        </div>
                        <div class="card-right">
                          <h4 class="card-title">Employees</h4>
                          <p class="card-text">700</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-6 col-sm-6 col-12">
                    <div class="card dash-widget ctm-border-radius shadow-sm grow">
                      <div class="card-body">
                        <div class="card-icon bg-warning">
                          <i class="fa building-o"><HiOutlineBuildingOffice /></i>
                        </div>
                        <div class="card-right">
                          <h4 class="card-title">Companies</h4>
                          <p class="card-text">30</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-6 col-sm-6 col-12">
                    <div class="card dash-widget ctm-border-radius shadow-sm grow">
                      <div class="card-body">
                        <div class="card-icon bg-danger">
                          <i class="fa suitcase" aria-hidden="true"><IoCalendarClearOutline /></i>
                        </div>
                        <div class="card-right">
                          <h4 class="card-title">Leaves</h4>
                          <p class="card-text">3</p>
                        </div>
                      </div>
                    </div>
                  </div>
                
                </div>


                {/* <div class="row">
                  <div class="col-md-6 d-flex">
                    <div class="card ctm-border-radius shadow-sm flex-fill grow">
                      <div class="card-header">
                        <h4 class="card-title mb-0">Total Employees</h4>
                      </div>
                      <div class="card-body">
                        <canvas id="pieChart"></canvas>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 d-flex">
                    <div class="card ctm-border-radius shadow-sm flex-fill grow">
                      <div class="card-header">
                        <h4 class="card-title mb-0">Total Salary By Unit</h4>
                      </div>
                      <div class="card-body">
                        <canvas id="lineChart"></canvas>
                      </div>
                    </div>
                  </div>
                </div> */}

                
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Adminhome
