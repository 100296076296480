import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Expenseslist/Expenseslist.css';

function Admin() {
    const [accountData, setAccountData] = useState({
        Eid: 1,
        date: '',
        reason: '',
        amount: '',
        cashFlow: 'Inflow',
        personName: '',
        description: ''
    });
    const [serialCounter, setSerialCounter] = useState(1); // Counter for serial number

    const [error, setError] = useState('');

    const handleAccountChange = (e) => {
        const { name, value } = e.target;
        setAccountData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleAccountSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            await axios.post('/api/accounts', accountData);
            setSerialCounter((prevCounter) => prevCounter + 1); // Increment the serial counter
            setAccountData({
                Eid: serialCounter + 1,
                date: '',
                reason: '',
                amount: '',
                cashFlow: 'Inflow',
                personName: '',
                description: ''
            });
            toast.success('Expenses added successfully!');
        } catch (err) {
            setError('Error submitting account data');
            toast.error('Error submitting account data');
        }
    }

    return (
        <div>
            <div className="sec-title centered">
                <h2>
                    <span className="theme_color">Add</span> Expenses
                </h2>
            </div>
            <div className="form-container cont" style={{marginTop:"-40px"}}>
                <form className="form" onSubmit={handleAccountSubmit}>
                    <p className="form-title">Fill Form</p>
                    <div className="input-container">
                        <input type="text"
                            id="Eid"
                            name="Eid"
                            value={accountData.Eid}
                            onChange={handleAccountChange}
                            placeholder="Enter Eid" required />
                    </div>
                    <div className="input-container">
                        <input type="date"
                            id="date"
                            name="date"
                            value={accountData.date}
                            onChange={handleAccountChange} required />
                    </div>
                    <div className="input-container">
                        <input type="text"
                            id="reason"
                            name="reason"
                            value={accountData.reason}
                            onChange={handleAccountChange}
                            placeholder="Enter reason" required />
                    </div>
                    <div className="input-container">
                        <input type="text"
                            id="amount"
                            name="amount"
                            value={accountData.amount}
                            onChange={handleAccountChange}
                            placeholder="Enter amount" required />
                    </div>

                    <div className="input-container" style={{ backgroundColor: "#fff", padding: "1rem", paddingRight: "3rem", fontSize: "0.875rem", lineHeight: "1.25rem", borderRadius: "0.5rem", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)", border: "1px solid #e5e7eb" }}>
                        <label htmlFor="cashFlow">Cash Flow:</label>
                        <select
                            id="cashFlow"
                            name="cashFlow"
                            value={accountData.cashFlow}
                            onChange={handleAccountChange}
                        >
                            <option value="Inflow">Inflow</option>
                            <option value="Outflow">Outflow</option>
                        </select>
                    </div>

                    <div className="input-container">
                        <input type="text"
                            id="personName"
                            name="personName"
                            value={accountData.personName}
                            onChange={handleAccountChange}
                            placeholder="Enter person name" required />
                    </div>

                    <div className="input-container">
                        <textarea
                            id="description"
                            name="description"
                            value={accountData.description}
                            onChange={handleAccountChange}
                            placeholder="Enter description"
                            style={{ backgroundColor: "#fff", padding: "1rem", paddingRight: "3rem", fontSize: "0.875rem", lineHeight: "1.25rem", borderRadius: "0.5rem", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)", border: "1px solid #e5e7eb" }}
                        ></textarea>
                    </div>
                    <button className="submit" type="submit">
                        Submit
                    </button>
                </form>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Admin;
