import React, { useEffect} from "react";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { BsChevronDoubleRight } from "react-icons/bs";
import { MdAddIcCall } from "react-icons/md"
import AOS from "aos";
import "aos/dist/aos.css";
import { TbArrowElbowRight } from "react-icons/tb";


const CoursePage = ({ appsList }) => {
  
    const { appId } = useParams();

    useEffect(() => {
      AOS.init();
     }, []);

    const appDetails = appsList.find(app => app.appId === parseInt(appId));
        
    if (!appDetails) {
        return <div>App not found</div>;
    }

  return (
    <div>
         <section
        className="page-title"
        style={{
          backgroundImage: `url('images/background/8.jpg')`,
        }}
      >
        <div className="auto-container">
          <div className="clearfix">
            <div className="pull-left">
              <h1>Career</h1>
            </div>
            <div className="pull-right">
              <ul className="page-breadcrumb">
                <li>
                  <Link to="/">Home</Link>   <BsChevronDoubleRight />
                </li>
                <li style={{ marginLeft: "-23px" }}>{appDetails.appName}</li>
              </ul>
            </div>
          </div>
          <div class="contact-number text-center"><span class="icon "><MdAddIcCall /></span>Call Us: +919263636301 /+918797046868</div>
        </div>
           </section>

        <section class="project-single-section">
    	<div class="auto-container">
        	<div class="inner-box">
            	<div class="project-gallery">
                	<div class="row clearfix">
                    	<div class="image-column col-md-4 col-sm-4 col-xs-12">
                    		<div class="image">
                            	<a href="images/gallery/14.jpg" data-fancybox="images" data-caption=""><img src="images\tt.jpg" alt=""/></a>
                            </div>
                            <div class="image">
                            	<a href="images/gallery/15.jpg" data-fancybox="images" data-caption=""><img src="./images/dd.jpg" alt=""/></a>
                            </div>
                        </div>
                        <div class="image-column col-md-8 col-sm-8 col-xs-12">
                        	<div class="image">
                            	<a href="images/gallery/16.jpg" data-fancybox="images" data-caption=""><img src="" alt=""/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                	
                	<div class="info-column col-md-4 col-sm-12 col-xs-12">
                    
                    	<div class="sec-title">
                        	<h2><span class="theme_color">Course</span> Information</h2>
                        </div>
                        
                        <div class="text" data-aos="fade-right">{appDetails.CourseInformation}</div>
                        <ul class="info-list">
                        	<li data-aos="fade-up"><span>Course:</span>{appDetails.CourseInformationType}</li>
                            <li data-aos="fade-up"><span>Location:</span><Link to="/contact">Choice Location</Link></li>
                            <li data-aos="fade-up"><span>Class Room:</span>Ac class Rooms</li>
                            <li data-aos="fade-up"><span>Duration:</span>1-2 month</li>
                        </ul>
                                                
                        <div class="solution-box-two" style={{backgroundImage:`url(./images/mw2.jpg)`}}>
                        	<div class="inner">
                            	<div class="title">Quick Contact</div>
                            	<h2>Get Solution</h2>
                                <div class="text" style={{textAlign:"justify"}}>Contact us at the Constration nearest to you inquiry online.</div>
                                <Link class="solution-btn theme-btn" to="/contact">Contact</Link>
                                
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="description-column col-md-8 col-sm-12 col-xs-12">
                    	<div class="sec-title">
                        	<h2><span class="theme_color">Course </span> Description</h2>
                        </div>
                        <div class="text">
                        	<p data-aos="fade-left">{appDetails.CourseDescripation}</p>
                            <h3>Course Learn</h3>
                            <p>{appDetails. CourseLearn[0]}</p>
                            <div class="row clearfix">
                            	<div class="column col-md-6 col-sm-6 col-xs-12">
                                	<ul class="list-style-five">
                                    	<li data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom"><TbArrowElbowRight style={{fontSize:"20px",marginRight:"5px",color:"#dfb014"}}/>{appDetails. CourseLearn[1]}</li>
                                        <li data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom"><TbArrowElbowRight style={{fontSize:"20px",marginRight:"5px",color:"#dfb014"}}/>{appDetails. CourseLearn[2]}</li>
                                        <li data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom"><TbArrowElbowRight style={{fontSize:"20px",marginRight:"5px",color:"#dfb014"}}/>{appDetails. CourseLearn[3]}</li>
                                    </ul>
                                </div>
                                <div class="column col-md-6 col-sm-6 col-xs-12">
                                	<ul class="list-style-five">
                                    	<li data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom"><TbArrowElbowRight style={{fontSize:"20px",marginRight:"5px",color:"#dfb014"}}/>{appDetails. CourseLearn[4]}</li>
                                        <li data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom"><TbArrowElbowRight style={{fontSize:"20px",marginRight:"5px",color:"#dfb014"}}/>{appDetails. CourseLearn[5]}</li>
                                    </ul>
                                </div>
                            </div>
                            {/* <h3>What We Did</h3>
                            <p>Then along come two they got nothin’ but their jeans. Texas tea. Knight Rider: A shadowy flight into the dangerous world of a man who does not exist. The first mate and his Skipper too will do their very best to make the others comfortable in their tropic island nest.</p> */}
                            <h3>RESULT</h3>
                            <div class="text" style={{textAlign:"justify"}} data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
                            CAD software offers a broad range of functionality, and you may consider specializing in specific areas based on your interests and career goals. You can undergo advanced training and certifications in areas such as 3D modeling, simulation, product design, architectural design, or mechanical engineering. This specialization can enhance your expertise and career prospects in your chosen field.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
          </section>

    </div>
  )
}

export default CoursePage
