import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ServiceSlider.css'
import { MdOutlineDesignServices } from "react-icons/md";
import { MdManageHistory } from "react-icons/md";
import { SiConstruct3 } from "react-icons/si";
import { FaBuildingColumns } from "react-icons/fa6";
import { SiAntdesign } from "react-icons/si";
import { MdEmojiTransportation } from "react-icons/md";
import { IoMdArrowDroprightCircle } from "react-icons/io";

const ServiceSlider = () => {
  const settings = {

    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 4000,
        },
      },
    ],
  };
  return (
    <div className='serviceslider_container'>
      <div className="wrapper responsive_slider">
        <div class="sec-title centered">

          <h2>
            <span class="theme_color">Our</span><span style={{ color: "white" }}> Services</span>
          </h2>
        </div>
        <Slider {...settings}>
          <div class="card_dd">
            <div class="icon">
              <MdOutlineDesignServices style={{ fontSize: "20px", color: "#F77522" }} />
            </div>
            <strong> Design Build
            </strong>
            <img src='images/d1.jpeg' style={{ width: "250px", height: "100px" }} />
            <div class="card__body">
              Our team of experienced designers and architects work hand in hand with our clients
            </div>
            <a href='/design'><span>Read more..</span></a>

          </div>
 
          <div class="card_dd">
            <div class="icon">
              <MdManageHistory style={{ fontSize: "20px", color: "#F77522" }} />
            </div>
            <strong> Management Work
            </strong>
            <img src='images/mw1.jpg' style={{ height: "100px", marginLeft: "30px" }} />
            <div class="card__body">
              we strive to deliver every project on time, within budget, and to the highest quality standards.

            </div>
            <a href='/Management'><span>Read more..</span></a>
          </div>

          <div class="card_dd">
            <div class="icon">
              <SiConstruct3 style={{ fontSize: "20px", color: "#F77522" }} />
            </div>
            <strong> Construction Planning
            </strong>
            <img src='images/images.jpeg' style={{ height: "100px", marginLeft: "30px" }} />
            <div class="card__body">
              Our construction planning services are tailored to the specific needs and requirements of our clients.
            </div>
            <a href='/construction'><span>Read more..</span></a>
          </div>

          <div class="card_dd" style={{ height: "280px" }} >
            <div class="icon">
              <FaBuildingColumns style={{ fontSize: "20px", color: "#F77522" }} />
            </div>
            <strong> Land Survey
            </strong>
            <img src='images/gallery/wg5.jpeg' style={{ height: "100px", marginLeft: "60px" }} />
            <div class="card__body">
              We use state-of-the-art technology and techniques to accurately and efficiently survey the land.
            </div>
            <a href='/land'><span>Read more..</span></a>
          </div>

          <div class="card_dd">
            <div class="icon">
              <SiAntdesign style={{ fontSize: "20px", color: "#F77522" }} />
            </div>
            <strong> Interior Design
            </strong>
            <img src='images/i1.jpeg' style={{ height: "100px", marginLeft: "30px" }} />
            <div class="card__body">
              Our interior design services are tailored to help our clients create functional and aesthetically.

            </div>
            <a href='/interior'><span>Read more..</span></a>
          </div>

          <div class="card_dd">
            <div class="icon">
              <SiAntdesign style={{ fontSize: "20px", color: "#F77522" }} />
            </div>
            <strong> Cad Training
            </strong>
            <img src='images/anothercadtraning.png' style={{ height: "100px", marginLeft: "55px" }} />
            <div class="card__body">
                CAD Desk offers a wide range of courses tailored for professionals and students in the fields of engineering.
            </div>
            <a href='/cadtraining'><span>Read more..</span></a>
          </div>

          {/* Add more slide components here */}
        </Slider>
      </div>
    </div>
  )
}

export default ServiceSlider
