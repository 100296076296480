import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MdOutlineDesignServices } from "react-icons/md";
import { MdManageHistory } from "react-icons/md";
import { SiConstruct3 } from "react-icons/si";
import { FaBuildingColumns } from "react-icons/fa6";
import { SiAntdesign } from "react-icons/si";
import { MdEmojiTransportation } from "react-icons/md";
import { IoMdArrowDroprightCircle } from "react-icons/io";
import { Link } from 'react-router-dom';

const GallerySlider = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          rtl: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          infinite: true,
          rtl: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
          rtl: true,
        },
      },
    ],
  };
  return (
    <div>
      <div className="wrapper responsive_slider">
        <Slider {...settings}>
          <figure class="snip1200" style={{height:"300px"}}>
            <img
              src="./images/gallery/wg5.jpeg"
              alt="sq-sample27"
              className="certificate_image"
              style={{height:'300px'}}
            />
          </figure>

          <figure class="snip1200" style={{height:"300px"}}>
            <img
              src="./images/gallery/g47.jpeg"
              alt="sq-sample27"
              className="certificate_image"
              style={{height:"300px"}}
            />
           
           
          </figure>

          <figure class="snip1200" style={{height:"300px"}} >
            <img
              src="./images/gallery/g48.jpeg"
              alt="sq-sample27"
              className="certificate_image"
              style={{height:"300px"}}
            />
          
          </figure>

          <figure class="snip1200" style={{height:"300px"}} >
            <img
              src="./images/gallery/g49.jpeg"
              alt="sq-sample27"
              className="certificate_image"
              style={{height:"300px"}}
            />
           
          </figure>

         
        </Slider>
      </div>
    </div>
  )
}

export default GallerySlider
