import React from 'react'
import { Link } from 'react-router-dom'
import { FaFacebookSquare } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { MdArrowRight } from "react-icons/md";
import { FaYoutube } from "react-icons/fa";
import { FaBookReader } from "react-icons/fa";


const Footer = () => {
    return (
        <div>
            <footer class="main-footer alternate" style={{ backgroundImage: `url('images/background/5.jpg')` }}>
                <div class="auto-container">

                    {/* <!--Widgets Section--> */}
                    <div class="widgets-section">
                        <div class="row clearfix">

                            {/* <!--big column--> */}
                            <div class="big-column col-lg-7 col-md-6 col-sm-12 col-xs-12">
                                <div class="row clearfix">

                                    {/* <!--Footer Column--> */}
                                    <div class="footer-column col-md-7 col-sm-6 col-xs-12">
                                        <div class="footer-widget logo-widget">
                                            <div class="logo">
                                                <Link to="/"><img style={{marginLeft:"-4px"}} src="images/logo10.png" alt="" /></Link>
                                            </div>
                                            <div class="text">We are engaged in Design & Detailed Engineering works for Highway Projects, Building Projects, Geo-technical Investigation, Land Surveying, Estimating & Costing, and Scheduling etc. We also prepare NOC cases for obtaining Access Permissions from Mort&H, NHAI, PWD etc.</div>
                                            <ul class="social-icon-one">
                                                <li><a href="https://www.facebook.com/dtech.cad/" target='_blank'><FaFacebookSquare class="fa fa-facebook-f" /></a></li>
                                                <li><a href="https://www.instagram.com/dtech.cad/" target='_blank'><RiInstagramFill class="fa fa-twitter" /></a></li>
                                                {/* <li><a href="#"><FaYoutube class="fa fa-twitter" /></a></li> */}
                                            </ul>
                                        </div>
                                    </div>

                                    {/* <!--Footer Column--> */}
                                    <div class="footer-column col-md-5 col-sm-6 col-xs-12">
                                        <div class="footer-widget links-widget">
                                            <div class="footer-title">
                                                <h2>Services</h2>
                                            </div>
                                            <ul class="footer-lists-two">
                                            <li><FaBookReader style={{color:"#F77522"}} />  <a href="/cadtraining">INTERNSHIP AND CAD TRAINING</a></li>
                                                <li><MdArrowRight /><a href="/design">Design and Build</a></li>
                                                <li><MdArrowRight /><a href="/management"> Management Work</a></li>
                                                <li><MdArrowRight /><a href="/construction">Construction Planning</a></li>
                                                <li><MdArrowRight /><a href="/land">Land Survey</a></li>
                                                <li><MdArrowRight /><a href="/interior">Interior Design</a></li>
                                                <li><MdArrowRight /><a href="/soil">Soil Testing</a></li>
                                               
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* <!--big column--> */}
                            <div class="big-column col-lg-5 col-md-6 col-sm-12 col-xs-12">
                                <div class="row clearfix">

                                    {/* <!--Footer Column--> */}
                                    <div class="footer-column col-md-5 col-sm-6 col-xs-12">
                                        <div class="footer-widget links-widget">
                                            <div class="footer-title">
                                                <h2>Extra Links</h2>
                                            </div>
                                            <ul class="footer-lists-two">
                                                <li><MdArrowRight /><Link to="/">Home</Link></li>
                                                <li><MdArrowRight /><Link to="/about">About Us</Link></li>
                                                <li><MdArrowRight /><Link tp="/services">Services</Link></li>
                                                <li><MdArrowRight /><Link to="/Gallery">Gallery</Link></li>
                                                <li><MdArrowRight /><Link to="/contact">Contact Us</Link></li>
                                                <li><MdArrowRight /><Link to="/Career">Career</Link></li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* <!--Footer Column--> */}
                                    <div class="footer-column col-md-7 col-sm-6 col-xs-12">
                                        <div class="footer-widget gallery-widget">
                                            <div class="footer-title">
                                                <h2>Google Map</h2>
                                            </div>
                                            <div class="widget-content">
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14601.136505133118!2d86.40928455229451!3d23.808493584503807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f6bcae96182cf1%3A0xe3b0673c0dbab0f0!2sJay%20Prakash%20Nagar%2C%20Jharudih%2C%20Dhanbad%2C%20Jharkhand%20826001!5e0!3m2!1sen!2sin!4v1715328643734!5m2!1sen!2sin" width="340" height="200" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {/* <!--Footer Bottom--> */}
                <div class="footer-bottom">
                    <div class="auto-container">
                        <div class="row clearfix">

                            <div class="column col-md-6 col-sm-12 col-xs-12">
                                <div class="copyright"> © 2024  DTECH.  All Rights Reserved.</div>
                            </div>
                            <div class="column col-md-6 col-sm-12 col-xs-12">
                                <ul class="footer-nav">
                                    <li><a href="https://www.excerptech.com/" target='_black'>Designed By Excerpt Technologies Pvt Ltd</a></li>
                                    {/* <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Contactus</a></li> */}
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
