import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../CarrerPage/CarrerPage.css";
import { FaArrowAltCircleRight } from "react-icons/fa";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import { BsChevronDoubleRight } from "react-icons/bs";
import { MdAddIcCall } from "react-icons/md";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdDelete } from "react-icons/md";
import styles from './CareerBox.module.css';
import { FaWhatsapp } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";

const CareerPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);


  const [jobApplications, setJobApplications] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchJobApplications = async () => {
      try {
        const response = await fetch('/api/jobapplications');
        const data = await response.json();
        setJobApplications(data);
      } catch (error) {
        console.error('Error fetching job applications:', error);
      }
    };

    fetchJobApplications();
  }, []);

  const scrollToApplyForm = () => {
    const formSection = document.getElementById('applyForm');
    if (formSection) {
      formSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    mobileNumber: '',
    message: '',
    resume: null,
    department: '',
    currentSalary: '',
    expectedSalary: '',
    experience: ''
  });


  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when form submission starts
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('resume', formData.resume);
    formDataToSend.append('subject', formData.subject);
    formDataToSend.append('mobileNumber', formData.mobileNumber);
    formDataToSend.append('department', formData.department);
    formDataToSend.append('currentSalary', formData.currentSalary);
    formDataToSend.append('expectedSalary', formData.expectedSalary);
    formDataToSend.append('experience', formData.experience);


    try {
      const response = await fetch('/api/submitApplication', {
        method: 'POST',
        body: formDataToSend
      });
      if (response.ok) {
        toast.success('Application submitted..');
        setFormData({
          name: '',
          email: '',
          subject: '',
          mobileNumber: '',
          message: '',
          resume: null,
          department: '',
          currentSalary: '',
          expectedSalary: '',
          experience: ''
        });
        setTimeout(() => {
          navigate('/thankyou');
        }, 3000); // Wait for 3 seconds before navigating to the Thank You page
      } else {
        toast.error('Failed to submit application');
      }
    } catch (error) {
      console.error('Error submitting application:', error);
      toast.error('An error occurred while submitting the application');
    } finally {
      setLoading(false); // Reset loading state after form submission completes
    }
  };

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };
  const [activeTab, setActiveTab] = useState("#prod-details");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleDelete = async (jobName) => {
    try {
      const response = await fetch(`/api/jobapplications/${encodeURIComponent(jobName)}`, {
        method: 'DELETE'
      });

      if (response.ok) {
        setJobApplications(jobApplications.filter(application => application.jobName !== jobName));
        toast.success('Job application deleted successfully.');
      } else {
        toast.error('Failed to delete job application.');
      }
    } catch (error) {
      console.error('Error deleting job application:', error);
      toast.error('An error occurred while deleting the job application.');
    }
  };


  return (
    <div>
      <section
        className="page-title"
        style={{
          backgroundImage: `url('images/background/8.jpg')`,
        }}
      >
        <div className="auto-container">
          <div className="clearfix">
            <div className="pull-left">
              <h1>Career</h1>
            </div>
            <div className="pull-right">
              <ul className="page-breadcrumb">
                <li>
                  <Link to="/">Home</Link>   <BsChevronDoubleRight />
                </li>
                <li style={{ marginLeft: "-23px" }}>Career</li>
              </ul>
            </div>
          </div>
          <div class="contact-number text-center"><span class="icon "><MdAddIcCall /></span>Call Us: +919263636301 /+918797046868</div>
        </div>
      </section>





      <div class="sec-title centered">
        <h2>
          <span class="theme_color">Job</span> Openings
        </h2>
      </div>

      <section className="card-container row">
        {jobApplications.length > 0 ? (
          jobApplications.map((application) => (
            <div className="card_career" key={application.id}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 className="card__title">{application.jobName}</h3>
                {/* <MdDelete
                  style={{ color: "#F77522", cursor: "pointer", fontSize: "30px" }}
                  onClick={() => handleDelete(application.jobName)}
                /> */}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4>Description</h4>
              </div>
              <p className="card__content" style={{ textAlign: "justify" }}>
                {application.jobDescription}
              </p>
              <div style={{ justifyContent: "space-around" }}>
                <ul>
                  <li><FaArrowAltCircleRight style={{ color: "#F77522", marginRight: "4px", marginBottom: "-3px" }} />{application.requiredQualifications}</li>
                  <li><FaArrowAltCircleRight style={{ color: "#F77522", marginRight: "4px", marginBottom: "-3px" }} />Full time</li>
                </ul>
                <ul>
                  <li><FaArrowAltCircleRight style={{ color: "#F77522", marginRight: "4px", marginBottom: "-3px" }} />20k-30k</li>
                  <li><FaArrowAltCircleRight style={{ color: "#F77522", marginRight: "4px", marginBottom: "-3px" }} />Good Skills</li>
                </ul>
              </div>
              <div className="card__date">April 15, 2022</div>
              <div className="card__arrow" style={{ color: "white", fontSize: "16px", fontWeight: "bold", cursor: "pointer" }} onClick={openPopup}>
                Apply <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="15" width="15">
                  <path fill="#fff" d="M13.4697 17.9697C13.1768 18.2626 13.1768 18.7374 13.4697 19.0303C13.7626 19.3232 14.2374 19.3232 14.5303 19.0303L20.3232 13.2374C21.0066 12.554 21.0066 11.446 20.3232 10.7626L14.5303 4.96967C14.2374 4.67678 13.7626 4.67678 13.4697 4.96967C13.1768 5.26256 13.1768 5.73744 13.4697 6.03033L18.6893 11.25H4C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75H18.6893L13.4697 17.9697Z"></path>
                </svg>
              </div>
            </div>
          ))
        ) : (
          <p>No job applications available.</p>
        )}
      </section>




      <Popup open={isOpen} closeOnDocumentClick onClose={closePopup} modal nested>
        <div className="modal-content">
          <button className="close" onClick={closePopup}>&times;</button>

          <form method="post" id="contact-form" className="fromresposive" noValidate="novalidate" onSubmit={handleSubmit}>
            <div className="sec-title centered" data-aos="fade-left">
              <div className="title">Looking for an opportunity</div>
              <h2><span className="theme_color">Apply</span>Job</h2>
            </div>
            <div className="row clearfix">
              <div className="form-group col-md-12">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  data-validation-required-message="Please enter your name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-12">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  required
                  data-validation-required-message="Please enter your email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-12">
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  required
                  data-validation-required-message="Please enter a subject"
                  value={formData.subject}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-12">
                <input
                  type="text"
                  name="mobileNumber"
                  id="mobileNumber"
                  placeholder="Phone"
                  required
                  data-validation-required-message="Please enter your 10-digit mobile number"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group col-md-12">
                <select
                  name="department"
                  id="department"
                  required
                  value={formData.department}
                  onChange={handleChange}
                >
                  <option value="" disabled>Select Department</option>
                  <option value="mechanical">Mechanical</option>
                  <option value="civil">Civil</option>
                </select>
              </div>
              <div className="form-group col-md-12">
                <input
                  type="text"
                  name="currentSalary"
                  id="currentSalary"
                  placeholder="Current Salary"
                  required
                  data-validation-required-message="Please enter your current salary"
                  value={formData.currentSalary}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-12">
                <input
                  type="text"
                  name="expectedSalary"
                  id="expectedSalary"
                  placeholder="Expected Salary"
                  required
                  data-validation-required-message="Please enter your expected salary"
                  value={formData.expectedSalary}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-12">
                <select
                  name="experience"
                  id="experience"
                  required
                  value={formData.experience}
                  onChange={handleChange}
                >
                  <option value="" disabled>Select Experience</option>
                  <option value="fresher">Fresher</option>
                  <option value="experienced">Experienced</option>
                </select>
              </div>
              <div className="form-group col-md-12">
                <input type="file" id="resume" name="resume" onChange={handleChange} accept=".pdf" required />
              </div>
              <div className="form-group col-md-12">
                <button className="button1" type="submit" disabled={loading}>
                  {loading ? 'Sending...' : 'Submit Application'}
                </button>
              </div>
            </div>
            <p className="form-message"></p>
          </form>

        </div>
      </Popup>




      {/* <section>
      <div class="sec-title centered">
        <h2>
          <span class="theme_color">Our </span>Placement
        </h2>
      </div>
        <div class="container">
          <main class="grid">
            <article>
              <aside class="sidebar default-sidebar">

                <div class="solution-box" style={{ backgroundimage: `url(images/resource/image-2.jpg)` }}>
                  <div class="inner">
                    <div class="title">Get your dream Jobs</div>
                    <h2>Your carrers</h2>
                    <div class="text">Contact us at the Constration office nearest to you or submit a business inquiry online.</div>
                    <a class="solution-btn theme-btn" href="#" onClick={openPopup}>Apply</a>
                  </div>
                </div>

              </aside>
            </article>
            <article>
              <img src="https://picsum.photos/600/400?image=1063" alt="Sample photo" />
              <div class="text">
                <h3>Completely Synergize</h3>
                <p>Dramatically engage seamlessly visualize quality intellectual capital without superior collaboration and idea-sharing.</p>
                <a href="" class="btn btn-primary btn-block">Here's how</a>
              </div>
            </article>
            <article>
              <img src="https://picsum.photos/600/400?image=1056" alt="Sample photo" />
              <div class="text">
                <h3>Dynamically Procrastinate</h3>
                <p>Completely synergize resource taxing relationships via premier niche markets.</p>
                <a href="" class="btn btn-primary btn-block">Read more</a>
              </div>
            </article>
            <article>
              <img src="https://picsum.photos/600/400?image=1050" alt="Sample photo" />
              <div class="text">
                <h3>Best in class</h3>
                <p>Imagine jumping into that boat, and just letting it sail wherever the wind takes you...</p>
                <a href="" class="btn btn-primary btn-block">Just do it...</a>
              </div>
            </article>
            <article>
              <img src="https://picsum.photos/600/400?image=1041" alt="Sample photo" />
              <div class="text">
                <h3>Dynamically innovate supply chains</h3>
                <p>Holisticly predominate extensible testing procedures for reliable supply chains.</p>
                <a href="" class="btn btn-primary btn-block">Here's why</a>
              </div>
            </article>
            <article>
              <img src="https://picsum.photos/600/400?image=1015" alt="Sample photo" />
              <div class="text">
                <h3>Sanity check</h3>
                <p>Objectively innovate empowered manufactured products whereas parallel platforms.</p>
                <a href="" class="btn btn-primary btn-block">Stop here</a>
              </div>
            </article>
          </main>
        </div>
      </section> */}
      <section>
      <div class="sec-title centered">
        <h2>
          <span class="theme_color">Our </span>Placement
        </h2>
      </div>
   

      <div class="custom-grid-container">
        <main class="grid">
          <article>
            <aside class="sidebar default-sidebar">

              <div class="solution-box" style={{ backgroundimage: `url(images/resource/image-2.jpg)` }}>
                <div class="inner">
                  <div class="title">Get your dream Jobs</div>
                  <h2>Your carrers</h2>
                  <div class="text">Contact us at the nearest office to you or submit a business inquiry online.</div>
                  <a class="solution-btn theme-btn" href="#" onClick={openPopup}>Apply</a>
                </div>
              </div>

            </aside>
          </article>
          <div style={{display:"flex",flexDirection:"column"}} className="resposnive_apply_job">
            <h1>Apply your Dream Job</h1>
            <p>Unlock your career potential with our robust placement programs! Our dedicated placement cell partners with top companies to provide you with exclusive job opportunities and personalized career guidance. Join us and embark on a path to professional success with guaranteed support and placement assistance.
            </p>
          </div>
          
          {/* <article>
            <img src="images/imagei.jpeg" alt="Sample photo" />
            <div class="text">
              <h3>Completely Synergize</h3>
              <p>Dramatically engage seamlessly visualize quality intellectual capital without superior collaboration and idea-sharing.</p>
              <a href="" class="btn btn-primary btn-block">Here's how</a>
            </div>
          </article>
          <article>
            <img src="images/imagei.jpeg" alt="Sample photo" />
            <div class="text">
              <h3>Dynamically Procrastinate</h3>
              <p>Completely synergize resource taxing relationships via premier niche markets.</p>
              <a href="" class="btn btn-primary btn-block">Read more</a>
            </div>
          </article>
          <article>
            <img src="images/imagei.jpeg" alt="Sample photo" />
            <div class="text">
              <h3>Best in class</h3>
              <p>Imagine jumping into that boat, and just letting it sail wherever the wind takes you...</p>
              <a href="" class="btn btn-primary btn-block">Just do it...</a>
            </div>
          </article>
          <article>
            <img src="images/imagei.jpeg" alt="Sample photo" />
            <div class="text">
              <h3>Dynamically innovate supply chains</h3>
              <p>Holisticly predominate extensible testing procedures for reliable supply chains.</p>
              <a href="" class="btn btn-primary btn-block">Here's why</a>
            </div>
          </article>
          <article>
            <img src="images/imagei.jpeg" alt="Sample photo" />
            <div class="text">
              <h3>Sanity check</h3>
              <p>Objectively innovate empowered manufactured products whereas parallel platforms.</p>
              <a href="" class="btn btn-primary btn-block">Stop here</a>
            </div>
          </article> */}
        </main>
      </div>
      </section>

      <section class="call-to-action-section-two" >
        <div class="auto-container">
          <div class="row clearfix">

            <div class="column col-md-7 col-sm-12 col-xs-12">
              <h2 className="join"><span style={{color:"white"}} class="theme_color">Enroll today</span>  to enhance your abilities with our internship and CAD training programs!</h2>
              <div class="text" style={{color:"black"}}>For more information, please contact us at available number.</div>
            </div>
            <div class="btn-column col-md-5 col-sm-12 col-xs-12" >
              <div class="number"><FaWhatsapp style={{color:"green",fontWeight:"600"}}/> +91 9263636301   </div>
              <div class="number"><FiPhone style={{fontWeight:"600",color:"white"}}/> +91 8797046868  </div>
            </div>

          </div>
        </div>
      </section>



      <ToastContainer />
    </div>
  );
};

export default CareerPage;
