import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HomeGallery.css';
import { Link } from 'react-router-dom';

const HomeGallery = () => {
  const settings = {
    infinite: true,
    speed: 2500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          rtl: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          infinite: true,
          rtl: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
          rtl: true,
        },
      },
    ],
  };
  return (
    <div>
      <div className="wrapper responsive_slider">
        <div className="sec-title centered">
          <h2>
            <span className="theme_color">Our</span> Events
          </h2>
        </div>
        <Slider {...settings}>
          <figure className="snip1200" style={{ height: "300px" }}>
            <img
              src="./images/gallery/tg2.jpeg"
              alt="sq-sample27"
              className="certificate_image"
              style={{ height: '300px' }}
            />
            <figcaption>
              {/* <p>
                great mentor have D tech company They were knowledge shared with student
              </p> */}
              <div className="heading">
                <h2>
                  <span style={{color:"white"}}>Teaching</span>
                </h2>
              </div>
            </figcaption>
            <Link to="/Gallery"></Link>
          </figure>

          <figure className="snip1200" style={{ height: "300px" }}>
            <img
              src="./images/gallery/tg3.jpeg"
              alt="sq-sample27"
              className="certificate_image"
              style={{ height: "300px" }}
            />
            <figcaption>
              {/* <p>
                they were detail explined about design software
              </p> */}
              <div className="heading">
                <h2>
                  <span style={{color:"white"}}>Drawing</span>
                </h2>
              </div>
            </figcaption>
            <Link to="/Gallery"></Link>
          </figure>

          <figure className="snip1200" style={{ height: "300px" }} >
            <img
              src="./images/gallery/tg4.jpeg"
              alt="sq-sample27"
              className="certificate_image"
              style={{ height: "300px" }}
            />
            <figcaption>
              {/* <p>
                motivational speech
              </p> */}
              <div className="heading">
                <h2>
                  <span style={{color:"white"}}>Events</span>
                </h2>
              </div>
            </figcaption>
            <Link to="/Gallery"></Link>
          </figure>

          <figure className="snip1200" style={{ height: "300px" }} >
            <img
              src="./images/gallery/tg5.jpeg"
              alt="sq-sample27"
              className="certificate_image"
              style={{ height: "300px" }}
            />
            <figcaption>
              {/* <p>
                Realtime Experience
              </p> */}
              <div className="heading">
                <h2>
                  <span style={{color:"white"}}>Classrooms</span>
                </h2>
              </div>
            </figcaption>
            <Link to="/Gallery"></Link>
          </figure>

          <figure className="snip1200" style={{ height: "300px" }} >
            <img
              src="./images/gallery/tg6.jpeg"
              alt="sq-sample27"
              className="certificate_image"
              style={{ height: "300px" }}
            />
            <figcaption>
              {/* <p>
                Clients satisfaction
              </p> */}
              <div className="heading">
                <h2>
                  <span style={{color:"white"}}>Project</span>
                </h2>
              </div>
            </figcaption>
            <Link to="/Gallery"></Link>
          </figure>
        </Slider>
      </div>
    </div>
  )
}

export default HomeGallery;
