import React from 'react';
import './Marque.css';

const Marquee = () => {
    return (
        <>
            <div class="sec-title centered">

                <h2>
                    <span class="theme_color">Our</span><span > Placement</span>
                </h2>
            </div>
            <div className='div'>

                <div class="content-column col-md-6 col-sm-12 col-xs-12 " style={{marginTop:"-60px"}}>
                    <div class="inner-column resposnive" style={{ width: "80%"}}>
                        <div class="sec-title" data-aos="fade-down">
                            <div class="title"></div>
                            
                        </div>
                        <div class="text1">
                            <h2 data-aos="fade-right"></h2>
                            <p style={{ textAlign: "justify" }} data-aos="fade-right">
                            We excel in delivering premier engineering services through our expert team, ensuring optimal product utilization and efficient processes. We guarantee exceptional quality by integrating cutting-edge innovations. Our team is relentlessly dedicated to continuous improvement and innovation, prioritizing bespoke solutions to precisely meet and exceed customer expectations.{" "}
                            </p>
                            <br />
                        </div>
                        <a href="/Career" class="theme-btn btn-style-four">
                            Careers
                        </a>
                    </div>
                </div>
                <div className="body__inner-wrapper col-md-6 col-sm-12 col-xs-12"  >

                    <div className="marquee">
                        <div className="marquee__inner-wrap">
                            <div className="marquee__inner resposnive_marquee" >
                                <div class="card2 ">
                                    <div class="left">
                                        <img class="profile_img" src="images/icon.jpg" alt="" />
                                        <div class="flag_wrapper">
                                            <img class="flag" src="images/icon.jpg" alt="South Korean Flag" />
                                        </div>
                                    </div>
                                    <div class="right">
                                        <h2 class="name">Karthik Kumar</h2>
                                        <p class="title">Design Engineering</p>
                                        <p class="location">Jaipur, India</p>
                                        
                                    </div>
                                </div>
                                <div class="card2 ">
                                    <div class="left">
                                        <img class="profile_img" src="images/icon.jpg" alt="" />
                                        <div class="flag_wrapper">
                                            <img class="flag" src="images/icon.jpg" alt="South Korean Flag" />
                                        </div>
                                    </div>
                                    <div class="right">
                                        <h2 class="name">Premgowda Gt</h2>
                                        <p class="title">Interior Design</p>
                                        <p class="location">Vijayavada, India</p>
                                        
                                    </div>
                                </div>
                                <div class="card2 ">
                                    <div class="left">
                                        <img class="profile_img" src="images/icon.jpg" alt="" />
                                        <div class="flag_wrapper">
                                            <img class="flag" src="images/icon.jpg" alt="South Korean Flag" />
                                        </div>
                                    </div>
                                    <div class="right">
                                        <h2 class="name">Prajwal H O</h2>
                                        <p class="title">Architecture Engineer</p>
                                        <p class="location">Dhanbad, India</p>
                                        
                                    </div>
                                </div>
                                <div class="card2 ">
                                    <div class="left">
                                        <img class="profile_img" src="images/icon.jpg" alt="" />
                                        <div class="flag_wrapper">
                                            <img class="flag" src="images/icon.jpg" alt="South Korean Flag" />
                                        </div>
                                    </div>
                                    <div class="right">
                                        <h2 class="name">Kavana</h2>
                                        <p class="title">Quality Engineer</p>
                                        <p class="location">Bokaro, India</p>
                                        
                                    </div>
                                </div>
                                {/* <div class="card2 ">
                                    <div class="left">
                                        <img class="profile_img" src="images/icon.jpg" alt="" />
                                        <div class="flag_wrapper">
                                            <img class="flag" src="images/icon.jpg" alt="South Korean Flag" />
                                        </div>
                                    </div>
                                    <div class="right">
                                        <h2 class="name">Nam Do-San</h2>
                                        <p class="title">Developer</p>
                                        <p class="location">India</p>
                                        
                                    </div>
                                </div> */}
                                {/* <div class="card2 ">
                                    <div class="left">
                                        <img class="profile_img" src="images/icon.jpg" alt="" />
                                        <div class="flag_wrapper">
                                            <img class="flag" src="images/icon.jpg" alt="South Korean Flag" />
                                        </div>
                                    </div>
                                    <div class="right">
                                        <h2 class="name">Nam Do-San</h2>
                                        <p class="title">Developer</p>
                                        <p class="location">India</p>
                                        
                                    </div>
                                </div>
                                <div class="card2 ">
                                    <div class="left">
                                        <img class="profile_img" src="images/icon.jpg" alt="" />
                                        <div class="flag_wrapper">
                                            <img class="flag" src="images/icon.jpg" alt="South Korean Flag" />
                                        </div>
                                    </div>
                                    <div class="right">
                                        <h2 class="name">Nam Do-San</h2>
                                        <p class="title">Developer</p>
                                        <p class="location">India</p>
                                        
                                    </div>
                                </div>
                                <div class="card2 ">
                                    <div class="left">
                                        <img class="profile_img" src="images/icon.jpg" alt="" />
                                        <div class="flag_wrapper">
                                            <img class="flag" src="images/icon.jpg" alt="South Korean Flag" />
                                        </div>
                                    </div>
                                    <div class="right">
                                        <h2 class="name">Nam Do-San</h2>
                                        <p class="title">Developer</p>
                                        <p class="location">India</p>
                                        
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
};

export default Marquee;
