import React, { useEffect, useState } from 'react';
import '../Resumes/Rsum.css';

const Resume = () => {
    const [resumes, setResumes] = useState([]);

    useEffect(() => {
        const fetchResumes = async () => {
            try {
                const response = await fetch('/api/resumes');
                const data = await response.json();
                setResumes(data);
            } catch (error) {
                console.error('Error fetching resumes:', error);
            }
        };

        fetchResumes();
    }, []);

    return (
        <div>
            <div className="resume-view">
                <div class="sec-title centered">
                    <h2>
                        <span class="theme_color">Applied</span> Resumes
                    </h2>
                </div>
                {resumes.length > 0 ? (
                    <table className="resume-table">
                        <thead>
                            <tr>
                                <th><b style={{color:"black"}}>Name</b></th>
                                <th>Email</th>
                                <th>Department</th>
                                <th>Action</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {resumes.map((resume) => (
                                <tr key={resume._id}>
                                    <td>{resume.name}</td>
                                    <td>{resume.email}</td>
                                    <td>{resume.department}</td>
                                    <td>
                                        <a href={`http://localhost:5000/${resume.resumePath}`} target='_blank' download={`${resume.name}.pdf`} className="download-button">
                                            <button className='button1'>Download Resume</button>
                                        </a>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No resumes found.</p>
                )}
            </div>
        </div>
    );
};

export default Resume;
