import React, { useRef, useState, useEffect } from 'react';
import './Cadslider.css'; // Ensure you have the corresponding CSS for styling

const ImageSlider = () => {
  const slideShowRef = useRef(null);
  const slideImgRef = useRef(null);
  const slideWidth = 620; // Width of each slide including margin
  const showNum = 3; // Number of slides to show at once
  const autoSlideInterval = 3000; // Auto slide interval in ms

  const images = [
    "images/Cad/cad2.jpg",
    "images/Cad/cad3.jpg",
    "images/Cad/cad4.jpg",
    "images/Cad/cad5.jpg",
    "images/Cad/cad6.jpg",
    "images/Cad/cad7.jpg",
    "images/Cad/cad9.jpg",
    "images/Cad/neww12.jpeg",
  ];

  const totalImages = [...images, ...images]; // Duplicate images for continuous scrolling
  const [marginLeft, setMarginLeft] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setMarginLeft((prev) => {
        const newMargin = prev - slideWidth;
        return newMargin <= -totalImages.length * slideWidth / 2 ? 0 : newMargin;
      });
    }, autoSlideInterval);
    return () => clearInterval(interval);
  }, []);

  const back = () => {
    setMarginLeft((prev) => {
      const newMargin = prev + slideWidth;
      return newMargin > 0 ? -totalImages.length * slideWidth / 2 + slideWidth : newMargin;
    });
  };

  const next = () => {
    setMarginLeft((prev) => {
      const newMargin = prev - slideWidth;
      return newMargin <= -totalImages.length * slideWidth / 2 ? 0 : newMargin;
    });
  };

  return (
    <div className="slide_wrap">
      <div className="slide_show" ref={slideShowRef}>
        <div className="slide_img" ref={slideImgRef} style={{ marginLeft: `${marginLeft}px` }}>
          {totalImages.map((src, index) => (
            <div className="slide" key={index}>
              <img src={src} alt={`이미지${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
      
    </div>
  );
};

export default ImageSlider;
