import React, { useState } from 'react';
import '../Enquiryslide/Eslide.css'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Slide = () => {
    const [isDemoOptionOpen, setDemoOptionOpen] = useState(false);
    const [status, setStatus] = useState("Submit");
    const [info, setInfo] = useState({ name: '', mobilenumber: '', email: '', service: '', query: '' });
    const handleQuickOptionClick = () => {
        setDemoOptionOpen(!isDemoOptionOpen);
    };



    const handleChange = (event) => {
        setInfo({
            ...info,
            [event.target.name]: event.target.value
        })
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");

        try {
            const response = await fetch("/api/inquiry", {
                method: "POST",
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(info),
            });
            setStatus("Submit");
            setInfo({ name: '', mobilenumber: '', email: '', service: '', query: '' });
            const result = await response.json();
            toast.success(result.message);
        } catch (error) {
            console.error('Error:', error);
            toast.error('Error submitting inquiry. Please try again later.');
        }
    };



    return (
        <>
            <ToastContainer
                position="top-left" // Set position to bottom-left
                toastContainerClassName="toast-container-left" // Add a custom class
            />
            <div className={`demo-option-container ${isDemoOptionOpen ? 'open' : ''}`}>

                <div className="aeroland__toolbar">
                    <div className="inner" style={{ background: "orange", textAlign: "right" }}>


                        <button className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
                            style={{
                                color: "White",
                                transform: `rotate(180deg) ${isDemoOptionOpen ? 'rotate(90deg)' : 'rotate(90deg)'}`,
                                fontSize: "25px", // Adjust the font size as needed
                                border: "none", // Remove the border
                                background: "transparent",
                                fontWeight: "bold",
                                outline: "none", /* Remove default focus outline */
                                border: "none",
                                marginLeft: '-60px',
                                marginTop: "70px"


                            }}
                            onClick={handleQuickOptionClick}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span role="img" aria-label="email" style={{ marginLeft: "-60px" }}>✉</span>
                                <span style={{ marginLeft: '16px' }}>Enquiry</span>
                            </div>
                        </button>
                    </div>

                </div>

                <div className="demo-option-wrapper">
                    <button className="quick-option hint--bounce hint--left hint--black primary-color-hover-important" style={{ width: "20%" }}
                        onClick={handleQuickOptionClick}
                    >
                        <img src="https://caddeskindia.com/wp-content/themes/caddesk_official/assets/images/Close-2-icon.png"
                            style={{ width: "20px", marginLeft: "10px", marginTop: "10px" }}
                            alt="Close"
                        />
                    </button>

                    <div className="business-solution-form-wrap mr-auto ml-auto">
                        <div className="contact-title text-center section-space--mb_40">
                            <b><h3 className="mb-10" style={{ fontSize: '30px', marginTop: "-20px" }}>Contact Us</h3></b>


                        </div>

                        <form className='formi' action="" method="post" id="applyOnlyEnqury" onSubmit={handleSubmit} style={{ boxShadow: "none" }} >

                            <input type="hidden" name="valid" value="false" />
                            <div class="contact-form__two">
                                <br />



                                <div class="contact-inner" style={{ width: "260px" }}>
                                    <input name="name" type="text" placeholder="Name *" required="" id="name" value={info.name} onChange={handleChange} />
                                </div>

                                <div class="contact-inner" style={{ width: "260px" }}>
                                    <input name="email" type="text" placeholder="Email id *" required="" id="name" value={info.email} onChange={handleChange} />
                                </div>


                                {/* <!-- <div class="contact-inner">
<input name="con_email" type="email" placeholder="Email *" required=""/>
</div> --> */}
                                <div class="contact-inner" style={{ width: "260px" }}>
                                    <input name="mobilenumber" type="text" placeholder="Contact No *" required="" id="mobilenumber" value={info.mobilenumber} onChange={handleChange} />
                                </div>




                                <div class="contact-select" style={{ width: "260px" }} >

                                    <span class="inquiry" onSubmit={handleSubmit} >
                                        <select id="category" name="service" required="" value={info.service} onChange={handleChange}>
                                            <option value="">Select Any Service</option>
                                            <option value="Yoga">DTECH</option>
                                            <option value="Consultation">Development Training</option>

                                        </select>
                                    </span>

                                </div>
                                <br />

                                <div className="contact-inner" style={{ height: "50px" }} >
                                    <textarea name="query" placeholder="Write your query..." value={info.query} onChange={handleChange} style={{ width: "260px" }}></textarea>
                                </div>




                                <br />



                                <div class="comment-submit-btn text-center" style={{ color: "#37355e", marginTop: "40px", width: "400px", marginLeft: "-100px" }}>
                                    <button class="ht-btn ht-btn-md" type="submit" name="submitEnqury" id="submitEnqury" >Submit</button>
                                    {/* <!--  <p class="form-messege"></p> --> */}
                                </div>




                                {/* <div class="contact-select">
        <div class="form-item contact-inner">
            <span class="inquiry">
                <select id="course" name="course" required="">
                    <option value="">Select Course</option>
                </select>
            </span>
        </div>
    </div> */}

                                {/* <div class="contact-select">
        <div class="form-item contact-inner">
            <span class="inquiry">
                <select id="states" name="states" required="">
                    <option value="">Select State</option>
                    <option value="2">Andhra Pradesh</option>
                    <option value="4">Assam</option>
                    <option value="5">Bihar</option>
                    <option value="7">Chhattisgarh</option>
                    <option value="10">Delhi (NCR)</option>
                    <option value="12">Gujarat</option>
                    <option value="13">Haryana</option>
                    <option value="14">Himachal Pradesh</option>
                    <option value="15">Jammu and Kashmir</option>
                    <option value="16">Jharkhand</option>
                    <option value="17">Karnataka</option>
                    <option value="18">Kerala</option>
                    <option value="20">Madhya Pradesh</option>
                    <option value="21">Maharashtra</option>
                    <option value="26">Odisha</option>
                    <option value="28">Punjab</option>
                    <option value="29">Rajasthan</option>
                    <option value="31">Tamil Nadu</option>
                    <option value="32">Telangana</option>
                    <option value="33">Tripura</option>
                    <option value="35">Uttar Pradesh</option>
                    <option value="36">West Bengal</option>
                </select>
            </span>
        </div>
    </div> */}

                                {/* <div class="contact-select">
        <div class="form-item contact-inner">
            <span class="inquiry">
                <select class="center" name="center" required="">
                    <option value="">Select Center</option>
                </select>
            </span>
        </div>
    </div> */}

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Slide;

