import React, { useState } from 'react';
import { IoHomeOutline } from "react-icons/io5";
import { GrGroup } from "react-icons/gr";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { SlCalender } from "react-icons/sl";
import { IoCalendarClearOutline } from "react-icons/io5";
import { MdOutlineReviews } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { MdOutlineManageAccounts } from "react-icons/md";
import { TbSettings2 } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import Jobdescription from './Jobdescription/Jobdescription';
import Resume from './Resumes/Resume';
import { Navigate, Link } from 'react-router-dom';
import { BsBank2 } from "react-icons/bs";
import { FaUserGraduate } from "react-icons/fa";
import { BsCalendarEventFill } from "react-icons/bs";
import { ImNewspaper } from "react-icons/im";
const Admincarrers = () => {

   const [currentView, setCurrentView] = useState('jobDescription');
    // Update this based on your authentication logic

    const renderContent = () => {
        switch (currentView) {
            
            case 'jobDescription':
                return <Jobdescription />; // Make sure to import and use your JobDescription component
            case 'resumes':
                return <Resume />; // Make sure to import and use your Resumes component
            default:
                return <Jobdescription />;
        }
    };

    const handleTabClick = (view) => {
        setCurrentView(view);
    };
    return (
        <div>
            <div class="inner-wrapper" style={{ marginTop: "30px" }}>


                <header class="header">

                    <div class="top-header-section">
                        <div class="container-fluid">
                            <div class="row align-items-center">

                                <div class="col-lg-9 col-md-9 col-sm-9 col-6 text-right">
                                    <div class="user-block d-none d-lg-block">
                                        <div class="row align-items-center">

                                        </div>
                                    </div>
                                    <div class="d-block d-lg-none">
                                        <a href="javascript:void(0)">
                                            <span class="lnr lnr-user d-block display-5 text-white" id="open_navSidebar"></span>
                                        </a>

                                        <div class="offcanvas-menu" id="offcanvas_menu">
                                            <span class="lnr lnr-cross float-left display-6 position-absolute t-1 l-1 text-white" id="close_navSidebar"></span>
                                            <div class="user-info align-center bg-theme text-center">
                                                <a href="javascript:void(0)" class="d-block menu-style text-white">
                                                    <div class="user-avatar d-inline-block mr-3">
                                                        <img src="assets/img/profiles/img-6.jpg" alt="user avatar" class="rounded-circle img-fluid" width="55" />
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="user-notification-block align-center">
                                                <div class="top-nav-search">
                                                    <form>
                                                        <input type="text" class="form-control" placeholder="Search here" />
                                                        <button class="btn" type="submit"><i class="fa fa-search"></i></button>
                                                    </form>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="user-menu-items px-3 m-0">
                                                <a class="px-0 pb-2 pt-0" href="/">
                                                    <span class="media align-items-center">
                                                        <span class="lnr lnr-home mr-3"></span>
                                                        <span class="media-body text-truncate text-left">
                                                            <span class="text-truncate text-left">Dashboard</span>
                                                        </span>
                                                    </span>
                                                </a>
                                                <a class="p-2" href="employees.html">
                                                    <span class="media align-items-center">
                                                        <span class="lnr lnr-users mr-3"></span>
                                                        <span class="media-body text-truncate text-left">
                                                            <span class="text-truncate text-left">Employees</span>
                                                        </span>
                                                    </span>
                                                </a>
                                                <a class="p-2" href="company.html">
                                                    <span class="media align-items-center">
                                                        <span class="lnr lnr-apartment mr-3"></span>
                                                        <span class="media-body text-truncate text-left">
                                                            <span class="text-truncate text-left">Company</span>
                                                        </span>
                                                    </span>
                                                </a>
                                                <a class="p-2" href="calendar.html">
                                                    <span class="media align-items-center">
                                                        <span class="lnr lnr-calendar-full mr-3"></span>
                                                        <span class="media-body text-truncate text-left">
                                                            <span class="text-truncate text-left">Calendar</span>
                                                        </span>
                                                    </span>
                                                </a>
                                                <a class="p-2" href="leave.html">
                                                    <span class="media align-items-center">
                                                        <span class="lnr lnr-briefcase mr-3"></span>
                                                        <span class="media-body text-truncate text-left">
                                                            <span class="text-truncate text-left">Leave</span>
                                                        </span>
                                                    </span>
                                                </a>
                                                <a class="p-2" href="reviews.html">
                                                    <span class="media align-items-center">
                                                        <span class="lnr lnr-star mr-3"></span>
                                                        <span class="media-body text-truncate text-left">
                                                            <span class="text-truncate text-left">Reviews</span>
                                                        </span>
                                                    </span>
                                                </a>
                                                <a class="p-2" href="reports.html">
                                                    <span class="media align-items-center">
                                                        <span class="lnr lnr-rocket mr-3"></span>
                                                        <span class="media-body text-truncate text-left">
                                                            <span class="text-truncate text-left">Reports</span>
                                                        </span>
                                                    </span>
                                                </a>
                                                <a class="p-2" href="manage.html">
                                                    <span class="media align-items-center">
                                                        <span class="lnr lnr-sync mr-3"></span>
                                                        <span class="media-body text-truncate text-left">
                                                            <span class="text-truncate text-left">Manage</span>
                                                        </span>
                                                    </span>
                                                </a>
                                                <a class="p-2" href="settings.html">
                                                    <span class="media align-items-center">
                                                        <span class="lnr lnr-cog mr-3"></span>
                                                        <span class="media-body text-truncate text-left">
                                                            <span class="text-truncate text-left">Settings</span>
                                                        </span>
                                                    </span>
                                                </a>
                                                <a class="p-2" href="employment.html">
                                                    <span class="media align-items-center">
                                                        <span class="lnr lnr-user mr-3"></span>
                                                        <span class="media-body text-truncate text-left">
                                                            <span class="text-truncate text-left">Profile</span>
                                                        </span>
                                                    </span>
                                                </a>
                                                <a class="p-2" href="login.html">
                                                    <span class="media align-items-center">
                                                        <span class="lnr lnr-power-switch mr-3"></span>
                                                        <span class="media-body text-truncate text-left">
                                                            <span class="text-truncate text-left">Logout</span>
                                                        </span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </header>

                <div class="page-wrapper" style={{ marginTop: "-70px" }}>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-3 col-lg-4 col-md-12 theiaStickySidebar">
                                <aside class="sidebar sidebar-user">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card ctm-border-radius shadow-sm grow" style={{ width: "290px" }}>
                                                <div class="card-body py-4">
                                                    <div class="row">
                                                        <div class="col-md-12 mr-auto text-left">
                                                            <div class="custom-search input-group">
                                                                <div class="custom-breadcrumb">
                                                                    <ol class="breadcrumb no-bg-color d-inline-block p-0 m-0 mb-2">
                                                                        <li class="breadcrumb-item d-inline-block"><a href="/" class="text-dark">Home</a></li>
                                                                        <li class="breadcrumb-item d-inline-block active">Carrers</li>
                                                                    </ol>
                                                                    <h4 class="text-dark">Job applications</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="user-card card shadow-sm bg-white text-center ctm-border-radius grow" style={{ width: "290px" }}>
                                        <div class="user-info card-body">
                                            <div class="user-avatar mb-4">
                                                <img src="images/408535-200.png" alt="User Avatar" class="img-fluid rounded-circle" width="100" />
                                            </div>
                                            <div class="user-details">
                                                <h2>Welcome Admin!</h2>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="sidebar-wrapper">
                                        <div className="card ctm-border-radius shadow-sm border-none grow navbars" style={{ width: "290px" }}>
                                            <div className="card-body">
                                                <div className="row no-gutters">
                                                    <div className="col-12 col-md-6 align-items-center shadow-none text-center">
                                                        <Link to="/admincarrers" className="text-dark p-4 second-slider-btn ctm-border-right ctm-border-top">
                                                            <span className="lnr font-23"><FaUserGraduate /></span>
                                                            <span className="nav-text">Careers</span>
                                                        </Link>
                                                    </div>
                                                    <div className="col-12 col-md-6 align-items-center shadow-none text-center">
                                                        <Link to="/expenses" className="text-dark p-4 second-slider-btn ctm-border-right ctm-border-top">
                                                            <span className="lnr font-23"><BsBank2 /></span>
                                                            <span className="nav-text">Expenses</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </aside>
                            </div>
                            <div class="col-xl-9 col-lg-8 col-md-12">
                                <div class="quicklink-sidebar-menu ctm-border-radius shadow-sm bg-white card grow" style={{ width: "900px" }}>

                                    <div class="card-body">
                                        <ul class="list-group list-group-horizontal-lg">
                                            <li className={`list-group-item text-center button-5 ${currentView === 'jobDescription' ? 'active' : ''}`}>
                                                <a
                                                    href="#"
                                                    className={currentView === 'jobDescription' ? 'text-white' : 'text-dark'}
                                                    onClick={() => handleTabClick('jobDescription')}
                                                >
                                                    <BsCalendarEventFill /> Job Description
                                                </a>
                                            </li>
                                            <li className={`list-group-item text-center button-6 ${currentView === 'resumes' ? 'active' : ''}`}>
                                                <a
                                                    href="#"
                                                    className={currentView === 'resumes' ? 'text-white' : 'text-dark'}
                                                    onClick={() => handleTabClick('resumes')}
                                                >
                                                   <ImNewspaper /> Resumes
                                                </a>
                                            </li>
                                            {/* Add other navigation links here */}
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-9 col-md-9">
                                    <div className="content-area">
                                        {renderContent()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sidebar-overlay" id="sidebar_overlay"></div>




            </div>
        </div>
    )
}

export default Admincarrers
