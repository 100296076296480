import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaPhone } from "react-icons/fa6";
import { TbBackslash } from "react-icons/tb";
import { GrUserExpert } from "react-icons/gr";
import { FcApproval } from "react-icons/fc";
import { SiTreehouse } from "react-icons/si";
import { FaHandPeace } from "react-icons/fa";
import { BsChevronDoubleRight } from "react-icons/bs";
import { MdAddIcCall } from "react-icons/md";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import "./About.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaWhatsapp } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <section
        class="page-title"
        style={{
          backgroundImage: `url('images/background/8.jpg')`,
        }}
      >
        <div class="auto-container">
          <div class="clearfix">
            <div class="pull-left">
              <h1>About Us</h1>
            </div>
            <div class="pull-right">
              <ul class="page-breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                  <BsChevronDoubleRight />
                </li>

                <li style={{ marginLeft: "-23px" }}>About us</li>
              </ul>
            </div>
          </div>
          <div class="contact-number text-center"><span class="icon "><MdAddIcCall /></span>Call Us: +919263636301 /+918797046868 </div>
        </div>
      </section>

      <section class="featured-section" style={{ marginBottom: "-60px" }}>
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Content Column--> */}
            <div class="content-column col-md-6 col-sm-12 col-xs-12">
              <div class="inner-column">
                <div class="sec-title">
                  <div class="title">Welcome to D Tech</div>
                  <h2>
                    <span class="theme_color">About</span> Us
                  </h2>
                </div>
                <div class="text" data-aos="fade-right">
                  <p style={{ textAlign: "justify", fontSize: "16px" }}>
                    D TECH, Consultant of IOCL/ BPCL/ HPCL/ GAIL GAS,
                    is an Engineers Associated Company working since November’
                    2016. We are engaged in Design & Detailed Engineering works
                    for Highway Projects, Building Projects, Geo-technical
                    Investigation, Land Surveying, Estimating & Costing, and
                    Scheduling etc. We also prepare NOC cases for obtaining
                    Access Permissions from Mort&H, NHAI, PWD etc. Since our
                    establishment we have been working with many reputed
                    Companies to provide them technical support in the manner of
                    providing Design Engineers, Planning Engineers, and Quantity
                    Surveyors etc.{" "}
                  </p>

                  {/* <ul style={{ marginLeft: "10px" }}>
                    <li
                      style={{
                        listStyleType: "Square",
                        color: "#666666",
                        textAlign: "justify",
                      }}
                    >
                      We offer our Design and Detailed Engineering Services in
                      Civil/Structural works for
                    </li>
                    <li
                      style={{
                        listStyleType: "Square",
                        color: "#666666",
                        textAlign: "justify",
                      }}
                    >
                      {" "}
                      Highways, Buildings etc. We are also offering technical
                      services such as preparing drawings
                    </li>
                    <li
                      style={{
                        listStyleType: "Square",
                        color: "#666666",
                        textAlign: "justify",
                      }}
                    >
                      and documents for the applicants who require access
                      permission from NHAI/MoRT&H/PWD
                    </li>
                    <li
                      style={{
                        listStyleType: "Square",
                        color: "#666666",
                        textAlign: "justify",
                      }}
                    >
                      to their properties / retail outlets situated along the
                      Highway. Apart from this we are also{" "}
                    </li>
                    <li
                      style={{
                        listStyleType: "Square",
                        color: "#666666",
                        textAlign: "justify",
                      }}
                    >
                      {" "}
                      providing Skill Development courses to technical &
                      Non-Technical students. We are
                    </li>
                    <li
                      style={{
                        listStyleType: "Square",
                        color: "#666666",
                        textAlign: "justify",
                      }}
                    >
                      {" "}
                      registered with MSME, Udyam Registration no. JH01D0007211.
                    </li>
                  </ul> */}
                </div>

                <a href="/contact" class="theme-btn btn-style-four">
                  Contact Us
                </a>
              </div>
            </div>

            {/* <!--Video Column--> */}
            <div
              class="video-column col-md-6 col-sm-12 col-xs-12"
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div class="inner-column">
                <div class="video-box">
                  <figure class="image">
                    <img src="./images/background/aboutus.jpg" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section class="fluid-section-one">
        <div class="outer-container clearfix">
          {/* <!--Image Column--> */}
          <div
            class="image-column"
            style={{
              backgroundImage: `url('./images/background/new45.png')`,
            }}
            data-aos="fade-right"
          >
            <figure class="image-box">
              <img src="./images/resource/image-1.jpg" alt="" />
            </figure>
          </div>
          {/* <!--Content Column--> */}

          <div
            class="content-column"
            style={{
              backgroundImage: `url('https://danslacuisinededede.com/constructo/images/background/4.jpg')`,paddingTop:"5px"
            }}
            data-aos="fade-left"
          >
            <div class="inner-box">
              <div class="sec-title">
                <h2>
                  <span class="theme_color">Successful</span> Ways
                </h2>
              </div>
              <div
                class="text"
                style={{ textAlign: "justify" }}
                data-aos="fade-down"
              >
                Welcome to D TECH - Empowering Engineering Excellence!
                D TECH, We are providing professional Consultancy services for IOCL/ BPCL/ HPCL/ GAIL GAS, is an Engineers Associated Company working since November 2016. We are engaged in Design & Detailed Engineering works for Architectural Design, Highway Projects, Building Projects, Geo-technical Investigation, Land Surveying, Estimating & Costing, Scheduling, etc. We also prepare NOC cases for obtaining Access Permissions from Mort&H, NHAI, PWD etc. Since our establishment, we have been working with many reputed Companies to provide them with technical support in the manner of providing Design Engineers, Planning Engineers, and Quantity Surveyors, etc.
                We have undertaken various projects over time, our main client in recent years is Indian Oil Corporation Limited (Vendor Code Number: 13226074) for M/s Indian Oil we have done.

              </div>

              {/* <img src="images/1-remove.png" alt="" className="mobile_only_have_image" /> */}
              <ul class="resposive_1 clearfix">
                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up" style={{ textAlign: "justify", marginBottom: "10px" }}>
                  <IoShieldCheckmarkSharp style={{ marginRight: "8px" }} />Site visit for finalization of land of New Retail Outlets.<br />Site visit for as-built layout of Existing Retail Outlets.
                </li>
                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up" style={{ textAlign: "justify", marginBottom: "10px" }}>
                  <IoShieldCheckmarkSharp style={{ marginRight: "8px" }} />Soil investigation, ERT & Compaction Test by approved agency.<br />Site Inspection as per IOCL Standard.
                </li>

                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up" style={{ textAlign: "justify", marginBottom: "10px" }}>
                  <IoShieldCheckmarkSharp style={{ marginRight: "8px" }} />Liasoning and Follow-up to obtain construction approval & Final approval for New and existing retail outlets.
                </li>
                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up" style={{ textAlign: "justify", marginBottom: "10px" }}>
                  <IoShieldCheckmarkSharp style={{ marginRight: "8px" }} />File preparation for obtaining NH Provisional NOC & Final NOC as per MoRT&H Guidelines.
                </li>
                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up" style={{ textAlign: "justify", marginBottom: "10px" }}>
                  <IoShieldCheckmarkSharp style={{ marginRight: "8px" }} />Liasoning and Follow-up to obtain NH Provisional NOC & Final NOC for New and existing retail outlets.
                </li>
                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up" style={{ textAlign: "justify", marginBottom: "10px" }}>
                  <IoShieldCheckmarkSharp style={{ marginRight: "8px" }} />2D drawing preparation of new & existing Retail outlets as per Petroleum Rule 2002,
                </li>
                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up" style={{ textAlign: "justify", marginBottom: "10px" }}>
                  <IoShieldCheckmarkSharp style={{ marginRight: "8px" }} />File preparation for obtaining DC NOC, PESO Construction approval & Final approval as per rules P-2002, G-2016 & NGT guidelines.
                </li>

              </ul>

              {/* <img src="images/2-remove.png" alt="" className="mobile_only_have_image" />
              <h4 style={{ fontSize: "18px" }}>Gas Cylinder Rule 2016, and as per NGT guidelines:</h4> */}
              <ul class="resposive_1 clearfix">
                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up" style={{ textAlign: "justify", marginBottom: "10px" }}>
                  <IoShieldCheckmarkSharp style={{ marginRight: "8px" }} />3D Layout preparation (Interior and Exterior Design with walk-through videos).
                </li>
                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up" style={{ textAlign: "justify", marginBottom: "10px" }}>
                  <IoShieldCheckmarkSharp style={{ marginRight: "8px" }} />Site Demarcation.
                </li>
                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up" style={{ textAlign: "justify", marginBottom: "10px" }}>
                  <IoShieldCheckmarkSharp style={{ marginRight: "8px" }} />Structural Design.
                </li>
              
                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up" style={{ textAlign: "justify", marginBottom: "10px" }}>
                  <IoShieldCheckmarkSharp style={{ marginRight: "8px" }} />Carrying out Topography Survey, Volume Calculation, Contour drawing & Level Sheet preparation, and other Engineering works.
                </li>
               

              </ul>
            </div>
          </div>
        </div>
      </section>



      <section class="featured-section" style={{ marginBottom: "-70px" }}>
        <div class="auto-container">
          <div class="row clearfix">
          <div
              class="video-column col-md-6 col-sm-12 col-xs-12 resposnive_home_aboutus_vission_other"
              data-aos="fade-up-left"
            >
              <div class="inner-column">
                <div class="video-box">
                  <figure class="image">
                    <img src="images/gallery/v1.jpg" alt="" />
                  </figure>
                </div>
              </div>
            </div>
            {/* <!--Content Column--> */}
            <div class="content-column col-md-6 col-sm-12 col-xs-12 ">
              <div class="inner-column">
                <div class="sec-title" data-aos="fade-down">
                  <div class="title"></div>
                  <h2>
                    <span class="theme_color">Vision</span>
                  </h2>
                </div>
                <div class="text">
             
                  <p style={{ textAlign: "justify" }} data-aos="fade-right">
                  We are equipped to deliver top-notch engineering services with our expert team, ensuring efficient processes and optimal product utilization. We guarantee high-quality services, incorporating the latest innovative ideas. Our team is committed to continuous improvement and innovation to better meet customer needs. We maintain a strong focus on tailoring our services to align with customer requirements.{" "}
                  </p>
                  <br />
                </div>
                <a href="/contact" class="theme-btn btn-style-four">
                  Contact Us
                </a>
              </div>
            </div>

            {/* <!--Video Column--> */}
            <div
              class="video-column col-md-6 col-sm-12 col-xs-12 resposnive_home_aboutus_vission"
              data-aos="fade-up-left"
              
            >
              <div class="inner-column">
                <div class="video-box">
                  <figure class="image">
                    <img src="images/gallery/v1.jpg" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="featured-section" style={{ marginBottom: "-70px" }}>
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Content Column--> */}

            {/* <!--Video Column--> */}
            <div
              class="video-column col-md-6 col-sm-12 col-xs-12"
              data-aos="fade-up-left"
            >
              <div class="inner-column">
                <div class="video-box">
                  <figure class="image">
                    <img src="images/gallery/am1.jpg" alt="" />
                  </figure>
                </div>
              </div>
            </div>

            <div class="content-column col-md-6 col-sm-12 col-xs-12">
              <div class="inner-column">
                <div class="sec-title" data-aos="fade-down">
                  <div class="title"></div>
                  <h2 style={{color:"#F77522"}}>
                    <span class="theme_color"></span>Mission
                  </h2>
                </div>
                <div class="text">
                  <br />
              
                  <p style={{ textAlign: "justify" }} data-aos="fade-left">
                    To provide customer utmost level satisfaction and do fulfil
                    their needs with our own organization benefit and capturing
                    a good share in the market. We are committed to provide our
                    business extreme growth along with our clients satisfaction
                    with minimum cost.
                  </p>
                </div>
                <a href="/contact" class="theme-btn btn-style-four">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="fluid-section-one">
        <div class="outer-container clearfix">
          
          <div
            class="image-column"
            style={{
              backgroundImage: `url('./images/background/aboutus2.jpg')`,
            }}
            data-aos="fade-right"
          >
            <figure class="image-box">
              <img src="./images/resource/image-1.jpg" alt="" />
            </figure>
          </div>
        
          <div
            class="content-column"
            style={{
              backgroundImage: `url('https://danslacuisinededede.com/constructo/images/background/4.jpg')`,
            }}
            data-aos="fade-left"
          >
            <div class="inner-box">
              <div class="sec-title">
                <div class="title">We Offer Best Services &amp; Solutions</div>
                <h2>
                  <span class="theme_color">Why </span> Choose Us
                </h2>
              </div>
              <div
                class="text"
                style={{ textAlign: "justify" }}
                data-aos="fade-down"
              >
                Integrity and scalability are the basis of DTech business ethics, whether dealing with customers, suppliers or fellow team members. DTech's experience ensures that projects will be done right and with the upmost professionalism. Detailed estimating, proposals, permitting, and material/subcontractor coordination are essential to a successful project. DTech prides themselves with having a strong dedication to the principle that a quality product is its own best form of advertising. Customer service is one of the things many people appreciate about their time with us. You can contact us at any time, and we will address your question or request in the timeliest manner possible.
              </div>
              <ul class="list-style-one clearfix">
                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up">
                  <GrUserExpert
                    class="icon flaticon-briefcase-1"
                    style={{ padding: "4px" }}
                  />
                  EXPERT &amp; PROFESSIONAL
                </li>
                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up">
                  <FcApproval
                    class="icon flaticon-diamond-1"
                    style={{ padding: "4px" }}
                  />
                  PROFESSIONAL APPROACH
                </li>
                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up">
                  <SiTreehouse
                    class="icon flaticon-bank-building"
                    style={{ padding: "4px" }}
                  />
                  HIGH QUALITY WORK
                </li>
                <li class="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up">
                  <FaHandPeace
                    class="icon flaticon-two-fingers-up"
                    style={{ padding: "4px" }}
                  />
                  SATISFACTION GUARANTEE
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* <div>
        <div class="sec-title centered" style={{ marginLeft: "60px", marginTop: "60px" }}>

          <h2>
            <span class="theme_color">Our</span>Certificates
          </h2>
        </div>

        <div className="certification_container">
          <figure class="snip1200">
            <img
              src="./images/gallery/certificate.jpg"
              alt="sq-sample27"
              className="certificate_image"
            />
            <figcaption>
              
              <div class="heading">
                <h2>
                  <span>Certificate</span>
                </h2>
              </div>
            </figcaption>
         
          </figure>
          <figure class="snip1200">
            <img
              src="./images/gallery/workflow.jpg"
              alt="sq-sample31"
              className="certificate_image"
              style={{ height: "390px" }}
            />
            <figcaption>
              
              <div class="heading">
                <h2>
                  <span>WorkFlow</span>
                </h2>
              </div>
            </figcaption>
          
          </figure>
        </div>

      </div> */}
       <section class="call-to-action-section-two" >
        <div class="auto-container">
          <div class="row clearfix">

            <div class="column col-md-7 col-sm-12 col-xs-12">
              <h2 className="join"><span style={{color:"white"}} class="theme_color">Enroll today</span>  to enhance your abilities with our internship and CAD training programs!</h2>
              <div class="text" style={{color:"black"}}>For more information, please contact us at available number.</div>
            </div>
            <div class="btn-column col-md-5 col-sm-12 col-xs-12" >
              <div class="number"><FaWhatsapp style={{color:"green",fontWeight:"600"}}/> +91 9263636301   </div>
              <div class="number"><FiPhone style={{fontWeight:"600",color:"white"}}/> +91 8797046868  </div>
            </div>

          </div>
        </div>
      </section>

    </div>
  );
};

export default About;
