import React, { useEffect, useState } from 'react';
import Home from './Pages/Home/Home'
import About from './Pages/About/About'
import ContactUs from './Pages/ContactUs/ContactUs'
import Services from './Pages/Services/Services'
import NavaBar from './Component/NavaBar/NavaBar'
import Footer1 from './Component/Footer/Footer1'
import Gallery from './Pages/Gallery/Gallery'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ScrollToTopButton from './Component/ScrollToTopButton/ScrollToTopButton'
import WhatsAppPopup from './Component/WhatsAppButton/WhatsAppButton'
import Header from './Component/Header'
import Register from './Component/Register/Register';
import CarrerPage from './Pages/CarrerPage/CarrerPage'
import Login from './Component/Login/Login'

import Adminhome from './Admin/Adminhome'
import Thankyou from './Pages/Thankyou/Thankyou'
import Admincarrers from './Admin/Admincarrers'
import Design from './Pages/Services/Design'
import Construction from './Pages/Services/Construction'
import Interior from './Pages/Services/Interior'
import Land from './Pages/Services/Land'
import Management from './Pages/Services/Management'
import Soil from './Pages/Services/Soil'
import CadTraining from './Pages/CadTraning/CadTraning'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Expenses from './Admin/Expenses';
import Eslide from'./Component/Enquiryslide/Eslide';
import CoursePage from './Pages/CoursePage/CoursePage';
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Retrieve login state from local storage on component mount
  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
  };

  const handleLogout = () => {
    // Show logout toast message
    toast.success('Logout successful');
    
    // Wait for a short time before navigating to give the user time to see the message
    setTimeout(() => {
      setIsLoggedIn(false);
      localStorage.removeItem('isLoggedIn');
    }, 3000);
  };

  const appsList = [
    {
      appId: 0,
      appName: 'AUTOCAD',
      imageUrl:'https://sttsonline.com/images/Autocad-Logo.png',
      imagesUrl:'../Certificate.jpg',
      backinformation:"Widely used for 2D and 3D drafting.",  
      category: 'MECH',
      describe:'AutoCAD is the most prominent designing and drafting software used to create precise 2D drawings and 3D models. AutoCAD Mechanical software helps the engineers to deliver their projects in a shorter time which eventually reduces the project costs and improves profitability. CAD DESK offers the best classroom trainings in India that helps the learner to get in-depth knowledge of various tools and libraries available in the AutoCAD software.AutoCAD Mechanical is majorly used in manufacturing products and has been the first choice for the learners who want to get involved in the field of designing and constructing machinery products. CAD DESK has various courses in the field of CAD, CAM & CAE and they always prioritize giving the best quality training to students. Moreover, while taking the coaching classes for AutoCAD Mechanical, students are encouraged to do an Internship and also get an opportunity to go for industrial visits as well.',
      Coursescontent:[{introduction:["introductiondesign","introduction Autocad","fundamental of techanical drawing","theory of projection","GUI Auto cad"],
      module:["Drawing","modifytools","dimensions","texts","multipleader","productivity","advance tools","3d modeling"]}],
      CourseInformation: "This course focuses on teaching participants the fundamentals of AutoCAD, a widely used 2D and 3D drafting software. Participants will learn how to create precise technical drawings, design 3D models, annotate drawings, and more.",
      CourseInformationType: "Auto Cad",
      CourseDescripation:"Our AutoCAD course is designed to equip participants with a strong foundation in 2D and 3D drafting. Throughout the course, you will learn essential skills such as creating and editing drawings, working with layers, adding dimensions and annotations, and understanding basic modeling techniques. By the end of the course, you will be able to utilize AutoCAD effectively to design accurate technical drawings and 3D models.",
      CourseLearn:["CAD software can be complex and have a steep learning curve.","Understanding the various tools, features,","and commands might initially seem daunting.","However, with proper guidance and practice,","you can gradually overcome this challenge","and become proficient in using the software."],
    },
    {
      appId: 1,
      appName: 'SOLID WORK',
      imageUrl:
        '../soildwork.png',
      backinformation:"3D modeling software for mechanical engineering..", 
      category: 'MECH',
      describe:"SOLIDWORKS is powerful 3D CADD software that helps in shortening the design cycle and increases productivity. SOLIDWORKS is the world’s leading product design software that facilitates us to deliver innovative solutions to the engineering or technical industries. CAD DESK offers the best classroom training in India that helps the learner to get in-depth knowledge of various tools and features available in the Solidworks software.Being a 2D and 3D parametric modeler, SOLIDWORKS is essential to most engineering and drafting systems. CAD DESK provides software training on a wide range of courses in the field of CAD, CAM & CAE and also they put great emphasis on delivering quality training to students. Further, while taking the coaching classes for SOLIDWORKS, students are encouraged to do Internship and also gets an option to go for industrial visits.",
      Coursescontent:[{introduction:["introductiondesign","introduction Autocad","fundamental of techanical drawing","theory of projection","GUI Auto cad"],
      module:["Drawing","modifytools","dimensions","texts","multipleader","productivity","advance tools","3d modeling"]}],
      CourseInformation: "Our SolidWorks course is designed to provide hands-on training in this popular 3D CAD software. Participants will learn to efficiently create and modify 3D models, design assemblies, perform simulations, generate technical drawings, and explore advanced features.",
      CourseInformationType: "Solid Works",
      CourseDescripation:"The SolidWorks course focuses on providing a comprehensive understanding of this powerful 3D CAD software. You will learn to create 3D models using various tools and features, manage assemblies, simulate designs, analyze structural integrity, generate engineering drawings, and explore advanced features like parametric modeling and design automation. By mastering SolidWorks, you will be capable of designing complex mechanical and industrial components with efficiency and accuracy.",
      CourseLearn:["fundamental of techanical drawing","theory of projection","CAD software offers a wide range of functionalities,","from creating 2D drafts to complex 3D modeling and simulation.","Keeping up with the extensive capabilities of the software and effectively utilizing them can be a challenge.","It is important to focus on mastering the essential functionalities first and gradually expand your skills.",""],
    },
    {
      appId: 2,
      appName: 'CATIA',
      imageUrl:
        '../catia.png',
      backinformation:"Comprehensive CAD/CAM/CAE suite for industries.", 
      category: 'MECH',
      describe:"CATIA is the well-known CADD software developed and designed by Dassault Systemes. CATIA is widely used in various industries like automotive, aerospace, auto-manufacturing companies, and also in architectural and construction projects for designing, analyzing, and engineering insights. CAD DESK offers best class room trainings in India that helps the learner to get in depth knowledge of various tools and features that available in the CATIA software.CATIA helps in developing high quality products. Also it is multi-platform 3D software, that not only just models any product, but it also provides hands-on experience on the product as per the real world. CAD DESK provides best quality trainings to its students in the field of CAD, CAM & CAE. Moreover, while taking the coaching classes for CATIA, students are encouraged to do Internship and also gets an opportunity to go for industrial visits as well.",
      Coursescontent:[{introduction:["introductiondesign","introduction Autocad","fundamental of techanical drawing","theory of projection","GUI Auto cad"],
      module:["Drawing","modifytools","dimensions","texts","multipleader","productivity","advance tools","3d modeling"]}],
      CourseInformation: "The CATIA course introduces participants to the powerful capabilities of this advanced CAD software. Participants will learn to create 3D models, analyze designs, simulate product behavior, generate manufacturing documentation, and collaborate effectively within a collaborative design environment.",
      CourseInformationType: "Catia",
      CourseDescripation:"Our CATIA course is ideal for those looking to expand their CAD skills in the aerospace, automotive, and manufacturing industries. You will learn to create sophisticated 3D models, perform advanced surface modeling, simulate product behavior, conduct finite element analysis (FEA), develop generative shape designs, and collaborate with teams using PLM (Product Lifecycle Management) tools. This course will enable you to tackle complex design challenges and optimize product performance.",
      CourseLearn:["CAD software is regularly updated with new features and enhancements..","Staying updated with the latest version and learning new functionalities can be a challenge,","especially if you are already familiar with an older version.","However, continuous learning and staying updated with the latest developments","will help you keep pace with the industry standards.","and become proficient in using the software."],
    },
    {
      appId: 3,
      appName: 'NXCAD',
      imageUrl:
        '../nxcad.png',
      backinformation:"Advanced CAD/CAM/CAE software by Siemens.", 
      category: 'MECH',
      describe:'Siemens NX is a progressive high-end CAD/CAM/CAE software package that is widely used for mechanical product design, engineering, and manufacturing. NX CAD comes up with such a prominent designing tool that is widely used in industry and is setting high standards for speed performance. CAD DESK offers the best classroom training in India that helps the learner to get in-depth knowledge of various tools and features available in the NX CAD software.NX CAD works seamlessly with data as compare to other CAD systems and it indulges the designers to get quick results by doing more use of virtual product models and physical prototypes. CAD DESK provides the best quality training to its students in the field of CAD, CAM & CAE. Moreover, while taking the coaching classes for NX CAD, students are encouraged to do Internship and also gets an opportunity to go for industrial visits as well.',
      Coursescontent:[{introduction:["introductiondesign","introduction Autocad","fundamental of techanical drawing","theory of projection","GUI Auto cad"],
    module:["Drawing","modifytools","dimensions","texts","multipleader","productivity","advance tools","3d modeling"]}],
    CourseInformation: "The NX CAD course focuses on teaching participants the essentials of NX, a comprehensive CAD/CAM/CAE software. Participants will learn to create feature-based 3D models, perform assembly design, analyze designs, generate technical drawings, and explore advanced functionalities like parametric modeling and synchronous technology.",
    CourseInformationType: "Nx Cad",
    CourseDescripation:"Our AutoCAD course is designed to equip participants with a strong foundation in 2D and 3D drafting. Throughout the course, you will learn essential skills such as creating and editing drawings, working with layers, adding dimensions and annotations, and understanding basic modeling techniques. By the end of the course, you will be able to utilize AutoCAD effectively to design accurate technical drawings and 3D models.",
    CourseLearn:["The NX CAD course aims to provide a comprehensive understanding of this integrated CAD/CAM/CAE software.","You will learn to create feature-based 3D models using parametric design techniques,","perform assembly modeling, analyze designs using simulation tools,","generate engineering documentation, and explore advanced","functionalities like model-based definition and reverse engineering.","you can gradually overcome this challenge"],
  
    },
    {
      appId: 4,
      appName: 'CREO',
      imageUrl:
        '../creologo.png',
      backinformation:"Parametric 3D modeling software for engineering.", 
      category: 'MECH',
      describe:'Creo is a 3D CAD software developed by PTC Corporation. CREO is a powerful software that takes care of all the aspects of product design including product development, designing, and analysis. CAD DESK classroom trainings helps the students to get best software training and let them understand all the important tools and commands of the software.Creo is an easy to learn software that reduces the costs and seamlessly takes you from product designing phase to manufacturing phase very quickly and also improves product quality. With the most experienced trainers, CAD DESK deliver many software trainings in the fields of CAD, CAM & CAE that are best in the industry. While learning from best software courses, students get an option to do Internship and can also go for Industrial visits assisted by the trainers; so that it helps the students to escalate their career and let them have in-hand knowledge of the software.',
      Coursescontent:[{introduction:["introductiondesign","introduction Autocad","fundamental of techanical drawing","theory of projection","GUI Auto cad"],
    module:["Drawing","modifytools","dimensions","texts","multipleader","productivity","advance tools","3d modeling"]}],
    CourseInformation: "Creo is a 3D CAD software developed by PTC Corporation. CREO is a powerful software that takes care of all the aspects of product design including product development, designing, and analysis. CAD DESK classroom trainings helps the students to get best software training and let them understand all the important tools and commands of the software.Creo is an easy to learn software that reduces the costs and seamlessly takes you from product designing phase to manufacturing phase very quickly and also improves product quality. With the most experienced trainers, CAD DESK deliver many software trainings in the fields of CAD, CAM & CAE that are best in the industry. While learning from best software courses, students get an option to do Internship and can also go for Industrial visits assisted by the trainers; so that it helps the students to escalate their career and let them have in-hand knowledge of the software.",
    CourseInformationType: "CREO",
    CourseDescripation:"Creo is a 3D CAD software developed by PTC Corporation. CREO is a powerful software that takes care of all the aspects of product design including product development, designing, and analysis.",
    CourseLearn:["introductiondesign","introduction Autocad","fundamental of techanical drawing","Drawing","modifytools","productivity"],
  
    },
    {
      appId: 5,
      appName: 'AUTO INVENTOR',
      imageUrl:
        '../autoinvernter.png',
      backinformation:" Professional-grade 3D mechanical design software.", 
      category: 'MECH',
      describe:'Autodesk Inventor is a professional-grade three-D CAD software program for mechanical engineers that gives all middle CAD features, together with parametric design, pressure analysis, and sheet steel design. It is used to create parts, assemblies, and drawings for production and production. In addition to product design,its also first-class desirable for tooling advent and simulation to check a product in movement earlier than constructing it.Inventor is broadly favored for developing correct three-D fashions and visualisation.',
      Coursescontent:[{introduction:["introductiondesign","introduction Autocad","fundamental of techanical drawing","theory of projection","GUI Auto cad"],
    module:["Drawing","modifytools","dimensions","texts","multipleader","productivity","advance tools","3d modeling"]}],
    CourseInformation: "The course covers all the functionalities of Autodesk Inventor and its best-in-class tools for manufacturing models and drawings. Upon completion of this course, you will be able to use Inventor for creating 3D models and technical drawings for mechanical engineering purposes.",
    CourseInformationType: "AUTO INVENTOR",
    CourseDescripation:"This courseware covers the fundamental principles and recommended workflows for creating complex sketches andcurves, creating and analysing complex parts using Autodesk Inventor®.Students learn how to use construction geometry to automate and simplify sketches, and the proper techniques and recommended workflows for creating complex 2D and 3D sketches, and 3D curves. Additionally, students learn the proper techniques and recommended workflows for incorporating complex, free-form shapes into a design to create attractive ergonomic products in Autodesk Inventor.",
    CourseLearn:["CAD software can be complex and have a steep learning curve.","Autodesk Inventor students also learn","Principles and Practice of Engineering (PE)","Technical Drawing","Finite Element Analysis (FEA)","and become proficient in using the software."],
  
    },
    {
      appId: 6,
      appName: 'ANSYS',
      imageUrl:
        'ansys.png',
        backinformation:"Simulation software for engineering analysis tasks.",
      category: 'MECH',
      describe:'ANSYS Workbench is a vastly used general-purpose engineering analysis that uses virtual simulation technology to solve many different engineering problems in the industries. ANSYS supports delivering high quality products with the help of simulation and also provides better results in lesser time. CAD DESK delivers the best classroom training for ANSYS WB, which has been designed in such a way that it will give a complete understanding and working of various tools in the software.ANSYS WB training course let the learner understand not only the basics of ANSYS but also highlights the concepts of Finite Element Analysis (FEA) and Finite Element Modeling (FEM). CAD DESK delivers the best CAD, CAM, and CAE software training in India. While doing the software training, students get an option to do Internship and develop their skills by working on projects. Also, they can go on various industrial visits with their trainers to gain practical knowledge of their software, which eventually helps them in building their career.',
      Coursescontent:[{introduction:["introductiondesign","introduction Autocad","fundamental of techanical drawing","theory of projection","GUI Auto cad"],
    module:["Drawing","modifytools","dimensions","texts","multipleader","productivity","advance tools","3d modeling"]}],
    CourseInformation: "Introduction to ANSYS Interface: Learn about the software layout, navigation, and basic functionalities.",
    CourseInformationType: "ANSYS",
    CourseDescripation:"This comprehensive ANSYS course is designed to provide you with a solid foundation in engineering simulation using ANSYS software. Whether you are a beginner or have some experience, this course will guide you through the basics of the ANSYS interface, essential analysis techniques, and advanced simulation methods. You will gain hands-on experience in solving real-world engineering problems across various domains including structural, thermal, fluid dynamics, and electromagnetic analysis.",
    CourseLearn:["CAD software can be complex and have a steep learning curve.","Introduction to ANSYS","Basic Structural Analysis","Thermal Analysis","Fluid Dynamics with ANSYS Fluent","Electromagnetic Analysis with ANSYS Maxwell"],
    },
    {
      appId: 7,
      appName: 'REVIT MEP',
      imageUrl:
        'revit.png',
        backinformation:"Building design software for mechanical engineers.",
  
      category: 'MECH',
      describe:"Autodesk Revit MEP is the industry-standard tool designed for building information modeling or BIM. MEP expands as Mechanical, Electrical, and Plumbing, which are the major components of the BIM chain. By joining CAD DESK classroom training for Revit MEP, students get an opportunity to learn from our well experienced trainers; who gives a great emphasis on delivering quality training to each of its learners.MEP project template in Revit is designed in such a way that it helps the learner to understand the working and uses of ducting, mechanical equipment, plumbing, electrical fixtures in a building. CAD DESK provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training for Revit MEP, students are encouraged to do an Internship and also get an opportunity to go for industrial visits.",
      Coursescontent:[{introduction:["introductiondesign","introduction Autocad","fundamental of techanical drawing","theory of projection","GUI Auto cad"],
    module:["Drawing","modifytools","dimensions","texts","multipleader","productivity","advance tools","3d modeling"]}],
    CourseInformation: "Autodesk Revit MEP is the industry-standard tool designed for building information modeling or BIM. MEP expands as Mechanical, Electrical, and Plumbing, which are the major components of the BIM chain. By joining CAD DESK classroom training for Revit MEP, students get an opportunity to learn from our well experienced trainers; who gives a great emphasis on delivering quality training to each of its learners.MEP project template in Revit is designed in such a way that it helps the learner to understand the working and uses of ducting, mechanical equipment, plumbing, electrical fixtures in a building. CAD DESK provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training for Revit MEP, students are encouraged to do an Internship and also get an opportunity to go for industrial visits.",
    CourseInformationType: "REVIT MEP",
    CourseDescripation:"Autodesk Revit MEP is the industry-standard tool designed for building information modeling or BIM. MEP expands as Mechanical, Electrical, and Plumbing, which are the major components of the BIM chain.",
    CourseLearn:["introductiondesign","introduction Autocad","fundamental of techanical drawing","theory of projection","GUI Auto cad","3d modeling"],
    },
    {
      appId: 8,
      appName: 'CNC',
      imageUrl:
        './cnc.png',
        backinformation:"Automated machining control system for manufacturing.",
  
      category: 'MECH',
      describe:'CNC programming (Computer Numerical Control Programming) is the software that is beneficial for creating computer program instructions to control machine tools. CNC is vastly used in the manufacturing process and promotes good mechanisms as well as flexibility. By joining CAD DESK classroom training for CNC Programming, students get an opportunity to learn from our expert trainers; who give a great emphasis on delivering the best quality training to each of its learners.Using CNC, a machine can be managed with supervision and it will increase productivity and minimize the risk of getting errors on the project. CAD DESK delivers the best CAD, CAM, and CAE software training in India. While doing the software training, students get an option to do Internship and develop their skills by working on projects. Also, they can go on various industrial visits with their trainers to gain practical knowledge of their software, which eventually helps them in building their career.',
      Coursescontent:[{introduction:["introductiondesign","introduction Autocad","fundamental of techanical drawing","theory of projection","GUI Auto cad"],
    module:["Drawing","modifytools","dimensions","texts","multipleader","productivity","advance tools","3d modeling"]}],
    CourseInformation: "Manufactured items differ in size and dimensions from the original CAD model due to variations in the manufacturing processes.",
    CourseInformationType: "GD&T",
    CourseDescripation:"GD&T tells manufacturing partners and inspectors the allowable variation within the product assembly and standardizes how that variation is measured.",
    CourseLearn:["The basic principles of GD&T","An overview of GD&T symbols"," manufacturing features were specified by X-Y areas","A case study that shows GD&T in use with cad","and a real-world product application"],
    }, 
    {
      appId: 9,
      appName: 'ART CAM',
      imageUrl:
        './artcam.png',
        backinformation:"Software for artistic design and manufacturing.",
  
      category: 'MECH',
      describe:'ArtCAM is a group of CAD/CAM software program bundle for designing innovative and inventive merchandise and manufacturing. It is a CAM laptop software particularly used while the consumer works on wooden engraving machines or comparable machines. 3-d Archlab on-line education path explains approximately 2D and 3-d built in layout functions which include reliefs, engraving, signal making, etc.ArtCAM is enormously encouraged software program in quite a few sectors from jewelry and coin minting, to the layout and manufacture of architectural cornicing and facades. 3-d Archlab additionally gives Internships to its college students and additionally includes on Industrial visits all through the path. Online software program education is supplied at the ultra-modern model of ArtCAM.',
      Coursescontent:[{introduction:["introductiondesign","introduction Autocad","fundamental of techanical drawing","theory of projection","GUI Auto cad"],
    module:["Drawing","modifytools","dimensions","texts","multipleader","productivity","advance tools","3d modeling"]}],
    CourseInformation: "This course focuses on teaching participants the fundamentals of AutoCAD, a widely used 2D and 3D drafting software. Participants will learn how to create precise technical drawings, design 3D models, annotate drawings, and more.",
    CourseInformationType: "Auto Cad",
    CourseDescripation:"Our AutoCAD course is designed to equip participants with a strong foundation in 2D and 3D drafting. Throughout the course, you will learn essential skills such as creating and editing drawings, working with layers, adding dimensions and annotations, and understanding basic modeling techniques. By the end of the course, you will be able to utilize AutoCAD effectively to design accurate technical drawings and 3D models.",
    CourseLearn:["CAD software can be complex and have a steep learning curve.","Understanding the various tools, features,","and commands might initially seem daunting.","However, with proper guidance and practice,","you can gradually overcome this challenge","and become proficient in using the software."],
    },
    {
      appId: 10,
      appName: 'NX CAM',
      imageUrl:
        'nxcam.png',
        backinformation:"Advanced CAM software for machining tasks.",
  
      category: 'MECH',
      describe:'Siemens NX is an advanced high-end CAD/CAM/CAE software package that is used for mechanical product design, engineering, and manufacturing.  It is the most flexible and innovative product design application used for product development solutions in the industry. CAD DESK provides classroom training for NX CAM through which students get an opportunity to learn from our expert trainers; who give a great emphasis on delivering the best quality training to each of its learners.NX CAM uses the most consistent 3D models, data, and processes that produce complex parts with fewer actions and setups and eventually reduces the costs and delivery times. CAD DESK provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training for NX CAM, students get motivated to do Internship and also gets an opportunity to go for industrial visits as well.',
      Coursescontent:[{introduction:["introductiondesign","introduction Autocad","fundamental of techanical drawing","theory of projection","GUI Auto cad"],
    module:["Drawing","modifytools","dimensions","texts","multipleader","productivity","advance tools","3d modeling"]}],
    CourseInformation: "This course focuses on teaching participants the fundamentals of AutoCAD, a widely used 2D and 3D drafting software. Participants will learn how to create precise technical drawings, design 3D models, annotate drawings, and more.",
    CourseInformationType: "Auto Cad",
    CourseDescripation:"Our AutoCAD course is designed to equip participants with a strong foundation in 2D and 3D drafting. Throughout the course, you will learn essential skills such as creating and editing drawings, working with layers, adding dimensions and annotations, and understanding basic modeling techniques. By the end of the course, you will be able to utilize AutoCAD effectively to design accurate technical drawings and 3D models.",
    CourseLearn:["CAD software can be complex and have a steep learning curve.","Understanding the various tools, features,","and commands might initially seem daunting.","However, with proper guidance and practice,","you can gradually overcome this challenge","and become proficient in using the software."],
  
    },
    {
      appId: 11,
      appName: 'REVIT STR',
      imageUrl:
        'Autocad-Logo.png',
        backinformation:"Widely used for 2D and 3D drafting.",
      category: 'CIVI',
      describe:"AutoCAD Civil 3D software is used in civil engineering that enables building information modeling (BIM) workflows. Civil 3D is a great software that boosts up project performance, maintains consistent data, and follows standard processes. CAD DESK offers the best classroom training for Civil 3D through which students understand the surfaces, lay out parcels, and design geometry by including the making of horizontal alignments and vertical profile, and many more.AutoCAD & Civil 3D both have similar interfaces and Civil 3D also has many AutoCAD compatible shortcuts available. CAD DESK provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training, students are encouraged to do an Internship and also get an opportunity to go for industrial visits as well.",
      CourseInformation: "while taking the coaching classes for Revit Architecture, students are encouraged to do Internship and also gets an opportunity to go for industrial visits as well.",
      CourseInformationType: "REVIT STR",
      CourseDescripation:"This skill helps aspirants to learn the function of software and its utilisation according to the project requirement. REVIT STR texture skills help mainly in the field of Designing specific structures of buildings, etc.",
      CourseLearn:["CAD software can be complex and have a steep learning.","introductiondesign","introduction Revit str","fundamental of techanical drawing","hands on experience","project explain"],
      Coursescontent:[{introduction:["introductiondesign","introduction Autocad","fundamental of techanical drawing","theory of projection","GUI Auto cad"],
    module:["Drawing","modifytools","dimensions","texts","multipleader","productivity","advance tools","3d modeling"]}]
    },
    {
      appId: 12,
      appName: 'REVIT ARCH',
      imageUrl:
        'revit.png',
        backinformation:" BIM software for architectural design projects.",
  
      category: 'CIVI',
      describe:'Autodesk Revit is the industry-standard tool designed for building information modeling or BIM. Revit Architecture training helps in enhancing the efficiency and accuracy across the project lifecycle, from concept to construction and later maintenance. As the model develops, Revit automatically updates the floor plans, elevations, and sections. CAD DESK offers the best classroom training in India that helps the learner to get in-depth knowledge of various tools and features available in the Revit Architecture software. Revit Architecture is a 3D designing software that enables the design of building structure and its components and further effectively analyzes the structure. CAD DESK provides the best quality training to its students in the field of CAD, CAM & CAE. Moreover, while taking the coaching classes for Revit Architecture, students are encouraged to do Internship and also gets an opportunity to go for industrial visits as well.',
      CourseInformation: "The provides the best quality training to its students in the field of CAD, CAM & CAE. Moreover, while taking the coaching classes for Revit Architecture, students are encouraged to do Internship and also gets an opportunity to go for industrial visits as well.",
      CourseInformationType: "REVIT ARCH",
      CourseDescripation:"This skill helps aspirants to learn the function of software and its utilisation according to the project requirement. Revit architecture texture skills help mainly in the field of Designing specific structures of buildings, etc.",
      CourseLearn:["CAD software can be complex and have a steep learning.","introductiondesign","introduction Revit Arch","fundamental of techanical drawing","hands on experience","project explain"],
    },
    {
      appId: 13,
      appName: 'SKETCH UP',
      imageUrl:
        'sketchup.png',
        backinformation:"3D modeling software for architectural visualization.",
  
      category: 'CIVI',
      describe:'SketchUp (formerly known as Google SketchUp) is a great tool to create 3D models in architecture, interior design, woodworking, or engineering. It has quite a user-friendly interface as compare to 3DS Max, with a splendid user environment than other computer-aided design programs. CAD DESK offers the best classroom training in India that helps the learner to get in-depth knowledge of various tools and features available in the Google SketchUp software. SketchUp is a built-in 3D modeling application that lets us create and edit 2D and 3D models with a patented “Push/Pull” technology and it is widely preferred by construction owners, architects, and engineers for error-free work output. CAD DESK provides the best quality training to its students in the field of CAD, CAM & CAE. Moreover, while taking the coaching classes, students are encouraged to do Internship and also gets an opportunity to go for industrial visits as well.',
      CourseInformation: "SketchUp (formerly known as Google SketchUp) is a great tool to create 3D models in architecture, interior design, woodworking, or engineering. It has quite a user-friendly interface as compare to 3DS Max, with a splendid user environment than other computer-aided design programs. CAD DESK offers the best classroom training in India that helps the learner to get in-depth knowledge of various tools and features available in the Google SketchUp software. SketchUp is a built-in 3D modeling application that lets us create and edit 2D and 3D models with a patented “Push/Pull” technology and it is widely preferred by construction owners, architects, and engineers for error-free work output. CAD DESK provides the best quality training to its students in the field of CAD, CAM & CAE. Moreover, while taking the coaching classes, students are encouraged to do Internship and also gets an opportunity to go for industrial visits as well.",
      CourseInformationType: "SKETCH UP",
      CourseDescripation:"This skill helps aspirants to learn the function of software and its utilisation according to the project requirement. Revit architecture texture skills help mainly in the field of Designing specific structures of buildings, etc.",
      CourseLearn:["CAD software can be complex and have a steep learning.","Understand Building Information Modeling (BIM)","Navigate the Revit Interface"," Create and Modify Building Elements","Work with Families and Components","Develop Comprehensive 3D Models"],
    },
    
    {
      appId: 14,
      appName: 'V-RAYS',
      imageUrl:
        'vrays.png',
        backinformation:" Rendering software for high-quality visualizations.",
  
      category: 'CIVI',
      describe:'V-Ray is the complete lighting, shading, and rendering toolkit using which you can render anything & everything. V-Ray is a commercial plug-in, used for visualizations and computer graphics in industries such as media, entertainment, film and video game production, industrial design, product design, and architecture. CAD DESK offers the best classroom training in India that helps the learner to get in depth knowledge of various tools and features available in the V-Ray software.Due to the usage of advanced techniques, V-Ray is highly recommended in multiple industries such as architectural visualization, advertising, and visual effects, etc., and is most commonly preferred by conventional renders. CAD DESK provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training, students are encouraged to do an Internship and also get an opportunity to go for industrial visits as well.',
      CourseInformation: "V-Ray is the complete lighting, shading, and rendering toolkit using which you can render anything & everything. V-Ray is a commercial plug-in, used for visualizations and computer graphics in industries such as media, entertainment, film and video game production, industrial design, product design, and architecture.",
      CourseInformationType: "V-RAYS",
      CourseDescripation:"V-Ray is the complete lighting, shading, and rendering toolkit using which you can render anything & everything. V-Ray is a commercial plug-in, used for visualizations and computer graphics in industries such as media, entertainment, film and video game production, industrial design, product design, and architecture. CAD DESK offers the best classroom training in India that helps the learner to get in depth knowledge of various tools and features available in the V-Ray software.Due to the usage of advanced techniques, V-Ray is highly recommended in multiple industries such as architectural visualization, advertising, and visual effects, etc., and is most commonly preferred by conventional renders. CAD DESK provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training, students are encouraged to do an Internship and also get an opportunity to go for industrial visits as well.",
      CourseLearn:["CAD software can be complex and have a steep learning.","Getting Ready to Render with V-Ray","Locating V-Ray’s tools and features","Dealing with lighting problems","Working with the dome light","Using the DMC Sampler"],
    },
    {
      appId: 15,
      appName: 'LUMION',
      imageUrl:
        'lumion.png',
        backinformation:"Real-time 3D visualization software for architects.",
  
      category: 'CIVI',
      describe:' Lumion is 3D rendering software for architects. Stable, fast, and intuitive, this tool helps you maintain your creative flow and transform architecture, interior, landscape, and urban design into high-quality visualizations. Whether you are a complete beginner or a rendering pro, Lumions user-friendly interface makes it easy to import your 3D models and bring them to life. Compatible with Revit, 3ds Max, SketchUp, ArchiCAD, Rhino, and more,it fits seamlessly into your workflow and transforms any model into a haptic experience. Lumion allows you to iterate during construction and quickly integrate visualizations at every stage of design development thanks to its lightning-fast rendering speeds. Everything you need to create stunning images, fly-through videos, and 360-degree panoramas of your designs, including realistic objects and materials, time-saving tools, and one-touch effects.',
      CourseInformation: "Lumion is 3D rendering software for architects. Stable, fast, and intuitive, this tool helps you maintain your creative flow and transform architecture, interior, landscape, and urban design into high-quality visualizations.",
      CourseInformationType: "LUMION",
      CourseDescripation:" Lumion is 3D rendering software for architects. Stable, fast, and intuitive, this tool helps you maintain your creative flow and transform architecture, interior, landscape, and urban design into high-quality visualizations. Whether you are a complete beginner or a rendering pro, Lumions user-friendly interface makes it easy to import your 3D models and bring them to life. Compatible with Revit, 3ds Max, SketchUp, ArchiCAD, Rhino, and more,it fits seamlessly into your workflow and transforms any model into a haptic experience. Lumion allows you to iterate during construction and quickly integrate visualizations at every stage of design development thanks to its lightning-fast rendering speeds. Everything you need to create stunning images, fly-through videos, and 360-degree panoramas of your designs, including realistic objects and materials, time-saving tools, and one-touch effects.",
      CourseLearn:["CAD software can be complex and have a steep learning.","Lumion Overview","Scaling & moving..etc","Directions","Loading and importing Models","Features and Use"],
    },
    {
      appId: 16,
      appName: 'STAAD PRO',
      imageUrl:
        'staadpro.png',
        backinformation:"Structural analysis and design software tool.",
  
      category: 'CIVI',
      describe:"STAAD Pro stands for Structural Analysis and Design Program originally developed by Research Engineers International, which was later bought by Bentley STAAD Pro training is widely recommended in Structural Analysis and Design Software which simplifies the BIM workflow by automatically converting a physical model into an analytical model. CAD DESK classroom training helps the students to get the best software training and lets them understand all the important tools and commands of the software.STAAD Pro is the most powerful software that can design almost any kind of civil engineering structure such as steel, concrete, timber, aluminum, and cold-formed steel designs. The newly released or complete package of analysis from Bentley is STAAD Pro V8i. With the most experienced trainers, CAD DESK delivers many software training in the fields of CAD, CAM & CAE that are the best in the industry. While learning from the best software courses, students get an option to do an Internship and can also go for Industrial visits assisted by the trainers; so that it helps the students to escalate their career and let them have in-hand knowledge of the software.",
      CourseInformation: "STAAD Pro stands for Structural Analysis and Design Program originally developed by Research Engineers International, which was later bought by Bentley STAAD Pro training is widely recommended in Structural Analysis and Design Software which simplifies the BIM workflow by automatically converting a physical model into an analytical model.",
      CourseInformationType: "STAAD PRO",
      CourseDescripation:"STAAD Pro stands for Structural Analysis and Design Program originally developed by Research Engineers International, which was later bought by Bentley STAAD Pro training is widely recommended in Structural Analysis and Design Software which simplifies the BIM workflow by automatically converting a physical model into an analytical model. CAD DESK classroom training helps the students to get the best software training and lets them understand all the important tools and commands of the software.STAAD Pro is the most powerful software that can design almost any kind of civil engineering structure such as steel, concrete, timber, aluminum, and cold-formed steel designs. The newly released or complete package of analysis from Bentley is STAAD Pro V8i. With the most experienced trainers, CAD DESK delivers many software training in the fields of CAD, CAM & CAE that are the best in the industry. While learning from the best software courses, students get an option to do an Internship and can also go for Industrial visits assisted by the trainers; so that it helps the students to escalate their career and let them have in-hand knowledge of the software.",
      CourseLearn:["CAD software can be complex and have a steep learning.","Overview of Structural Analysis and Design","Introduction to STAAD Pro.","Geometry Creation Methods","Nodal Moment","Global Coordinate System"],
    },
    {
      appId: 17,
      appName: 'ETAB',
      imageUrl:
        'etabs.png',
        backinformation:"Structural analysis and design software solution.",
  
      category: 'CIVI',
      describe:"ETABS expands as Extended3D Analysis of Building System. ETABS is a structural analysis and design software developed by Computers and Structures, Inc. (CSI). ETABS is an Engineering software application that is highly used for multi-storied building structural analysis as well as structural design. CAD DESK offers the best classroom training for Civil 3D through which students understand the surfaces, lay out parcels, and design geometry by including the making of horizontal alignments and vertical profile, and many more.ETABS has powerful CAD-like drawing tools available with an integrated system, which enhances its ability to handle the largest and even the complex building models and configurations. CAD DESK provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training for Etabs, students get motivated to do Internship and also gets an opportunity to go for industrial visits as well.",
      CourseInformation: "ETABS expands as Extended3D Analysis of Building System. ETABS is a structural analysis and design software developed by Computers and Structures, Inc. (CSI).",
      CourseInformationType: "ETAB",
      CourseDescripation:"ETABS expands as Extended3D Analysis of Building System. ETABS is a structural analysis and design software developed by Computers and Structures, Inc. (CSI). ETABS is an Engineering software application that is highly used for multi-storied building structural analysis as well as structural design. CAD DESK offers the best classroom training for Civil 3D through which students understand the surfaces, lay out parcels, and design geometry by including the making of horizontal alignments and vertical profile, and many more.ETABS has powerful CAD-like drawing tools available with an integrated system, which enhances its ability to handle the largest and even the complex building models and configurations. CAD DESK provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training for Etabs, students get motivated to do Internship and also gets an opportunity to go for industrial visits as well.",
      CourseLearn:["CAD software can be complex and have a steep learning.","Introduction to ETABS","Introduction to Structure Design","Drawing Tools","Select Commands","Composite Columns"],
    },
    {
      appId: 18,
      appName: 'CIVIL 3D',
      imageUrl:
        'civil3d.png',
        backinformation:"Civil engineering design and documentation software.",
  
      category: 'CIVI',
      describe:'Autodesk 3DS Max is a powerful software and is highly recommended among modeling and rendering tools. 3DS Max especially shows its strength in architecture, manufacturing, industrial design, and many more like visual graphics models. CAD DESK offers the best classroom training in India that helps the learner to get in-depth knowledge of various tools and features available in the 3DS Max software.During the initial days, 3DS Max was known as a 3D Studio and 3D Studio Max. With its artist-friendly user interface, it saves a lot of time and accelerates manual steps that boost productivity. 3DS Max works is widely used in designing 2D cross-section shapes of 3D models. CAD DESK provides software training on a wide range of courses in the field of CAD, CAM & CAE and they put great emphasis on delivering quality training to students. Further, while taking the coaching classes for 3DS Max, students are encouraged to do an Internship and also get an option to go for industrial visits.',
      CourseInformation: "Autodesk 3DS Max is a powerful software and is highly recommended among modeling and rendering tools. 3DS Max especially shows its strength in architecture, manufacturing, industrial design, and many more like visual graphics models.",
      CourseInformationType: "CIVIL 3D",
      CourseDescripation:"Autodesk 3DS Max is a powerful software and is highly recommended among modeling and rendering tools. 3DS Max especially shows its strength in architecture, manufacturing, industrial design, and many more like visual graphics models. CAD DESK offers the best classroom training in India that helps the learner to get in-depth knowledge of various tools and features available in the 3DS Max software.During the initial days, 3DS Max was known as a 3D Studio and 3D Studio Max. With its artist-friendly user interface, it saves a lot of time and accelerates manual steps that boost productivity. 3DS Max works is widely used in designing 2D cross-section shapes of 3D models. CAD DESK provides software training on a wide range of courses in the field of CAD, CAM & CAE and they put great emphasis on delivering quality training to students. Further, while taking the coaching classes for 3DS Max, students are encouraged to do an Internship and also get an option to go for industrial visits.",
      CourseLearn:["CAD software can be complex and have a steep learning.","Application workspace and preferences","Navigating in viewport","Application workspace and preferences","Standard and Extended Primitives","Modelling with primitives and modifiers"],
    },
   
    {
      appId: 19,
      appName: 'PRIMAVERA',
      imageUrl:
        'primvera.png',
        backinformation:"Project management software for construction projects.",
  
      category: 'CIVI',
      describe:"Primavera is a project, program, and portfolio control software. It belongs to Oracle Corporation. Primavera is broadly used for making plans, managing, and executing initiatives of all kinds. In architectural and civil engineering, it's miles used to address massive and small production initiatives. Some of its foremost packages are in making plans and scheduling, useful resource control, and chance and trouble control.",
      CourseInformation: "Primavera is a project, program, and portfolio control software. It belongs to Oracle Corporation. Primavera is broadly used for making plans, managing, and executing initiatives of all kinds. In architectural and civil engineering, it's miles used to address massive and small production initiatives. Some of its foremost packages are in making plans and scheduling, useful resource control, and chance and trouble control.",
      CourseInformationType: "PRIMAVERA",
      CourseDescripation:"Primavera is a project, program, and portfolio control software. It belongs to Oracle Corporation. Primavera is broadly used for making plans, managing, and executing initiatives of all kinds.",
      CourseLearn:["CAD software can be complex and have a steep learning.","Risk management","Informative dashboard","Reporting and analytics","Resource Analysis & Allocation","Assign resources to tasks, and track progress"],
    },
    {
      appId: 21,
      appName: 'AUTOCAD',
      imageUrl:
        'Autocad-Logo.png',
        backinformation:"Widely used for 2D and 3D drafting.",
  
      category: 'ELEC',
      CourseInformation: "This course focuses on teaching participants the fundamentals of AutoCAD, a widely used 2D and 3D drafting software. Participants will learn how to create precise technical drawings, design 3D models, annotate drawings, and more.",
      CourseInformationType: "Auto Cad",
      CourseDescripation:"Our AutoCAD course is designed to equip participants with a strong foundation in 2D and 3D drafting. Throughout the course, you will learn essential skills such as creating and editing drawings, working with layers, adding dimensions and annotations, and understanding basic modeling techniques. By the end of the course, you will be able to utilize AutoCAD effectively to design accurate technical drawings and 3D models.",
      CourseLearn:["CAD software can be complex and have a steep learning.","CAD software can be complex and have a steep learning. curve.","Understanding the various tools, features,","and commands might initially seem daunting.","However, with proper guidance and practice,","you can gradually overcome this challenge","and become proficient in using the software."],
    },
    {
      appId: 22,
      appName: 'AUTO ELECTRIC',
      imageUrl:
        'autoelectric.png',
        backinformation:" Software for automotive electrical systems.",
  
      category: 'ELEC',
      describe:"AutoCAD Electrical is one of the most dominant software programs developed by Autodesk which is widely used in creating and modifying electrical control systems. It has a complete toolset available which includes a number of drafting commands like wiring, circuiting, PLC modules, panels, and many more. The Electrical toolset boosts up the productivity level by 95% as compared to other traditional 2D drafting methods. CAD DESK offers the best classroom training in India that helps the learner to get in depth knowledge of various tools and features available in the AutoCAD Electrical software.",
      CourseInformation: "AutoCAD Electrical is one of the most dominant software programs developed by Autodesk which is widely used in creating and modifying electrical control systems.",
      CourseInformationType: "AUTO ELECTRIC",
      CourseDescripation:"It has a complete toolset available which includes a number of drafting commands like wiring, circuiting, PLC modules, panels, and many more. The Electrical toolset boosts up the productivity level by 95% as compared to other traditional 2D drafting methods.",
      CourseLearn:["CAD software can be complex and have a steep learning.","Need of Electrical drawing","Inserting and Editing components","Inserting and Editing wires","Introduction Panel layout","Component report"],
    },
    {
      appId: 23,
      appName: 'EPLAN',
      imageUrl:
        'elpan.png',
        backinformation:" Electrical engineering software for circuit design.",
  
      category: 'ELEC',
      describe:"EPLAN Electric P8 is an electrical engineering design software program that offers unlimited possibilities for project planning, documentation, and management. EPLAN Electric P8 supports a variety of engineering methods due to which it is one of the most preferred software in industries like maritime, building, automation technology, machinery, and plant construction, and more. CAD DESK delivers the best classroom training for Eplan Electric, which has been designed in such a way that it will give a complete understanding and working of various tools in the software.",
      CourseInformation: "EPLAN Electric P8 is an electrical engineering design software program that offers unlimited possibilities for project planning, documentation, and management.",
      CourseInformationType: "EPLAN",
      CourseDescripation:"EPLAN Electric P8 supports a variety of engineering methods due to which it is one of the most preferred software in industries like maritime, building, automation technology, machinery, and plant construction, and more.",
      CourseLearn:["CAD software can be complex and have a steep learning.","Fundamentals of Power System Analysis","Load Flow Analysis","Transformer X/R Ratio Calculation","Motor Starting Analysis","Short Circuit Analysis"],
    },
    {
      appId: 24,
      appName: 'MATLAB',
      imageUrl:
        'matlab.png',
        backinformation:"High-level programming language and numerical computing.",
  
      category: 'ELEC',
      describe:"MATLAB is an abbreviation of Matrix Laboratory which is developed by MathWorks for providing a platform for engineers and scientists to perform numerical computing. MATLAB has a worldwide presence and is highly used in various backgrounds of engineering, science, and economics. CAD DESK classroom training helps the students to get the best software training and lets them understand all the important tools and commands of the software.MATLAB provides an easy-to-use environment for math and computation, algorithm development, data analysis, scientific and engineering graphics, and exploration and visualization. Also, MATLAB is compatible to work on Windows, macOS, and Linux operating systems. CAD DESK provides software training on a wide range of software in the field of CAD, CAM & CAE and also they put great emphasis on prioritizing in giving quality training to students. Further, while taking the coaching classes for MATLAB, students are encouraged to do Internship and also gets an option to go for industrial visits.",
      CourseInformation: "MATLAB is an abbreviation of Matrix Laboratory which is developed by MathWorks for providing a platform for engineers and scientists to perform numerical computing.",
      CourseInformationType: "MATLAB",
      CourseDescripation:".MATLAB provides an easy-to-use environment for math and computation, algorithm development, data analysis, scientific and engineering graphics, and exploration and visualization",
      CourseLearn:["CAD software can be complex and have a steep learning.","Working with the MATLAB user interface","Overview of MATLAB syntax","Analyzing vectors and matrices","Working with data files","Working with data types"],
    },
    {
      appId: 25,
      appName: 'PLC',
      imageUrl:
        'plc.png',
        backinformation:"Programmable Logic Controller for industrial automation.",
  
      category: 'ELEC',
      describe:"Programmable logic controllers (PLCs) are one of the most useful technologies in the industries for automation. They are industrial computer systems that are used to control the electro-mechanical processes in various industries like manufacturing, plants, etc. CAD DESK provides classroom training for PLC, through which students get an opportunity to learn from our expert trainers; who give a great emphasis on delivering the best quality training to each of its learners. PLCs are available in varied sizes and forms. PLC provides the ability to reprogram the process at very little cost. Programmable logic controllers are flexible and depending on the input and output, it records the data, such as machine productivity or operating temperature. CAD DESK provides the best quality training to its students in the field of CAD, CAM & CAE. Moreover, while taking the coaching classes, students are encouraged to do Internship and also gets an opportunity to go for industrial visits as well.",
      CourseInformation: "Programmable logic controllers (PLCs) are one of the most useful technologies in the industries for automation.",
      CourseInformationType: "PLC",
      CourseDescripation:"PLCs are available in varied sizes and forms. PLC provides the ability to reprogram the process at very little cost. Programmable logic controllers are flexible and depending on the input and output, it records the data, such as machine productivity or operating temperature.",
      CourseLearn:["CAD software can be complex and have a steep learning.","Introduction to Industrial Automation","Application of Industrial Automation","Controller(PLC)","Introduction to Programmable Logic","Addressing for PLC."],
    },
    {
      appId: 26,
      appName: 'SCADA',
      imageUrl:
        'scada.png',
        backinformation:"Supervisory Control and Data Acquisition system.",
  
      category: 'ELEC',
      describe:"Supervisory Control and Data Acquisition (SCADA) is an automation control system that is widely used in various industries like energy, oil, and gas, water, power, etc. SCADA helps in monitoring and controlling various processes in industries. By joining CAD DESK classroom training for Scada, students get an opportunity to learn from our well experienced trainers; who give a great emphasis on delivering quality training to each of its learners.SCADA systems contain both hardware and software components and it gathers data from different sensors and systems located in remote areas, and further performs the analysis. The hard disk present in the SCADA system, stores the data into files, which can be printed whenever required. CAD DESK provides the best coaching classes to its students in the field of CAD, CAM & CAE. And, while taking the software training for Scada, students are encouraged to do an Internship and also get an opportunity to go for industrial visits.",
      CourseInformation: "Supervisory Control and Data Acquisition (SCADA) is an automation control system that is widely used in various industries like energy, oil, and gas, water, power, etc.",
      CourseInformationType: "SCADA",
      CourseDescripation:"SCADA systems contain both hardware and software components and it gathers data from different sensors and systems located in remote areas, and further performs the analysis.",
      CourseLearn:["CAD software can be complex and have a steep learning.","SCADA -What is it?","Modern Automation Systems", "Why SCADA? What is SCADA?","Elements of SCADA","Actuators"],
    },
    
    
    
  
    {
      appId: 34,
      appName: 'Python',
      imageUrl:
      'python.png',
        backinformation:"Development covering both frontend and backend.",
  
      category: 'IT',
      CourseInformation: "Data science with Python performs data analysis, data visualization, web scraping, and etc.",
      CourseInformationType: "Python",
      CourseDescripation:"Python, a versatile programming language, has gained immense popularity among data scientists due to its simplicity, extensive libraries, and powerful analytical capabilities.",
      CourseLearn:["Explore new things it's here.","Ease of Use","you will write code","Course Curriculum","problem solve ability","syntax"],
    },
    {
      appId: 35,
      appName: 'C,C++',
      imageUrl:
        'fullstack.png',
        backinformation:"Development covering both frontend and backend.",
  
      category: 'IT',
      CourseInformation: "A C/C++ programming internship is a practical learning opportunity designed for students and programming enthusiasts.",
      CourseInformationType: "C,C++",
      CourseDescripation:"It allows participants to apply their theoretical knowledge to real-world projects under the guidance of experienced professionals.",
      CourseLearn:["Explore new things it's here.","Introduction and Basics of C","Data Types and Variables","Control Flow Statements","Formatted IO functions","Functions and Program Structure"],
    },
    {
      appId: 36,
      appName: 'Ms-Office',
      imageUrl:
        'cibi.jpeg',
        backinformation:"A legally break into computers and networks to test and oraganization security.",
  
      category: 'IT',
      CourseInformation: "The Microsoft Office publications we provide are designed to assist put together you for a profession in commercial enterprise administration.",
      CourseInformationType: "Ms-Office",
      CourseDescripation:"The facts management, file control, and workplace productivity, providing mastery in vital equipment like Word, Excel, PowerPoint, and Outlook.",
      CourseLearn:["Explore new things it's here.","Typing the text","Editing Text: Cut, Copy, Paste, Select All, Clear","Font Color, Use the Bold, Italic, and Underline","Line spacing, Paragraph spacing","Shapes, Clipart and Picture, Word Art, Smart Art"],
    },
    {
      appId: 37,
      appName: 'Tally',
      imageUrl:
      'java.png',
        backinformation:"https://itdeskmahadevpura.com/allprogram",
  
      category: 'IT',
      CourseInformation: "Tally is a popular and powerful accounting software widely used by businesses of all sizes to manage their financial data efficiently.",
      CourseInformationType: "Tally",
      CourseDescripation:"this comprehensive software offers a range of features and capabilities that make it a reliable and user-friendly choice for accounting and financial management tasks.",
      CourseLearn:["Explore new things it's here.","Fundamentals of Tally","Introduction to Accounting","ERP 9 Fundamentals","Accounting and Inventory in Tally E.R.P 9","Generating Basic Accounting Reports"],
    },
    {
      appId: 38,
      appName: 'Photo-Shop',
      imageUrl:
        'digi.jpeg',
        backinformation:"The most powerfull tool to grow business research.",
  
      category: 'IT',
      CourseInformation: "We will teach ow to edit photo,video and plugins",
      CourseInformationType: "Photo-Shop",
      CourseDescripation:"Graphic design software used for enhancing and editing images, including architectural visualizations.",
      CourseLearn:["Explore new things it's here.","Photoshop Workspace","Importing","Working with Layers","Brushes with Masks","Collaging & Editing Drawings"],
    },
    
  ]
  
  return (
    <div class="page-wrapper">
      <BrowserRouter>
        <Header isLoggedIn={isLoggedIn} onLogout={handleLogout} />
        {/* <NavaBar/> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Career" element={<CarrerPage />} />
          <Route path="/login" element={isLoggedIn ? <Navigate to="/admincarrers" /> : <Login onLogin={handleLogin} />} />
          <Route path="/adminhome" element={isLoggedIn ? <Adminhome /> : <Navigate to="/login" />} />
          <Route path="/admincarrers" element={isLoggedIn ? <Admincarrers /> : <Navigate to="/login" />} />
          <Route path="/expenses" element={<Expenses isLoggedIn={isLoggedIn} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/construction" element={<Construction />} />
          <Route path="/interior" element={<Interior />} />
          <Route path="/land" element={<Land />} />
          <Route path="/management" element={<Management />} />
          <Route path="/soil" element={<Soil />} />
          <Route path="/design" element={<Design />} />
          <Route path="/cadtraining" element={<CadTraining />} />
          <Route path='/coursedetails/:appId' element={<CoursePage appsList={appsList}/>}/>

        </Routes>
        <ToastContainer />
        <Footer1 />

        <WhatsAppPopup />
        <ScrollToTopButton />
        <Eslide />
      </BrowserRouter>
    </div>
  )
}

export default App

