import React, { useEffect } from "react";
import { MdOutlineDesignServices } from "react-icons/md";
import { MdManageHistory } from "react-icons/md";
import { SiConstruct3 } from "react-icons/si";
import { FaBuildingColumns } from "react-icons/fa6";
import { SiAntdesign } from "react-icons/si";
import { MdEmojiTransportation } from "react-icons/md";
import { IoMdArrowDroprightCircle } from "react-icons/io";
import { BsChevronDoubleRight } from "react-icons/bs";
import { MdAddIcCall } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";
import "./Services.css";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div>
      <section
        class="page-title"
        style={{
          backgroundImage: `url('images/background/services.jpg')`,
          backgroundSize: "fill",
        }}
      >
        <div class="auto-container">
          <div class="clearfix">
            <div class="pull-left">
              <h2 style={{color:"white"}}>Services</h2>
            </div>
            <div class="pull-right">
              <ul class="page-breadcrumb">
                <li>
                  <a href="/">Home</a>  <BsChevronDoubleRight />
                </li>
                <li style={{ marginLeft: "-23px" }}>Services</li>
              </ul>
            </div>
          </div>
          <div class="contact-number text-center"><span class="icon "><MdAddIcCall /></span>Call Us: +919263636301 /+918797046868 </div>
        </div>
      </section>
      <section
        class="services-section-two"
        style={{ backgroundImage: "url(images/background/4.jpg)" }}
      >
        <div class="auto-container">
          <div class="sec-title centered" data-aos="fade-left">
            <div class="title">Services We Offer & Solutions</div>
            <h2>
              <span class="theme_color">Our</span> Main Services
            </h2>
          </div>
          <div class="row clearfix">
            {/* <!--Services Block Two--> */}
            <div
              class="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-right"
            >
              <div class="inner-box">
                <div class="upper-box">
                  <div class="icon-box">
                    <MdOutlineDesignServices />
                  </div>
                  <h3>
                    <a href="/design">Design and Build</a>
                  </h3>
                </div>
                <div class="text">
                  How all this mistaken denouncing pleasure praising complete
                  the work.
                </div>
                <a href="/design" class="read-more">
                  Read More{"/design "}
                  <IoMdArrowDroprightCircle class="fa fa-angle-right" />
                </a>
              </div>
            </div>

            {/* <!--Services Block Two--> */}
            <div
              class="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-down"
            >
              <div class="inner-box">
                <div class="upper-box">
                  <div class="icon-box">
                    <MdManageHistory />
                  </div>
                  <h3>
                    <a href="/management">Management Work</a>
                  </h3>
                </div>
                <div class="text">
                  Give you complete account of the system, and expound great
                  opportunity.
                </div>
                <a href="/management" class="read-more">
                  Read More{"/management "}
                  <IoMdArrowDroprightCircle class="fa fa-angle-right" />
                </a>
              </div>
            </div>

            {/* <!--Services Block Two--> */}
            <div
              class="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-left"
            >
              <div class="inner-box">
                <div class="upper-box">
                  <div class="icon-box">
                    <SiConstruct3 />
                  </div>
                  <h3>
                    <a href="/construction">Construction Planning</a>
                  </h3>
                </div>
                <div class="text">
                  Pleasure and praising pain was born and I will give complete
                  Progresss.
                </div>
                <a href="/construction" class="read-more">
                  Read More{"/construction "}
                  <IoMdArrowDroprightCircle class="fa fa-angle-right" />
                </a>
              </div>
            </div>

            {/* <!--Services Block Two--> */}
            <div
              class="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-right"
            >
              <div class="inner-box">
                <div class="upper-box">
                  <div class="icon-box">
                    <FaBuildingColumns />
                  </div>
                  <h3>
                    <a href="/land">Land Survey</a>
                  </h3>
                </div>
                <div class="text">
                  Expound the actual teaching of the great pleasure work in the
                  Company.
                </div>
                <a href="/land" class="read-more">
                  Read More{"/land"}
                  <IoMdArrowDroprightCircle class="fa fa-angle-right" />
                </a>
              </div>
            </div>

            {/* <!--Services Block Two--> */}
            <div
              class="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-down"
            >
              <div class="inner-box">
                <div class="upper-box">
                  <div class="icon-box">
                    <SiAntdesign />
                  </div>
                  <h3>
                    <a href="/interior">Interior Design</a>
                  </h3>
                </div>
                <div class="text">
                  Encounter conseqce that ut pleasure extremely painful again
                  lets create Design.
                </div>
                <a href="/interior" class="read-more">
                  Read More{"/interior"}
                  <IoMdArrowDroprightCircle class="fa fa-angle-right" />
                </a>
              </div>
            </div>

            {/* <!--Services Block Two--> */}
            <div
              class="services-block-two col-lg-4 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-left"
            >
              <div class="inner-box">
                <div class="upper-box">
                  <div class="icon-box">
                    <MdEmojiTransportation />
                  </div>
                  <h3>
                    <a href="/soil">Soil Testing</a>
                  </h3>
                </div>
                <div class="text">
                  Undertaks laborius physical except obtain some advantage for
                  your work...
                </div>
                <a href="/soil" class="read-more">
                  Read More{"/soil "}
                  <IoMdArrowDroprightCircle class="fa fa-angle-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="call-to-action-section-two" >
        <div class="auto-container">
          <div class="row clearfix">

            <div class="column col-md-7 col-sm-12 col-xs-12">
              <h2 className="join"><span style={{color:"white"}} class="theme_color">Enroll today</span>  to enhance your abilities with our internship and CAD training programs!</h2>
              <div class="text" style={{color:"black"}}>For more information, please contact us at available number.</div>
            </div>
            <div class="btn-column col-md-5 col-sm-12 col-xs-12" >
              <div class="number"><FaWhatsapp style={{color:"green",fontWeight:"600"}}/> +91 9263636301   </div>
              <div class="number"><FiPhone style={{fontWeight:"600",color:"white"}}/> +91 8797046868  </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
