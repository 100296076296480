import React, { useEffect } from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import '../Thankyou/Thankyou.css'

const ThankYouPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []);
  return (
    <div className="thank-you-container1">
      <div className="card1">
        <h1>Thank You!</h1>
        <p>Your message has been sent successfully. We will reach out to you soon.</p>
        <div className="social-icons">
          <a href="https://www.facebook.com"><FaFacebook /></a>
          <a href="https://www.twitter.com"><FaTwitter /></a>
          <a href="https://www.instagram.com"><FaInstagram /></a>
          <a href="https://www.linkedin.com"><FaLinkedin /></a>
        </div>
        <div className="contact-us">
          <h3>Contact Us</h3>
          <p>Email: dtechwale@gmail.com</p>
          <p>Phone: +919263636301</p>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
