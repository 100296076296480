import {Component} from 'react'
import TranningTab from '../TranningTab/TranningTab'
import TranningItem from '../TranningItem/TranningItem'
import './TranningStroe.css'

const tabsList = [
  {tabId: 'MECH', displayText: 'Mechanical'},
  {tabId: 'CIVI', displayText: 'Civil'},
  {tabId: 'ELEC', displayText: 'Electrical'},
  {tabId: 'IT', displayText: 'IT'},
]

const appsList = [
  {
    appId: 0,
    appName: 'Auto Cad',
    imageUrl:
      'https://sttsonline.com/images/Autocad-Logo.png',
    category: 'MECH',
    brief:"Widely used for 2D and 3D drafting."
  },
  {
    appId: 1,
    appName: 'Solid Work',
    imageUrl:
      'https://sttsonline.com/images/soildwork.png',
    category: 'MECH',
    brief:"3D modeling software for mechanical engineering."
  },
  {
    appId: 2,
    appName: 'Catia',
    imageUrl:
      'https://sttsonline.com/images/catia.png',
    category: 'MECH',
    brief:"Comprehensive CAD/CAM/CAE suite for industries."
  },
  {
    appId: 3,
    appName: 'Nx Cad',
    imageUrl:
      'https://sttsonline.com/images/nxcad.png',
    category: 'MECH',
    brief:"Advanced CAD/CAM/CAE software by Siemens."
  },
  {
    appId: 4,
    appName: 'Creo',
    imageUrl:
      'https://sttsonline.com/images/creologo.png',
    category: 'MECH',
    brief:"Parametric 3D modeling software for engineering."
  },
  {
    appId: 5,
    appName: 'Auto Inventer',
    imageUrl:
      'https://sttsonline.com/images/autoinvernter.png',
    category: 'MECH',
    brief:" Professional-grade 3D mechanical design software."
  },
  {
    appId: 6,
    appName: 'Ansys',
    imageUrl:
      'https://sttsonline.com/images/ansys.png',
    category: 'MECH',
    brief:"Simulation software for engineering analysis tasks."
  },

  {
    appId: 7,
    appName: 'Revit Mep',
    imageUrl:
      'https://sttsonline.com/images/revit.png',
    category: 'MECH',
    brief:"Building design software for mechanical engineers."
  },
  {
    appId: 8,
    appName: 'Gd&T',
    imageUrl:
      './images/gd&t.png',
    category: 'MECH',
    brief:"Communicated precise specifications, ensuring quality in production processes."
  },
  {
    appId: 10,
    appName: 'Auto Cad Civil',
    imageUrl:
      'https://sttsonline.com/images/Autocad-Logo.png',
    category: 'CIVI',
    brief: "Widely used for 2D and 3D drafting."
  },
  {
    appId: 11,
    appName: 'Revit Str',
    imageUrl:
      'https://sttsonline.com/images/revitstructure.png',
    category: 'CIVI',
    brief:"BIM software for architectural design projects."
  },
  {
    appId: 12,
    appName: 'Revit Arch',
    imageUrl:
      'https://sttsonline.com/images/revit.png',
    category: 'CIVI',
     brief: "3D modeling software for architectural visualization."
  },
  {
    appId: 13,
    appName: 'Sketch Up',
    imageUrl:
      'https://sttsonline.com/images/sketchup.png',
    category: 'CIVI',
     brief: "3D modeling software for architectural visualization."
  },
  {
    appId: 14,
    appName: 'V-Rays',
    imageUrl:
      'https://sttsonline.com/images/vrays.png',
    category: 'CIVI',
    brief:" Rendering software for high-quality visualizations."
  },
  {
    appId: 15,
    appName: 'Lumion',
    imageUrl:
      'https://sttsonline.com/images/lumion.png',
    category: 'CIVI',
    brief:"Real-time 3D visualization software for architects."
  },
  {
    appId: 16,
    appName: 'Staad pro',
    imageUrl:
      'https://sttsonline.com/images/staadpro.png',
    category: 'CIVI',
    brief:"Structural analysis and design software tool."
  },
  {
    appId: 17,
    appName: 'Etabs',
    imageUrl:
      'https://sttsonline.com/images/etabs.png',
    category: 'CIVI',
    brief:"Structural analysis and design software solution."
  },
  {
    appId: 18,
    appName: 'Civil3d',
    imageUrl:
      'https://sttsonline.com/images/civil3d.png',
    category: 'CIVI',
    brief:"Civil engineering design and documentation software."
  },
  {
    appId: 19,
    appName: 'Primvera',
    imageUrl:
      'https://sttsonline.com/images/primvera.png',
    category: 'CIVI',
    brief:"Project management software for construction projects."
  },
  
  
{
  appId: 21,
  appName: 'Auto Cad',
    imageUrl:
      './images/cad1.avif',
    category: 'ELEC',
    brief:"Created precise 2D and 3D drawings, essential for foundational CAD skills."
},
{
  appId: 22,
  appName: 'Auto Electric',
  imageUrl:
    'https://sttsonline.com/images/autoelectric.png',
    backinformation:" Software for automotive electrical systems.",

  category: 'ELEC',
  brief:"It is one of the most dominant software programs developed by Autodesk.",
},
{
  appId: 23,
  appName: 'Eplan',
  imageUrl:
    'https://sttsonline.com/images/elpan.png',
    backinformation:" Electrical engineering software for circuit design.",

  category: 'ELEC',
  brief:"EPLAN Electric P8 is an electrical engineering design software program.",
},
{
  appId: 24,
  appName: 'Matlab',
  imageUrl:
    'https://sttsonline.com/images/matlab.png',
    backinformation:"High-level programming language and numerical computing.",

  category: 'ELEC',
  brief:"MATLAB is an abbreviation of Matrix Laboratory which is developed by MathWorks.",
},
{
  appId: 25,
  appName: 'Plc',
  imageUrl:
    'https://sttsonline.com/images/plc.png',
    backinformation:"Programmable Logic Controller for industrial automation.",

  category: 'ELEC',
  brief:"The one of the most useful technologies in the industries for automation.",
},
{
  appId: 26,
  appName: 'Scada',
  imageUrl:
    'https://sttsonline.com/images/scada.png',
    backinformation:"Supervisory Control and Data Acquisition system.",

  category: 'ELEC',
  brief:"That is widely used in various industries like energy, oil etc.",
},


   {   appId: 34,
      appName: 'Python',
      imageUrl:
        'https://sttsonline.com/images/python.png',
      category: 'IT',
      brief:"Development covering both frontend and backend."
    },
    {
      appId: 35,
      appName: 'C,C++',
      imageUrl:
        'https://sttsonline.com/images/c.png',
      category: 'IT',
      brief:"Programming languages for software development."
    },
    {
      appId: 36,
      appName: 'Ms-Office',
      imageUrl:
        'https://sttsonline.com/images/msoffice.png',
      category: 'IT',
      brief:"Suite of productivity software by Microsoft."
    },
    {
      appId: 37,
      appName: 'Tally',
      imageUrl:
        'https://sttsonline.com/images/tally.png',
      category: 'IT',
      brief:"Accounting software for businesses and individuals."
    },
    {
      appId: 38,
      appName: 'Photo-Shop',
      imageUrl:
        'https://sttsonline.com/images/photoshop.png',
      category: 'IT',
      brief:"Image editing software for graphic designers."
    },


]

// Write your code here
class TranningStore extends Component {
  state = {activeTabId: tabsList[0].tabId, searchInput: ''}

  getFilteredProjects = () => {
    const {activeTabId} = this.state
    const filterdata = appsList.filter(
      eachdetails => eachdetails.category === activeTabId,
    )
    return filterdata
  }

  onchangestatus = tabId => {
    this.setState(each => ({activeTabId: tabId}))
  }

  getinputdetails = event => {
    this.setState(each => ({searchInput: event.target.value}))
  }

  render() {
    const {activeTabId, searchInput} = this.state
    const fliterdataproject = this.getFilteredProjects()
    const searchresult = fliterdataproject.filter(eachfilterdata =>
      eachfilterdata.appName.includes(searchInput),
    )
    // const filterdata = appsList.filter((eachdetails)=>(eachdetails.category === activeTabId))
    return (
      <div className="appcontainer" style={{marginTop:"80px"}}>
        <div className="cardcontainer">
          <div className="upcontainer">
              <div class="sec-title">
                  <h2>
                    <span class="theme_color">Cad</span>Training
                  </h2>
                </div>

            {/* <div className="search-input-container">
              <input
                type="search"
                className="search-input"
                placeholder="Search"
                onChange={this.getinputdetails}
              />
              <img
                src="https://assets.ccbp.in/frontend/react-js/app-store/app-store-search-img.png "
                alt="search icon"
                className="search_icon"
              />
            </div> */}
          </div>

          <ul className="tabitemcontainer">
            {tabsList.map(each => (
              <TranningTab
                tabname={each}
                key={each.tabId}
                onchangestatus={this.onchangestatus}
                isActive={activeTabId === each.tabId}
              />
            ))}
          </ul>

          <ul className="appcontainer">
            {searchresult.map(each => (
              <TranningItem Appitem={each} key={each.appId} />
            ))}
          </ul>

        </div>
      </div>
    )
  }
}

export default TranningStore
