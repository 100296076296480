import React, { useState } from 'react';
import { BsChevronDoubleRight } from "react-icons/bs";
import { MdAddIcCall } from "react-icons/md";
import { GrServices } from "react-icons/gr";
const Design = () => {
    const [activeTab, setActiveTab] = useState("#prod-details");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };
    return (
        <div>
            <div class="page-wrapper">
                <section
                    class="page-title"
                    style={{
                        backgroundImage: `url('images/background/services.jpg')`,
                        backgroundSize: "fill",
                    }}
                >
                    <div class="auto-container">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h2 style={{ color: "white" }}>Services</h2>
                            </div>
                            <div class="pull-right">
                                <ul class="page-breadcrumb">
                                    <li>
                                        <a href="/">Home</a>  <BsChevronDoubleRight />
                                    </li>
                                    <li style={{ marginLeft: "-23px" }}>Services</li>
                                </ul>
                            </div>
                        </div>
                        <div class="contact-number text-center"><span class="icon "><MdAddIcCall /></span>Call Us: +968 547856 254</div>
                    </div>
                </section>
                <div class="sidebar-page-container">
                    <div class="auto-container">
                        <div class="row clearfix">


                            <div class="sidebar-side col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <aside class="sidebar default-sidebar">


                                    <div class="sidebar-widget sidebar-blog-category">
                                    <ul class="blog-cat">
                                            <li><a href="/design"> <GrServices />  Design and Build</a></li>
                                            <li><a href="/management"> <GrServices /> Management Work</a></li>
                                            <li><a href="/construction"> <GrServices /> Construction Planning</a></li>
                                            <li ><a href="/land"> <GrServices /> Land Survey</a></li>
                                            <li><a href="/interior"> <GrServices /> Interior Design</a></li>
                                            <li><a href="/soil"> <GrServices /> Soil Testing</a></li>

                                        </ul>
                                    </div>


                                    {/* <div class="sidebar-widget brochure-widget">
                                        <div class="sidebar-title">
                                            <h2><span class="theme_color">Download</span> Brochures</h2>
                                        </div>

                                        <div class="brochure-box">
                                            <div class="inner">
                                                <span class="icon fa fa-file-pdf-o"></span>
                                                <div class="text">Project-One .pdf</div>
                                            </div>
                                            <a href="#" class="overlay-link"></a>
                                        </div>

                                        <div class="brochure-box">
                                            <div class="inner">
                                                <span class="icon flaticon-word-file"></span>
                                                <div class="text">Project-One .wd</div>
                                            </div>
                                            <a href="#" class="overlay-link"></a>
                                        </div>

                                        <div class="brochure-box">
                                            <div class="inner">
                                                <span class="icon fa fa-file-powerpoint-o"></span>
                                                <div class="text">Project-One .ppt</div>
                                            </div>
                                            <a href="#" class="overlay-link"></a>
                                        </div>

                                    </div> */}


                                    <div class="solution-box" style={{ backgroundimage: `url(images/resource/image-2.jpg)` }}>
                                        <div class="inner">
                                            <div class="title">Quick Contact</div>
                                            <h2>Get Solution</h2>
                                            <div class="text">Contact us at the Constrution office nearest to you or submit a business inquiry online.</div>
                                            <a class="solution-btn theme-btn" href="/contact">Contact Us</a>
                                        </div>
                                    </div>

                                </aside>
                            </div>


                            <div class="content-side col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                <div class="services-single">
                                    <div class="inner-box">


                                        <div class="services-gallery">
                                            <div class="row clearfix">
                                                <div class="column col-md-8 col-sm-8 col-xs-12">
                                                    <div class="image">
                                                        <img src="images/mw1.jpg" alt="" />
                                                    </div>
                                                </div>
                                                <div class="column col-md-4 col-sm-4 col-xs-12">
                                                    <div class="image">
                                                        <img src="images/mw2.jpg" alt="" />
                                                    </div>
                                                    {/* <div class="image">
                                                        <img src="images/resource/team-1.png" alt="" />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <h2>Management Work</h2>
                                        <div class="text">
                                            <p>Management is how businesses organize and direct workflow, operations, and employees to meet company goals. The primary goal of management is to create an environment that empowers employees to work efficiently and productively.</p>

                                            <p> Managerial roles are behaviors adopted to perform various management functions, like leading and planning, organizing, strategizing, and solving problems. Within an organization, managers of different levels have different responsibilities that may overlap.</p>
                                            <p>Management is the process of planning and organising the resources and activities of a business to achieve specific goals in the most effective and efficient manner possible. Efficiency in management refers to the completion of tasks correctly and at minimal costs.</p>

                                            <div class="two-column">
                                                <div class="row clearfix">
                                                    <div class="image-column col-md-6 col-sm-6 col-xs-12">
                                                        <div class="image">
                                                            <img src="images/resource/services-7.jpg" alt="" />
                                                        </div>
                                                    </div>
                                                    {/* <div class="content-column col-md-6 col-sm-6 col-xs-12">
                                                        <h3><span class="theme_color">Buidling</span> Renovation</h3>
                                                        <p>That is the way we all be came the Brady Bunch these to days are all Happy and Free these days you wanna be where everybody knows your name fish do not fry in the artist kitchen.</p>
                                                        <ul class="list-style-four">
                                                            <li>States starship enterprise the Brady.</li>
                                                            <li>The odds are against him and their dangers.</li>
                                                            <li>Travelled down the road and back again.</li>
                                                            <li>A loveable space that needs your face threes.</li>
                                                        </ul>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="alternate"><span class="theme_color">Working</span> Process</h3>




                                        <div className="product-info-tabs">
                                            <div className="services-tabs tabs-box">
                                                <ul className="tab-btns tab-buttons clearfix">
                                                    <li onClick={() => handleTabClick("#prod-details")} className={`tab-btn ${activeTab === "#prod-details" ? "active-btn" : ""}`}>Precautions</li>
                                                    <li onClick={() => handleTabClick("#prod-spec")} className={`tab-btn ${activeTab === "#prod-spec" ? "active-btn" : ""}`}>Intelligence</li>
                                                    <li onClick={() => handleTabClick("#prod-reviews")} className={`tab-btn ${activeTab === "#prod-reviews" ? "active-btn" : ""}`}>Specializations</li>
                                                </ul>
                                                <div className="tabs-content">
                                                    <div className={`tab ${activeTab === "#prod-details" ? "active-tab" : ""}`} id="prod-details">
                                                        <div className="content">
                                                            <p>Processing and refining operations turn crude oil and gas into marketable products. In the case of crude oil, these products include heating oil, gasoline for use in vehicles, jet fuel, and diesel oil. Oil refining processes include dis-catalytic cracking, alkylation, isomerization, and hydrotreating.</p>
                                                            <p>Again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but seds because occasionally circumstances occur in which toil and pain can procure him some great pleasure.</p>
                                                        </div>
                                                    </div>
                                                    <div className={`tab ${activeTab === "#prod-spec" ? "active-tab" : ""}`} id="prod-spec">
                                                        <div className="content">
                                                            <p>Ensuring the safety and efficiency of processing and refining operations involves implementing rigorous precautionary measures, comprehensive training protocols, and regular equipment inspections to mitigate potential hazards and minimize operational risks.</p>
                                                        </div>
                                                    </div>
                                                    <div className={`tab ${activeTab === "#prod-reviews" ? "active-tab" : ""}`} id="prod-reviews">
                                                        <div className="content">
                                                            <p>Specializations in the processing and refining industry may include expertise in various refining processes such as catalytic cracking, alkylation, isomerization, and hydrotreating, as well as proficiency in optimizing operational efficiency, ensuring product quality, and complying with environmental regulations</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Design
