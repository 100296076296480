import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import './Gallery.css'
import AOS from "aos";
import "aos/dist/aos.css";
import { BsChevronDoubleRight } from "react-icons/bs";
import { MdAddIcCall } from "react-icons/md";
import GallerySlider from "../../Component/GallerySlider/GallerySlider";

import Cadslider from '../../Component/Cadslider/Cadslider'
const Gallery = () => {

  useEffect(() => {
    AOS.init();

  }, []);

  return (
    <div>
      <section
        class="page-title"
        style={{
          backgroundImage: `url('images/background/8.jpg')`,
        }}
      >
        <div class="auto-container">
          <div class="clearfix">
            <div class="pull-left">
              <h1>Gallery</h1>
            </div>
            <div class="pull-right">
              <ul class="page-breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                  <BsChevronDoubleRight />
                </li>

                <li style={{ marginLeft: "-23px" }}>Gallery</li>
              </ul>
            </div>
          </div>
          <div class="contact-number text-center"><span class="icon "><MdAddIcCall /></span>Call Us: +91 9263636301</div>
        </div>
      </section>

      <div class="sec-title centered" data-aos="fade-left">

        <h2>
          <span class="theme_color">G</span>allery
        </h2>
      </div>

      <div class="container_gallery">
        <div class="gallery">

          <div class="gallery-item" data-aos="fade-up"
            data-aos-duration="1000">
            <img class="gallery-image" src="images/ggg1.jpeg" alt="person writing in a notebook beside by an iPad, laptop, printed photos, spectacles, and a cup of coffee on a saucer" />
          </div>

          <div class="gallery-item" data-aos="fade-up"
            data-aos-duration="2000">
            <img class="gallery-image" src="images/ggg2.jpeg" alt="sunset behind San Francisco city skyline" />
          </div>

          <div class="gallery-item" data-aos="fade-up"
            data-aos-duration="3000">
            <img class="gallery-image" src="images/ggg3.jpeg" alt="people holding umbrellas on a busy street at night lit by street lights and illuminated signs in Tokyo, Japan" />
          </div>

          <div class="gallery-item" data-aos="fade-up"
            data-aos-duration="4000">
            <img class="gallery-image" src="images/ggg4.jpeg" alt="car interior from central back seat position showing driver and blurred view through windscreen of a busy road at night" />
          </div>

          <div class="gallery-item" data-aos="fade-up"
            data-aos-duration="5000">
            <img class="gallery-image" src="images/ggg5.jpeg" alt="back view of woman wearing a backpack and beanie waiting to cross the road on a busy street at night in New York City, USA" />
          </div>

          <div class="gallery-item" data-aos="fade-up"
            data-aos-duration="6000">
            <img class="gallery-image" src="images/ggg6.jpeg" alt="man wearing a black jacket, white shirt, blue jeans, and brown boots, playing a white electric guitar while sitting on an amp" />
          </div>

          <div class="gallery-item" data-aos="fade-up"
            data-aos-duration="7000">
            <img class="gallery-image" src="images/ggg7.jpeg" alt="car interior from central back seat position showing driver and blurred view through windscreen of a busy road at night" />
          </div>

          <div class="gallery-item" data-aos="fade-up"
            data-aos-duration="8000">
            <img class="gallery-image" src="images/ggg8.jpeg" alt="back view of woman wearing a backpack and beanie waiting to cross the road on a busy street at night in New York City, USA" />
          </div>



        </div>

      </div>

      <section class="team-section">
        <div class="auto-container">
          <div class="sec-title centered">
           
            <h2>
              <span class="theme_color">field</span> Work 
            </h2>
          </div>
          <div class="row clearfix field " >
            {/* <!--Team Block--> */}



            {/* <!--Team Block--> */}
            <div
              class="team-block col-lg-2 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-down-right"
            >
              <div class="inner-box" >
                <div class="image">
                  <img src="/images/gallery/wg1.jpeg" alt="" />
                  <div class="overlay-box">
                    
                   
                    <div class="designation">
                    
                      <p className="deepak_info">
                      Surveying equipment is used to provide dimensional control for all stages of construction work, volume calculation/Cutting-Filling in mining areas, including the stakeout of reference points and markers that will guide the construction of new structures such as roads, rail, or buildings. Surveyors and construction workers use tools like Theodolites, DGPS, Auto level, and many more survey equipment and tools to determine the relative position of the points on, above, or below the earth's surface by taking direct or indirect measurements of distance, direction, and elevation.
                      </p>
                    </div>

                  </div>
                </div>

              </div>
              <h1 style={{backgroundColor:"rgb(4, 32, 56)",padding:"5px",color:"white",textAlign:"center",fontWeight:"500"}} className="resposnive_new_heading_32"><b>Site Surveying and Layout</b> </h1>
              <p className="deepak"> 
              
              Surveying equipment is used to provide dimensional control for all stages of construction work, volume calculation/Cutting-Filling in mining areas, including the stakeout of reference points and markers that will guide the construction of new structures such as roads, rail, or buildings. Surveyors and construction workers use tools like Theodolites, DGPS, Auto level, and many more survey equipment and tools to determine the relative position of the points on, above, or below the earth's surface by taking direct or indirect measurements of distance, direction, and elevation.
              </p>
            </div>
            <div
              class="team-block col-lg-2 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-down-right"
            >
              <div class="inner-box" >
                <div class="image">
                  <img src="/images/gallery/wg5.jpeg" alt="" />
                  <div class="overlay-box">
                    
                   
                    <div class="designation">
                      <p className="deepak_info">
                      Using Tripod for STANDARD PENETRATION TEST (SPT) OF SOIL ON-SITE
                      </p>
                    </div>

                  </div>
                </div>

              </div>
              <h1 style={{backgroundColor:"rgb(4, 32, 56)",padding:"5px",color:"white",textAlign:"center",fontWeight:"500"}} className="resposnive_new_heading_32"><b>Tripod for STANDARD PENETRATION TEST</b> </h1>

              <p className="deepak"> 
             
              Using Tripod for STANDARD PENETRATION TEST (SPT) of Soil On-site.
              </p>
            </div>
            <div
              class="team-block col-lg-2 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-down-right"
            >
              <div class="inner-box" >
                <div class="image">
                  <img src="/images/gallery/wg3.jpeg" alt="" />
                  <div class="overlay-box">
                    
                   
                    <div class="designation">
                      <p className="deepak_info">

                     
                      Collecting Soil samples from Split Spoon Sampler to determine the types of soil available in different layers.
                      </p>
                    </div>

                  </div>
                </div>

              </div>
              <h1 style={{backgroundColor:"rgb(4, 32, 56)",padding:"5px",color:"white",textAlign:"center",fontWeight:"500"}} className="resposnive_new_heading_32"><b>Soil Classification</b> </h1>

              <p className="deepak"> 
             
              Collecting Soil samples from Split Spoon Sampler to determine the types of soil available in different layers.
              </p>
            </div>
            <div
              class="team-block col-lg-2 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-down-right"
            >
              <div class="inner-box" >
                <div class="image">
                  <img src="/images/gallery/wg4.jpeg" alt="" />
                  <div class="overlay-box">
                    
                   
                    <div class="designation">
                      <p className="deepak_info">

                     
                      This test requires the user to place four equally spaced auxiliary probes into the earth to determine the actual soil resistance, traditionally in ohms-cm or ohm-m. This test must take place around the entire area to determine the soil value at all locations.
                      </p>
                    </div>

                  </div>
                </div>

              </div>
              <h1 style={{backgroundColor:"rgb(4, 32, 56)",padding:"5px",color:"white",textAlign:"center",fontWeight:"500"}} className="resposnive_new_heading_32"><b>Earth Resistivity Test</b> </h1>

              <p className="deepak"> 
            
              This test requires the user to place four equally spaced auxiliary probes into the earth to determine the actual soil resistance, traditionally in ohms-cm or ohm-m. This test must take place around the entire area to determine the soil value at all locations.
              </p>
            </div>
            <div
              class="team-block col-lg-2 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-down-right"
            >
              <div class="inner-box" >
                <div class="image">
                  <img src="./images/ggg12.jpeg" alt="" />
                  <div class="overlay-box">
                    
                   
                    <div class="designation">
                      <p className="deepak_info">

                     
                      Determining the optimal moisture content at which soil achieves its maximum density.
                      </p>
                    </div>

                  </div>
                </div>

              </div>
              {/* <h1 style={{backgroundColor:"rgb(4, 32, 56)",padding:"5px",color:"white",textAlign:"center",fontWeight:"500"}}><b>hi</b> </h1> */}

              <p className="deepak"> 
             
              Determining the optimal moisture content at which soil achieves its maximum density.
              </p>
            </div>
           

            {/* <!--Team Block--> */}



            {/* <!--Team Block--> */}

          </div>
        </div>
      </section>

















      <div class="sec-title centered" data-aos="fade-left">

        <h2>
          <span class="theme_color">Process</span> work
        </h2>
      </div>
      <GallerySlider />

      <div class="sec-title centered" data-aos="fade-left">

        <h2>
          <span class="theme_color">E</span>vents
        </h2>
      </div>

      <div class="container-all">
        <div class="container_other" data-aos="zoom-in">
          <img src="images/gg1.jpeg" alt="" className='simple_gallery_image' />
          <span class="title_other"></span>

        </div>

        <div class="container_other" data-aos="zoom-in-up">
          <img src="images/gg2.jpeg" alt="" className='simple_gallery_image' />
          <span class="title_other"></span>

        </div>

        <div class="container_other" data-aos="zoom-in-down">
          <img src="images/gg3.jpeg" alt="" className='simple_gallery_image' />
          <span class="title_other"></span>

        </div>

        <div class="container_other" data-aos="zoom-in-up">
          <img src="images/gg4.jpeg" alt="" className='simple_gallery_image' />
          <span class="title_other"></span>

        </div>

        <div class="container_other" data-aos="zoom-in-left">
          <img src="./images/gallery/eg8.jpeg" alt="" className='simple_gallery_image' />
          <span class="title_other"></span>

        </div>

      </div>





      <div class="sec-title centered" data-aos="fade-left">

        <h2>
          <span class="theme_color">Our</span> Projects
        </h2>
      </div>
      <h2 style={{ textAlign: "center" }}>1.
        <span class="theme_color"> 3D</span> View Of Sales Building
      </h2>
      <div class="container-all">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="images/gallery/dtech1.PNG" alt="" style={{ height: "250px" }} />
            </div>
            <div class="flip-card-back">
              <p class="title">"Crafting your dreams into reality, one brick at a time."</p>

            </div>
          </div>
        </div>

        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="images/gallery/dtech3.PNG" alt="" style={{ height: "250px" }} />
            </div>
            <div class="flip-card-back">
              <p class="title">Transforming spaces, building legacies.</p>

            </div>
          </div>
        </div>

        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="images/gallery/dtech4.PNG" alt="" style={{ height: "250px" }} />
            </div>
            <div class="flip-card-back">
              <p class="title">From concept to creation, we're your building partners</p>

            </div>
          </div>
        </div>

        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="images/gallery/dtech5.PNG" alt="" style={{ height: "250px" }} />
            </div>
            <div class="flip-card-back">
              <p class="title">Building better tomorrows, today</p>

            </div>
          </div>
        </div>

      </div>

      <div class="container-all">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="images/gallery/dtech2.PNG" alt="" style={{ height: "250px" }} />
            </div>
            <div class="flip-card-back">
              <p class="title">Quality construction, timeless craftsmanship</p>

            </div>
          </div>
        </div>

        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="images/gallery/dtech6.PNG" alt="" style={{ height: "250px" }} />
            </div>
            <div class="flip-card-back">
              <p class="title">Where innovation meets construction excellence.</p>

            </div>
          </div>
        </div>

        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="images/gallery/dtech7.PNG" alt="" style={{ height: "250px" }} />
            </div>
            <div class="flip-card-back">
              <p class="title">Creating structures that stand the test of time</p>

            </div>
          </div>
        </div>

        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="images/gallery/dtech8.PNG" alt="" style={{ height: "250px" }} />
            </div>
            <div class="flip-card-back">
              <p class="title">Turning blueprints into beautiful realities.</p>

            </div>
          </div>
        </div>

      </div>

      <div class="container-all">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="images/gallery/dtech9.PNG" alt="" style={{ height: "250px" }} />
            </div>
            <div class="flip-card-back">
              <p class="title">Your vision, our expertise, together we build.</p>

            </div>
          </div>
        </div>

        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="images/gallery/dtech10.PNG" alt="" style={{ height: "250px" }} />
            </div>
            <div class="flip-card-back">
              <p class="title">Crafting architectural wonders, shaping skylines</p>

            </div>
          </div>
        </div>

       


      </div>

      {/* <div class="container-all">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="images/gallery/g42.jpeg" alt="" style={{ height: "250px" }} />
            </div>
            <div class="flip-card-back">
              <p class="title">Building dreams, one foundation at a time</p>

            </div>
          </div>
        </div>
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="images/gallery/g43.jpeg" alt="" style={{ height: "250px" }} />
            </div>
            <div class="flip-card-back">
              <p class="title">Where passion meets professionalism, buildings rise</p>

            </div>
          </div>
        </div>
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="images/gallery/g44.jpeg" alt="" style={{ height: "250px" }} />
            </div>
            <div class="flip-card-back">
              <p class="title">Innovative designs, solid foundations, lasting impressions.</p>

            </div>
          </div>
        </div>
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="images/gallery/g45.jpeg" alt="" style={{ height: "250px" }} />
            </div>
            <div class="flip-card-back">
              <p class="title">Crafting spaces that inspire, constructions that endure.</p>

            </div>
          </div>
        </div>
      </div> */}
      <h2 style={{textAlign:"center"}}>2.
          <span  class="theme_color"> Indian</span> Oil Housing Colony
        </h2>
       <section style={{marginBottom:"20px"}}>
           <div className="container">
               <div className="row">
                    <div className="col-lg-6 col-md-6 col-12" style={{marginTop:"18px"}}>
                         <div>
                           <img src="images/gallery/indiahouse1.png" alt=""/>
                         </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12" style={{marginTop:"23px"}}>
                         <div>
                           <img src="images/gallery/indiahouse2.png" alt=""/>
                         </div>
                    </div>
               </div>
           </div>
       </section>
       <section>
      <div class="sec-title centered" >
          <h2>
            <span class="theme_color">Internship</span> and CAD Training
          </h2>
          <div class="title">We are offering summer Internship programs</div>
          <div class="title">Join us to enhance your skills.. Get Job Ready</div>
        </div>
        <Cadslider />
      </section>
    </div>

  )
}

export default Gallery
