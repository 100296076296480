import React,{useEffect} from 'react'
import './CadTraning.css'
import TranningStore from '../../Component/TranningStore/TranningStroe'
import { FaWhatsapp } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";

const CadTraning = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <>
      <TranningStore />
      <section class="call-to-action-section-two" >
        <div class="auto-container">
          <div class="row clearfix">

            <div class="column col-md-7 col-sm-12 col-xs-12">
              <h2 className="join"><span style={{color:"white"}} class="theme_color">Enroll today</span>  to enhance your abilities with our internship and CAD training programs!</h2>
              <div class="text" style={{color:"black"}}>For more information, please contact us at available number.</div>
            </div>
            <div class="btn-column col-md-5 col-sm-12 col-xs-12" >
              <div class="number"><FaWhatsapp style={{color:"green",fontWeight:"600"}}/> +91 9263636301   </div>
              <div class="number"><FiPhone style={{fontWeight:"600",color:"white"}}/> +91 8797046868  </div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default CadTraning
