import React, { useEffect, useState } from 'react';
import { GrInstagram } from "react-icons/gr";
import { MdOutlinePhoneForwarded } from "react-icons/md";
import { FaHouseUser } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { TbTargetArrow } from "react-icons/tb";
import { GrHomeRounded } from "react-icons/gr";
import { MdManageSearch } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { GrGallery } from "react-icons/gr";
import { IoMdContacts } from "react-icons/io";
import { RiAdminLine } from "react-icons/ri";
import { SiFuturelearn } from "react-icons/si";
import { IoMdLogIn } from "react-icons/io";
import { MdOutlineConnectWithoutContact } from "react-icons/md";
import { FaFacebook, FaYoutube, FaLocationArrow, FaMailBulk, FaHome, FaCaretDown } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import './Header.css'

const Header = ({ isLoggedIn, onLogout }) => {
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const handleNavItemClick = () => {
      const navbarCollapse = document.getElementById('navbar-collapse');
      if (navbarCollapse.classList.contains('in')) {
        navbarCollapse.classList.remove('in');
      }
    };

    const navItems = document.querySelectorAll('.navigation li a');
    navItems.forEach(item => item.addEventListener('click', handleNavItemClick));

    return () => {
      navItems.forEach(item => item.removeEventListener('click', handleNavItemClick));
    };
  }, []);
  const toggleServicesDropdown = (e) => {
    e.preventDefault();
    setServicesDropdownOpen(!servicesDropdownOpen);
  };
  const handleServiceClick = (e) => {
    // Stop propagation to prevent parent click event
    e.stopPropagation();
  };
  return (
    <header className="main-header problem ">
      {/* Header Top */}
      <div className="header-top ">
        <div className="auto-container">
          <div className="clearfix">
            {/* Top Left */}
            <div className="top-left pull-left">
              <ul className="clearfix">
                <li data-aos="fade-right"><FaLocationArrow style={{ color: "#F77522", marginRight: "5px" }} />The Pride Apartment, Near Bus Stand, Lane No.1, Jayprakash Nagar, Dhanbad, Jharkhand - 826001</li>
              </ul>
            </div>
            {/* Top Right */}
            <div className="top-right pull-right" data-aos="fade-left">
              <ul className="social-nav">
                <li><a href="https://www.facebook.com/dtech.cad/" target='_blank'><span className=""> </span><FaFacebook /></a></li>
                <li><a href="https://www.instagram.com/dtech.cad/" target='_blank'><span className=""></span><GrInstagram /></a></li>
                {/* <li><a href="#"><span className=""></span><FaYoutube /></a></li> */}
              </ul>
              <ul className="list">
                <li>Have any question? +919263636301</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Header-Upper */}

      <div style={{ display: "flex", justifyContent: "space-around", marginTop: "40px" }}>
        <div className="clearfix">
          <div className="pull-left logo-box">
            <div className="logo" style={{ height: "50px", padding: "10px", width: "250px" }}><a href="/"><img className='resposnive_logo' src="images/logos1.png" alt="" title="" /></a></div>
          </div>

          <div className="pull-right upper-right clearfix responsive_hidden_icons " style={{ marginTop: "50px" }}>
            {/* Info Box */}
            <div className="upper-column info-box">
              <div className="icon-box"><MdOutlineConnectWithoutContact /></div>
              <ul>
                <li><strong>Phone Call</strong></li>
                <li> +919263636301 /+918797046868</li>
              </ul>
            </div>
            {/* Info Box */}
            <div className="upper-column info-box">
              <div className="icon-box"><FaMailBulk /></div>
              <ul>
                <li><strong>Send your mail at</strong></li>
                <li>
                  <a href="mailto:dtechwale@gmail.com" style={{ textDecoration: 'none', color: 'inherit' }}>
                    dtechwale@gmail.com
                  </a>
                </li>
              </ul>
            </div>

            <div className="upper-column info-box">

              <div className="dropdown">
                <Link style={{fontSize:"16px"}} to="/cadtraining" className="Btn">
                  Skill Development Training
                </Link>

              </div>

            </div>
          </div>
        </div>
      </div>
      {/* Header Lower */}
      <div className="header-lower">
        <div className="auto-container" style={{ marginTop: "-40px" }} >
          <div className="nav-outer clearfix" >
            {/* Main Menu */}
            <nav className="main-menu">
              <div className="navbar-header">
                {/* Toggle Button */}
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>


              </div>

              <div id="navbar-collapse" className="navbar-collapse collapse clearfix">
                <ul className="navigation clearfix">
                  <li className="current dropdown"><Link to="/"><FaHome style={{ fontSize: "25px" }} /></Link>

                  </li>
                  <li><Link to="/about"><MdManageSearch />About us</Link></li>
                  <li className={`dropdown ${servicesDropdownOpen ? 'open' : ''}`}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: "16px" }}>
                      <Link className='serve' to="/services" style={{ color: "white", width: "110px" }}>
                        <AiFillProduct /> Services
                      </Link>
                      <button className='sbtn'
                        onClick={toggleServicesDropdown}
                        style={{
                          background: 'none',
                          border: 'none',
                          padding: 0,
                         
                          cursor: 'pointer',
                        }}
                      >
                        <FaCaretDown />
                      </button>
                    </div>
                    <ul className={`dropdown-menu ${servicesDropdownOpen ? 'show' : ''}`} onClick={handleServiceClick}>
                      <li><Link to="/design">Design and Build</Link></li>
                      <li><Link to="/management">Management Work</Link></li>
                      <li><Link to="/construction">Construction Planning</Link></li>
                      <li><Link to="/land">Land Survey</Link></li>
                      <li><Link to="/interior">Interior Design</Link></li>
                      <li><Link to="/soil">Soil Testing</Link></li>
                    </ul>
                  </li>

                  <li className="dropdown"><Link to="/Gallery"><GrGallery />Gallery</Link>

                  </li>

                  <li><Link to="/contact"><IoMdContacts />Contact us</Link></li>
                  <li><Link to="/Career"><SiFuturelearn />Careers</Link></li>
                  {isLoggedIn ? (
                    <li className='resposive_logout_in_mobile'><Link to="/" onClick={onLogout} ><IoMdLogIn />Logout</Link></li>
                  ) : (
                    <li><Link to="/login"><IoMdLogIn />Admin</Link></li>
                  )}
                  {/* <li  className='cad'><Link to="/login"><SiFuturelearn />Admin</Link></li> */}
                  
                  {isLoggedIn && (
                    <li><Link to="/admincarrers"><RiAdminLine />Admin</Link></li>
                  )}
                  
                </ul>
              </div>
            </nav>

            {/* Main Menu End */}
            <div className="outer-box clearfix" >
              {/* Search Box */}

              <div className="search-box-outer">
                {isLoggedIn ? (
                  <button className="search1-box-btn response_logout" onClick={onLogout}>LOGOUT</button>
                ) : (
                  <Link to="/login">
                    <button className="search-box-btn response_admin">LOGIN</button>
                  </Link>
                )}
              </div>
              <Link to="/cadtraining">
              <button className="search5-box-btn response_button">Internship and CAD Training</button>
              </Link>
              {/* Advisor Box */}
              <div className="advisor-box">
                <Link to="/contact" className="theme-btn advisor-btn">Get Info <TbTargetArrow className="fa fa-long-arrow-right" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sticky Header */}
      <div className="sticky-header">
        <div className="auto-container clearfix">
          {/* Logo */}
          <div className="logo pull-left" style={{ height: "30px", width: "200px", padding: "20px", marginTop: "-10px" }}>
            <a href="/" className="img-responsive"><img src="images/logos1.png" alt="" title="" /></a>
          </div>
          {/* Right Col */}
          <div className="right-col pull-right">
            {/* Main Menu */}
            <nav className="main-menu">
              <div className="navbar-header">
                {/* Toggle Button */}
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              </div>
              <div id="navbar-collapse" className="navbar-collapse collapse clearfix">
                <ul className="navigation clearfix">
                  <li className="current dropdown"><Link to="/"><GrHomeRounded />Home</Link>
                    {/* <ul>
                      <li><a href="index.html">Homepage One</a></li>
                      <li><a href="index-2.html">Homepage Two</a></li>
                      <li className="dropdown"><a href="#">Header Styles</a>
                        <ul>
                          <li><a href="index.html">Header Style One</a></li>
                          <li><a href="index-2.html">Header Style Two</a></li>
                        </ul>
                      </li>
                    </ul> */}
                  </li>
                  <li><Link to="/about"><MdManageSearch />About us</Link></li>
                  <li className="dropdown">
                    <Link to="/services"><AiFillProduct />Services</Link>
                    <ul className="dropdown-menu">
                 
                      <li><Link to="/design">Design and Build</Link></li>
                      <li><Link to="/management">Management Work</Link></li>
                      <li><Link to="/construction">Construction Planning</Link></li>
                      <li><Link to="/land">Land Survey</Link></li>
                      <li><Link to="/interior">Interior Design</Link></li>
                      <li><Link to="/soil">Soil Testing</Link></li>
                    </ul>
                  </li>
                  <li className="dropdown"><Link to="/Gallery"><GrGallery />Gallery</Link>
                    {/* <ul>
                      <li><a href="projects.html">Our projects</a></li>
                      <li><a href="projects-detail.html">Projects Details</a></li>
                    </ul> */}
                  </li>
                  {/* <li className="dropdown"><a href="#">Blog</a>
                    <ul>
                      <li><a href="blog.html">Blog</a></li>
                      <li><a href="blog-detail.html">Blog Details</a></li>
                    </ul>
                  </li>
                  <li className="dropdown"><a href="#">Shop</a>
                    <ul>
                      <li><a href="shop.html">Shop</a></li>
                      <li><a href="shop-single.html">Product Details</a></li>
                      <li><a href="shoping-cart.html">Cart Page</a></li>
                      <li><a href="checkout.html">Checkout Page</a></li>
                      <li><a href="login.html">Login Page</a></li>
                    </ul>
                  </li> */}
                  <li><Link to="/contact"><IoMdContacts />Contact us</Link></li>
                  <li><Link to="/Career"><SiFuturelearn />Careers</Link></li>
                  <li><Link to="/"><RiAdminLine />Admin</Link></li>
                </ul>
              </div>
            </nav>
            {/* Main Menu End */}
          </div>
        </div>
      </div>
      {/* End Sticky Header */}
    </header>
  );
};

export default Header;
