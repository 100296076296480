import React, { useState } from 'react';
import '../Jobdescription/Jobdescription.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Jobdescription = () => {
    const [formData, setFormData] = useState({
        jobName: '',
        jobTitle: '',
        jobDescription: '',
        requiredQualifications: '',
        otherMsg: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if all fields are filled
        if (Object.values(formData).some(field => field === '')) {
            toast.error('Please fill in all fields');
            return;
        }

        try {
            const response = await fetch('/api/jobapplications', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                toast.success('Job application submitted successfully');
                setFormData({
                    jobName: '',
                    jobTitle: '',
                    jobDescription: '',
                    requiredQualifications: '',
                    otherMsg: ''
                });
            } else {
                toast.error('Failed to submit job application');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred while submitting the job application');
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="sec-title centered">
                    <h2>
                        <span className="theme_color">Enter Job</span> Description
                    </h2>
                </div>
                <fieldset>
                    <legend>Job Description</legend>

                    <label htmlFor="jobName">Job Name</label>
                    <input
                        type="text"
                        id="jobName"
                        name="jobName"
                        value={formData.jobName}
                        onChange={handleChange}
                    />

                    <label htmlFor="jobTitle">Job Title</label>
                    <input
                        type="text"
                        id="jobTitle"
                        name="jobTitle"
                        value={formData.jobTitle}
                        onChange={handleChange}
                    />

                    <label htmlFor="jobDescription">Job Description</label>
                    <textarea
                        id="jobDescription"
                        name="jobDescription"
                        value={formData.jobDescription}
                        onChange={handleChange}
                    />

                    <label htmlFor="requiredQualifications">Required Qualifications</label>
                    <textarea
                        id="requiredQualifications"
                        name="requiredQualifications"
                        value={formData.requiredQualifications}
                        onChange={handleChange}
                    />

                    <label htmlFor="otherMsg">Field of Job</label>
                    <textarea
                        id="otherMsg"
                        name="otherMsg"
                        value={formData.otherMsg}
                        onChange={handleChange}
                    />
                </fieldset>

                <button type="submit">Submit</button>
            </form>
            <ToastContainer />
        </div>
    );
}

export default Jobdescription;
